<template>
  <div>
    <transition name="fade">
      <div
        class="absolute text-left text-small"
        v-if="visible"
        :style="{
          'background-color': toastNotificationState ? '#bdffb7' : '#e76b6b',
        }"
      >
        {{ this.toastNotificationMessage }}
      </div>
    </transition>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "ToastNotification",
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    ...mapGetters({
      toastNotificationMessage: "utils/toastNotificationMessage",
      toastNotificationState: "utils/toastNotificationState",
    }),
  },
  watch: {
    toastNotificationMessage(newValue) {
      if (newValue === "") this.visible = false;
      else this.visible = true;
    },
  },
  methods: {},
};
</script>
<style scoped>
.absolute {
  position: fixed;
  bottom: 30px;
  padding: 15px;
  border-radius: 10px;
  right: 30px;
  width: 300px;
  z-index: 5000;
  background-color: #eb4444;
}

@media (max-width: 768px) {
  .absolute {
    width: 200px;
  }
}
.fade-enter-active {
  transition: opacity 0.1s;
}
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
