<template>
  <generic-dialog title="Recordatorios" v-model="computedValue" width="500">
    <!-- reminder editor -->
    <generic-dialog
      v-model="showReminderEditor"
      v-if="showReminderEditor"
      @close="resetReminderEditor"
      :title="`Recordatorio: ${currentReminder.name}`"
      width="450"
    >
      <div class="d-flex">
        <v-text-field
          min="200"
          max="1000"
          type="number"
          label="n_min"
          hide-spin-buttons
          v-model="nMin"
          :rules="[
            (v) => v >= 200 || 'Valor mínimo de 200',
            (v) => v <= 1000 || 'Valor máximo de 1000',
          ]"
        ></v-text-field>
        <v-text-field
          min="201"
          hide-spin-buttons
          max="1000"
          type="number"
          :rules="[
            (v) => v >= 201 || 'Valor mínimo de 201',
            (v) => v <= 2000 || 'Valor máximo de 2000',
          ]"
          label="n_max"
          v-model="nMax"
        ></v-text-field>
        <tooltip class="mt-4"
          >Al momento de enviar el chatbot, se enviarán batches de un número
          aleatorio entre <b>{{ nMin }}</b> y <b>{{ nMax }}</b
          >.
        </tooltip>
      </div>
      <div
        v-if="parseInt(nMin) > parseInt(nMax)"
        class="color--error text-label"
      >
        El n mínimo no puede ser mayor al n máximo.
      </div>
      <div class="d-flex">
        <v-text-field
          min="5"
          max="60"
          type="number"
          hide-spin-buttons
          label="t_min"
          v-model="tMin"
          :rules="[
            (v) => v >= 1 || 'Valor mínimo de 1',
            (v) => v <= 60 || 'Valor máximo de 60',
          ]"
        ></v-text-field>

        <v-text-field
          min="10"
          max="90"
          type="number"
          hide-spin-buttons
          label="t_max"
          v-model="tMax"
          :rules="[
            (v) => v >= 2 || 'Valor mínimo de 2',
            (v) => v <= 90 || 'Valor máximo de 90',
          ]"
        ></v-text-field>
        <tooltip class="mt-4"
          >Al momento de enviar el chatbot, entre batches, se esperará un tiempo
          aleatorio entre <b>{{ tMin }}</b> y <b>{{ tMax }}</b> segundos.
        </tooltip>
      </div>
      <div
        v-if="parseInt(tMin) > parseInt(tMax)"
        class="color--error text-label"
      >
        El t mínimo no puede ser mayor al t máximo.
      </div>
      <div style="font-style: italic" class="text-left">
        Al momento de enviar el chatbot, se enviarán batches de un número
        aleatorio entre <b>{{ nMin }}</b> y <b>{{ nMax }}</b
        >. Después de cada batch, se esperará un tiempo aleatorio de entre
        <b>{{ tMin }}</b> y <b>{{ tMax }}</b> segundos.
      </div>
      <div class="d-flex w-100">
        <v-btn
          class="ms-auto me-auto mt-4"
          @click="saveReminder"
          :disabled="reminderName == '' || !validNumbers"
          >Guardar</v-btn
        >
      </div>
    </generic-dialog>
    <!-- end reminder editor -->

    <div v-if="reminders.length == 0">
      No hay recordatorios para este chatbot.
    </div>
    <v-expansion-panels
      class="text-left"
      style="max-height: 65vh; overflow-y: auto"
    >
      <v-expansion-panel v-for="(reminder, index) in reminders" :key="index">
        <v-expansion-panel-header>
          <div class="d-flex">
            <div class="h-100 mt-auto mb-auto">
              {{ reminder.name }} - {{ reminder.total }} usuarios
            </div>

            <v-card
              :style="{ background: background(reminder.status) }"
              class="ms-2 pa-2 rounded"
              >{{ reminder.status }}</v-card
            >
          </div></v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <div>
            <div class="pt-4 pe-4 pb-4">
              <div class="text-body d-flex">
                <div>Datos de recordatorio</div>
                <div class="ms-auto">
                  <v-tooltip top :disabled="reminder.status == 'paused'">
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <v-icon
                          :disabled="editDisabled(reminder)"
                          @click="editReminder(reminder)"
                          color="blue"
                          class="ms-auto"
                          >mdi-pencil</v-icon
                        >
                      </div>
                    </template>
                    <span
                      >El recordatorio solo puede ser editado cuando está en
                      pausa.</span
                    >
                  </v-tooltip>
                </div>
              </div>

              <div class="text-label">Nombre: {{ reminder.name }}</div>
              <div class="text-label">
                Fecha creada: {{ formatReadableDate(reminder.created) }}
              </div>
              <div class="text-label">
                Última fecha de envío:
                {{ formatReadableDate(reminder.updated) }}
              </div>
              <div class="text-label">
                Tamaño de batch: entre {{ reminder.n_min }} y
                {{ reminder.n_max }}
              </div>
              <div class="text-label">
                Tiempo de espera entre batches: entre
                {{ reminder.t_min }} y {{ reminder.t_max }} segundos
              </div>
              <div class="text-label">
                Estadísticas (Total - {{ reminder.total }}):
              </div>
            </div>
            <div style="border: 1px solid black">
              <div
                class="mt-3"
                v-for="(key, value) in reminder.statistics"
                :key="key"
              >
                <div class="text-label ms-1">
                  {{ rename(value) }}: {{ key }} ({{
                    approximate(key, reminder.total)
                  }}%)
                </div>
                <div class="d-flex w-100">
                  <div
                    class="w-100 bg--primary"
                    style="height: 20px"
                    :style="{
                      width: `${approximate(key, reminder.total)}%`,
                    }"
                  ></div>
                  <div class="text-label ms-1">
                    {{ approximate(key, reminder.total) }}%
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex">
              <v-tooltip top :disabled="reminder.status == 'paused'">
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-btn
                      class="mt-2"
                      :disabled="reminder.status != 'paused'"
                      @click="continueReminder(reminder.name)"
                      >Continuar envío</v-btn
                    >
                  </div>
                </template>
                <span>{{
                  reminder.status == "ongoing"
                    ? "El recordatorio está en proceso"
                    : "El recordatorio ha finalizado"
                }}</span>
              </v-tooltip>
              <v-tooltip top :disabled="reminder.status == 'ongoing'">
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-btn
                      :disabled="reminder.status != 'ongoing'"
                      class="mt-2 ms-2"
                      @click="stopReminder(reminder.id)"
                      >Parar envío</v-btn
                    >
                  </div>
                </template>
                <span>{{
                  reminder.status == "paused"
                    ? "El recordatorio está pausada"
                    : "El recordatorio ha finalizado"
                }}</span>
              </v-tooltip>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </generic-dialog>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ChatbotReminder",
  data() {
    return {
      showReminderEditor: false,
      reminders: [],
      tMin: null,
      tMax: null,
      nMin: null,
      nMax: null,
      currentReminder: null,
    };
  },
  mounted() {
    this.getReminders();
  },
  props: {
    value: {
      required: true,
      type: Boolean,
    },
  },
  watch: {
    value() {
      this.getReminders();
    },
  },
  methods: {
    editDisabled(reminder) {
      return ["finished", "ongoing", "loading"].includes(reminder.status);
    },
    resetReminderEditor() {
      this.tMin = null;
      this.tMax = null;
      this.nMin = null;
      this.nMax = null;
      this.showReminderEditor = false;
      this.currentReminder = null;
    },
    saveReminder() {
      this.request({
        errorMessage: "error updating reminder",
        successMessage: "reminder updated successfully",
        type: "patch",
        endpoint: `reminder/${this.currentReminder.id}/`,
        body: {
          t_min: this.tMin,
          t_max: this.tMax,
          n_min: this.nMin,
          n_max: this.nMax,
        },
      })
        .then(() => {
          let currentReminder = this.reminders.find(
            (i) => i.id == this.currentReminder.id
          );
          currentReminder.t_min = this.tMin;
          currentReminder.t_max = this.tMax;
          currentReminder.n_min = this.nMin;
          currentReminder.n_max = this.nMax;
        })
        .finally(() => {
          this.resetReminderEditor();
        });
    },
    editReminder(reminder) {
      this.currentReminder = reminder;
      this.tMin = reminder.t_min;
      this.tMax = reminder.t_max;
      this.nMin = reminder.n_min;
      this.nMax = reminder.n_max;
      this.showReminderEditor = true;
    },
    getContinueText(reminder) {
      if (
        "pending" in reminder.statistics &&
        reminder.statistics["pending"] == reminder.total
      ) {
        return "Comenzar envío";
      }
      return "Continuar envío";
    },
    formatReadableDate(isoDateString) {
      const date = new Date(isoDateString);

      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const seconds = date.getSeconds().toString().padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    getReminders() {
      this.request({
        type: "get",
        endpoint: `reminder?chatbot__id=${this.chatbot.id}`,
      }).then((response) => {
        response.data.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        this.reminders = response.data;
        // this.reminders[0].status = "paused"; // "finished"; // finished, ongoing, paused, loading
      });
    },
    background(status) {
      return {
        finished: "#8cea79",
        ongoing: "#4671f2",
        paused: "#ef5b5b",
        loading: "#fff65e",
      }[status];
    },
    continueReminder(reminderName) {
      this.request({
        type: "post",
        endpoint: `send_reminder/${this.chatbot.name}/`,
        successMessage: "reminder sent successfully",
        errorMessage: "reminder could not be sent",
        body: {
          name: reminderName,
          start: 1,
        },
      }).then(() => {
        // new: change immediately
        let reminder = this.reminders.find((r) => r.name == reminderName);
        reminder.status = "ongoing";
        this.setToastNotification({
          toastState: true,
          toastMessage: "Recordatorio continuado correctamente.",
        });
      });
    },
    stopReminder(reminderId) {
      this.request({
        type: "patch",
        endpoint: `reminder/${reminderId}/`,
        successMessage: "reminder stopped successfully",
        errorMessage: "reminder could not be stopped",
        body: {
          status: "paused",
        },
      }).then(() => {
        let reminder = this.reminders.find((r) => r.id == reminderId);
        reminder.status = "paused";
      });
    },
    approximate(k, total) {
      return Math.round((100 * k) / total);
    },
    rename(key) {
      if (key == "") {
        return "200 (exitoso)";
      }
      return key;
    },
    ...mapActions({
      request: "utils/request",
    }),
  },
  computed: {
    ...mapGetters({
      chatbot: "chatbot/chatbot",
    }),
    validNumbers() {
      if (parseInt(this.tMin) > parseInt(this.tMax)) {
        return false;
      }
      if (parseInt(this.nMin) > parseInt(this.nMax)) {
        return false;
      }
      if (parseInt(this.nMin) > 1000 || parseInt(this.nMin) < 200) {
        return false;
      }
      if (parseInt(this.nMax) > 2000 || parseInt(this.nMax) < 201) {
        return false;
      }
      if (parseInt(this.tMin) > 60 || parseInt(this.tMin) < 1) {
        return false;
      }
      if (parseInt(this.tMax) > 90 || parseInt(this.tMax) < 2) {
        return false;
      }
      return true;
    },
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>
