<template>
  <generic-dialog v-model="computedValue" width="500" @save="saveChatbot">
    <div v-if="internalChatbot">
      <v-text-field
        readonly
        disabled
        label="Nombre interno"
        v-model="internalChatbot.name"
      >
      </v-text-field>
      <v-text-field
        label="Messaging service sid"
        v-model="internalChatbot.start_message_service"
      >
      </v-text-field>
      <v-select
        :items="['ES', 'EN']"
        v-model="chatbot.language"
        label="Idioma"
      ></v-select>
      <v-text-field label="Nombre visible" v-model="internalChatbot.label">
      </v-text-field>
      <div class="text-left">
        <div class="text-body-2 mb-2">Color</div>
        <v-btn-toggle v-model="internalChatbot.theme">
          <v-btn
            v-for="(i, index) in [
              'primary',
              'secondary',
              'tertiary',
              'quaternary',
            ]"
            :key="index"
            icon
            :value="i"
          >
            <v-icon :color="themes[i]">mdi-circle</v-icon>
          </v-btn>
          <tooltip class="mt-auto mb-auto" :width="600">
            <chatbot
              :externalTheme="internalChatbot.theme"
              :hasCloseButton="false"
              :width="550"
              :chats="[
                { source: 'Bot', text: '¡Hola! ¿Quieres hablar conmigo?' },
                { source: 'Me', text: '¡Claro!' },
                { source: 'Bot', text: 'Te informo que...' },
              ]"
              :showForm="false"
            />
          </tooltip>
        </v-btn-toggle>
      </div>
      <div class="text-left mt-2">
        <div class="text-body-2">Avatar</div>
        <image-file-input
          @setImage="setImage"
          :imageSource="internalChatbot.image"
        />
      </div>
    </div>
  </generic-dialog>
</template>
<script>
import Chatbot from '@/components/Chatbot.vue';
import ImageFileInput from '@/components/ImageFileInput.vue';
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'ChatbotEditor',
  props: {
    value: {
      required: true,
      type: Boolean,
    },
  },
  components: {
    ImageFileInput,
    Chatbot,
  },
  data() {
    return {
      internalChatbot: null,
      themes: {
        primary: 'indigo darken-2',
        secondary: 'red darken-3',
        tertiary: 'purple darken-4',
        quaternary: 'green darken-4',
      },
    };
  },
  mounted() {
    this.internalChatbot = {
      start_message_service: this.chatbot.start_message_service,
      label: this.chatbot.label,
      theme: this.chatbot.theme,
      image: this.chatbot.image,
      name: this.chatbot.name,
      id: this.chatbot.id,
    };
  },
  methods: {
    setImage(img) {
      this.internalChatbot.image = img;
    },
    ...mapActions({
      request: 'utils/request',
      updateChatbotBasic: 'chatbot/updateChatbotBasic',
    }),
    saveChatbot() {
      // patch
      this.request({
        errorMessage: 'error updating chatbot',
        successMessage: 'Chatbot updated successfully',
        type: 'patch',
        endpoint: `chatbot/${this.chatbot.id}/`,
        body: this.internalChatbot,
      }).then(() => {
        this.updateChatbotBasic({
          chatbot: this.internalChatbot,
        });
        //change the values in frontend probably with main or store
      });
    },
  },
  computed: {
    ...mapGetters({
      chatbot: 'chatbot/chatbot',
    }),
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
};
</script>
