<template>
  <generic-dialog title="Intervenciones" v-model="computedValue" width="500">
    <!-- intervention editor -->
    <generic-dialog
      v-model="showInterventionEditor"
      v-if="showInterventionEditor"
      @close="resetInterventionEditor"
      :title="`Intervención: ${currentIntervention.name}`"
      width="450"
    >
      <div class="d-flex">
        <v-text-field
          min="200"
          max="1000"
          type="number"
          label="n_min"
          hide-spin-buttons
          v-model="nMin"
          :rules="[
            (v) => v >= 200 || 'Valor mínimo de 200',
            (v) => v <= 1000 || 'Valor máximo de 1000',
          ]"
        ></v-text-field>
        <v-text-field
          min="201"
          hide-spin-buttons
          max="1000"
          type="number"
          :rules="[
            (v) => v >= 201 || 'Valor mínimo de 201',
            (v) => v <= 2000 || 'Valor máximo de 2000',
          ]"
          label="n_max"
          v-model="nMax"
        ></v-text-field>
        <tooltip class="mt-4"
          >Al momento de enviar el chatbot, se enviarán batches de un número
          aleatorio entre <b>{{ nMin }}</b> y <b>{{ nMax }}</b
          >.
        </tooltip>
      </div>
      <div
        v-if="parseInt(nMin) > parseInt(nMax)"
        class="color--error text-label"
      >
        El n mínimo no puede ser mayor al n máximo.
      </div>
      <div class="d-flex">
        <v-text-field
          min="5"
          max="60"
          type="number"
          hide-spin-buttons
          label="t_min"
          v-model="tMin"
          :rules="[
            (v) => v >= 1 || 'Valor mínimo de 1',
            (v) => v <= 60 || 'Valor máximo de 60',
          ]"
        ></v-text-field>

        <v-text-field
          min="10"
          max="90"
          type="number"
          hide-spin-buttons
          label="t_max"
          v-model="tMax"
          :rules="[
            (v) => v >= 2 || 'Valor mínimo de 2',
            (v) => v <= 90 || 'Valor máximo de 90',
          ]"
        ></v-text-field>
        <tooltip class="mt-4"
          >Al momento de enviar el chatbot, entre batches, se esperará un tiempo
          aleatorio entre <b>{{ tMin }}</b> y <b>{{ tMax }}</b> segundos.
        </tooltip>
      </div>
      <div
        v-if="parseInt(tMin) > parseInt(tMax)"
        class="color--error text-label"
      >
        El t mínimo no puede ser mayor al t máximo.
      </div>
      <div style="font-style: italic" class="text-left">
        Al momento de enviar el chatbot, se enviarán batches de un número
        aleatorio entre <b>{{ nMin }}</b> y <b>{{ nMax }}</b
        >. Después de cada batch, se esperará un tiempo aleatorio de entre
        <b>{{ tMin }}</b> y <b>{{ tMax }}</b> segundos.
      </div>
      <div class="d-flex w-100">
        <v-btn
          class="ms-auto me-auto mt-4"
          @click="saveIntervention"
          :disabled="interventionName == '' || !validNumbers"
          >Guardar</v-btn
        >
      </div>
    </generic-dialog>
    <!-- end intervention editor -->
    <div v-if="interventions.length == 0">
      No hay intervenciones para este chatbot.
    </div>
    <v-expansion-panels
      class="text-left"
      style="max-height: 50vh; overflow-y: auto"
    >
      <v-expansion-panel
        v-for="(intervention, index) in interventions"
        :key="index"
      >
        <v-expansion-panel-header>
          <div class="d-flex">
            <div class="h-100 mt-auto mb-auto">
              {{ intervention.name }} - {{ intervention.total }} usuarios
            </div>

            <v-card
              :style="{ background: background(intervention.status) }"
              class="ms-2 pa-2 rounded"
              >{{ intervention.status }}</v-card
            >
          </div></v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <div>
            <div class="pt-4 pe-4 pb-4">
              <div class="text-body d-flex">
                <div>Datos de intervención</div>
                <div class="ms-auto">
                  <v-tooltip top :disabled="intervention.status == 'paused'">
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <v-icon
                          @click="editIntervention(intervention)"
                          :disabled="editDisabled(intervention)"
                          color="blue"
                          class="ms-auto"
                          >mdi-pencil</v-icon
                        >
                      </div>
                    </template>
                    <span
                      >La intervención solo puede ser editada cuando está en
                      pausa.</span
                    >
                  </v-tooltip>
                </div>
              </div>
              <div class="text-label">Nombre: {{ intervention.name }}</div>
              <div class="text-label">
                Fecha creada: {{ formatReadableDate(intervention.created) }}
              </div>
              <div class="text-label">
                Última fecha de envío:
                {{ formatReadableDate(intervention.updated) }}
              </div>
              <div class="text-label">
                Tamaño de batch: entre {{ intervention.n_min }} y
                {{ intervention.n_max }}
              </div>
              <div class="text-label">
                Tiempo de espera entre batches: entre
                {{ intervention.t_min }} y {{ intervention.t_max }} segundos
              </div>
              <div class="text-label">
                Estadísticas (Total - {{ intervention.total }}):
              </div>
            </div>
            <div style="border: 1px solid black">
              <div
                class="mt-3"
                v-for="(key, value) in intervention.statistics"
                :key="key"
              >
                <div class="text-label ms-1">
                  {{ rename(value) }}: {{ key }} ({{
                    approximate(key, intervention.total)
                  }}%)
                </div>
                <div class="d-flex w-100">
                  <div
                    class="w-100 bg--primary"
                    style="height: 20px"
                    :style="{
                      width: `${approximate(key, intervention.total)}%`,
                    }"
                  ></div>
                  <div class="text-label ms-1">
                    {{ approximate(key, intervention.total) }}%
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex">
              <v-tooltip top :disabled="intervention.status == 'paused'">
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-btn
                      class="mt-2"
                      :disabled="intervention.status != 'paused'"
                      @click="continueIntervention(intervention.name)"
                      >{{ getContinueText(intervention) }}</v-btn
                    >
                  </div>
                </template>
                <span>{{
                  intervention.status == "ongoing"
                    ? "La intervención está en proceso"
                    : "La intervención ha finalizado"
                }}</span>
              </v-tooltip>
              <v-tooltip top :disabled="intervention.status == 'ongoing'">
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-btn
                      :disabled="intervention.status != 'ongoing'"
                      class="mt-2 ms-2"
                      @click="stopIntervention(intervention.id)"
                      >Parar envío</v-btn
                    >
                  </div>
                </template>
                <span>{{
                  intervention.status == "paused"
                    ? "La intervención está pausada"
                    : "La intervención ha finalizado"
                }}</span>
              </v-tooltip>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </generic-dialog>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ChatbotIntervention",
  data() {
    return {
      showInterventionEditor: false,
      interventions: [],
      tMin: null,
      tMax: null,
      nMin: null,
      nMax: null,
      currentIntervention: null,
    };
  },
  mounted() {
    this.getInterventions();
  },
  props: {
    value: {
      required: true,
      type: Boolean,
    },
  },
  watch: {
    value() {
      this.getInterventions();
    },
  },
  methods: {
    editDisabled(intervention) {
      return ["finished", "ongoing", "loading"].includes(intervention.status);
    },
    resetInterventionEditor() {
      this.tMin = null;
      this.tMax = null;
      this.nMin = null;
      this.nMax = null;
      this.showInterventionEditor = false;
      this.currentIntervention = null;
    },
    saveIntervention() {
      this.request({
        errorMessage: "error updating intervention",
        successMessage: "intervention updated successfully",
        type: "patch",
        endpoint: `intervention/${this.currentIntervention.id}/`,
        body: {
          t_min: this.tMin,
          t_max: this.tMax,
          n_min: this.nMin,
          n_max: this.nMax,
        },
      })
        .then(() => {
          let currentIntervention = this.interventions.find(
            (i) => i.id == this.currentIntervention.id
          );
          currentIntervention.t_min = this.tMin;
          currentIntervention.t_max = this.tMax;
          currentIntervention.n_min = this.nMin;
          currentIntervention.n_max = this.nMax;
        })
        .finally(() => {
          this.resetInterventionEditor();
        });
    },
    editIntervention(intervention) {
      this.currentIntervention = intervention;
      this.tMin = intervention.t_min;
      this.tMax = intervention.t_max;
      this.nMin = intervention.n_min;
      this.nMax = intervention.n_max;
      this.showInterventionEditor = true;
    },
    getContinueText(intervention) {
      if (
        "pending" in intervention.statistics &&
        intervention.statistics["pending"] == intervention.total
      ) {
        return "Comenzar envío";
      }
      return "Continuar envío";
    },
    formatReadableDate(isoDateString) {
      const date = new Date(isoDateString);

      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const seconds = date.getSeconds().toString().padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    getInterventions() {
      this.request({
        type: "get",
        endpoint: `intervention?chatbot__id=${this.chatbot.id}`,
      }).then((response) => {
        response.data.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        this.interventions = response.data;

        // this.interventions[0].status = "paused"; // "finished"; // finished, ongoing, paused, loading
      });
    },
    background(status) {
      return {
        finished: "#8cea79",
        ongoing: "#4671f2",
        paused: "#ef5b5b",
        loading: "#fff65e",
      }[status];
    },
    continueIntervention(interventionName) {
      this.request({
        type: "post",
        endpoint: `send_chatbot/${this.chatbot.name}/`,
        body: {
          name: interventionName,
          start: 1,
        },
      }).then(() => {
        // new: change immediately
        let intervention = this.interventions.find(
          (i) => i.name == interventionName
        );
        intervention.status = "ongoing";
        this.setToastNotification({
          toastState: true,
          toastMessage: "Intervención continuada correctamente.",
        });
      });
    },
    stopIntervention(interventionId) {
      this.request({
        type: "patch",
        endpoint: `intervention/${interventionId}/`,
        successMessage: "intervention stopped successfully",
        errorMessage: "intervention could not be stopped",
        body: {
          status: "paused",
        },
      }).then(() => {
        let intervention = this.interventions.find(
          (i) => i.id == interventionId
        );
        intervention.status = "paused";
      });
    },
    approximate(k, total) {
      return Math.round((100 * k) / total);
    },
    rename(key) {
      if (key == "") {
        return "200 (exitoso)";
      }
      return key;
    },
    ...mapActions({
      request: "utils/request",
      setToastNotification: "utils/setToastNotification",
    }),
  },
  computed: {
    ...mapGetters({
      chatbot: "chatbot/chatbot",
    }),
    validNumbers() {
      if (parseInt(this.tMin) > parseInt(this.tMax)) {
        return false;
      }
      if (parseInt(this.nMin) > parseInt(this.nMax)) {
        return false;
      }
      if (parseInt(this.nMin) > 1000 || parseInt(this.nMin) < 200) {
        return false;
      }
      if (parseInt(this.nMax) > 2000 || parseInt(this.nMax) < 201) {
        return false;
      }
      if (parseInt(this.tMin) > 60 || parseInt(this.tMin) < 1) {
        return false;
      }
      if (parseInt(this.tMax) > 90 || parseInt(this.tMax) < 2) {
        return false;
      }
      return true;
    },
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>
