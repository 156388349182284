<template>
  <div>
    <!-- delete dialog -->
    <generic-dialog v-model="deleteDialog" width="300">
      <div>¿Estás seguro de desasociar el recurso de este nodo?</div>

      <div class="d-flex mt-4">
        <v-btn @click="sendDeleteNodeResource" class="me-auto"
          >Sí, eliminar</v-btn
        >
        <v-btn @click="deleteDialog = false" class="ms-auto">No</v-btn>
      </div>
    </generic-dialog>
    <!-- edit node resource -->
    <generic-dialog
      v-model="computedValue"
      @save="saveNodeResource({ resource: internalNodeResource })"
      @delete="deleteDialog = true"
      title="Módulo de edición"
      :delete-condition="role == 'admin'"
      width="500"
      height="200"
      v-if="internalNodeResource"
    >
      <div class="pt-10">
        <v-select
          label="Recurso"
          outlined
          hide-details
          background="white"
          color="white"
          key
          v-model="internalNodeResource.resource"
          :items="chatbot.resources"
          item-text="name"
          item-value="id"
        ></v-select>
      </div>
    </generic-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'NodeResource',
  props: {
    nodeResource: {
      required: true,
    },
    value: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      deleteDialog: false,
      internalNodeResource: null,
    };
  },
  mounted() {
    this.internalNodeResource = { ...this.nodeResource };
  },
  methods: {
    ...mapActions({
      deleteNodeResource: 'chatbot/deleteNodeResource',
      saveNodeResource: 'chatbot/saveNodeResource',
    }),
    sendDeleteNodeResource() {
      this.deleteNodeResource({ resource: this.internalNodeResource }).then(
        () => {
          this.deleteDialog = false;
          this.internalNodeResource = null;
          this.$emit('selectNodeResource', null);
          this.$emit('input', false);
        }
      );
    },
  },
  computed: {
    ...mapGetters({
      role: 'chatbot/role',
      chatbot: 'chatbot/chatbot',
    }),
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
};
</script>
