<template>
  <div class="vw-100 h-100 d-flex" style="height: 100vh; background: lightgray">
    <v-card
      v-if="!shouldLogin"
      class="ma-auto pa-6 text-left"
      style="max-width: 600px; max-height: 300px"
    >
      <h3>
        {{ downloaded ? "Archivo descargado." : "Descargando archivo..." }}
      </h3>
    </v-card>
    <v-card
      v-else
      class="ma-auto pa-6 text-left"
      style="max-width: 600px; max-height: 300px"
    >
      <h2>Iniciar sesión</h2>
      <v-form @submit.prevent="checkCredentials">
        <v-text-field v-model="username" placeholder="Usuario"></v-text-field>
        <v-text-field
          v-model="password"
          placeholder="Contraseña"
          type="password"
        ></v-text-field>
        <v-btn type="submit" :loading="loading">Iniciar sesión</v-btn>
      </v-form>
    </v-card>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "DownloadFile",
  mounted() {
    const { uuid } = this.$route.params;
    this.uuid = uuid;
    this.download();
  },
  data() {
    return {
      uuid: null,
      downloaded: false,
      shouldLogin: false,
      username: "",
      password: "",
    };
  },
  methods: {
    ...mapActions({
      asyncFetch: "utils/asyncFetch",
      login: "utils/login",
    }),
    download() {
      this.asyncFetch({
        url: `download_file/${this.uuid}/`,
      })
        .then(() => {
          this.downloaded = true;
        })
        .catch(() => {
          this.shouldLogin = true;
          // try login
        });
    },
    checkCredentials() {
      this.login({
        username: this.username,
        password: this.password,
        captcha: "Este es el código secreto",
        stay: true,
      }).then(() => {
        this.shouldLogin = false;
        this.download();
      });
    },
  },
};
</script>
