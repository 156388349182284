var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('generic-dialog',{attrs:{"title":"Crear chatbot","width":"600"},model:{value:(_vm.computedValue),callback:function ($$v) {_vm.computedValue=$$v},expression:"computedValue"}},[_c('v-text-field',{attrs:{"label":"nombre del chatbot:","rules":[
        (v) =>
          _vm.validateName(v) ||
          'El nombre solo puede contener letras y guión bajo.',
        (v) =>
          _vm.shortName(v) ||
          'El nombre no puede ser de largo mayor a 50 caracteres.',
      ]},model:{value:(_vm.newChatbotName),callback:function ($$v) {_vm.newChatbotName=$$v},expression:"newChatbotName"}}),_c('v-file-input',{attrs:{"label":"(opcional) Contenido de chatbot:","accept":".json"},on:{"change":_vm.uploadChatbot},model:{value:(_vm.chatbotFile),callback:function ($$v) {_vm.chatbotFile=$$v},expression:"chatbotFile"}}),_c('v-btn',{attrs:{"disabled":!_vm.validName},on:{"click":_vm.createChatbot}},[_vm._v("Crear chatbot")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }