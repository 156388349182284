<template>
  <div
    class="pa-3"
    :style="
      showComments
        ? 'position: absolute;right: 0;top: 0;height: calc(100vh - 30px);width: 370px;background: white;z-index: 2000;'
        : 'position:absolute;right:0px;top:0px;z-index: 2000;background:transparent'
    "
    :class="showComments ? 'ma-3  pa-1 rounded' : ''"
  >
    <v-btn class="mb-2 me-auto" @click="showComments = !showComments">
      <v-icon small class="me-2">mdi-comment-outline</v-icon>
      {{ showComments ? "Esconder comentarios" : "Ver comentarios" }}
    </v-btn>
    <div
      v-if="showComments"
      style="max-height: calc(100vh - 90px); overflow-y: auto"
      class="pb-1"
    >
      <div
        v-if="filteredComments && filteredComments.length == 0"
        style="font-style: italic"
      >
        No hay comentarios.
      </div>
      <transition-group name="comment-list" tag="div">
        <v-card
          @click="$emit('clickComment', comment.node)"
          class="pt-2 pr-2 pl-2 pb-1 mt-1 ms-1 me-1 clickable comment-item"
          v-for="comment in filteredComments"
          :key="comment.id"
          :class="{ 'selected-comment': comment.node === selectedNode }"
        >
          <div class="top-right" style="position: absolute">
            <v-progress-circular
              color="primary"
              v-if="commentBeingDeleted == comment.id"
              class="me-1"
              indeterminate
              :size="20"
            ></v-progress-circular>
          </div>
          <div class="text-start" style="margin-left: 5px">
            <v-icon :color="getColor(comment.email)">mdi-account-circle</v-icon>
            <span class="ml-2" :style="'color: ' + getColor(comment.email)"> {{
              comment.email
            }}</span>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div v-if="commentBeingDeleted === comment.id">
                  <v-progress-circular indeterminate></v-progress-circular>
                </div>
                <v-icon
                  v-else
                  color="green"
                  style="position: relative; float: right"
                  @click.stop="sendDeleteComment(comment)"
                  v-bind="attrs"
                  v-on="on"
                  >mdi-check</v-icon
                >
              </template>
              <span>Resolver</span>
            </v-tooltip>
          </div>
          <p class="text-start" style="margin-left: 15px; margin-top: 3px">
            {{ comment.text }}
          </p>

          <div
            class="text-caption font-weight-light text-end"
            style="margin-right: 5px"
          >
            {{ getPrettyDate(comment.date_sent) }}
          </div>
        </v-card>
      </transition-group>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    selectedNode: {
      required: true,
    },
  },
  data() {
    return {
      colorList: ["#f08080", "#ADD8E6", "#90EE90", "#778899", "#87cefa"],
      deleteDialog: false,
      showComments: true,
      commentBeingDeleted: null,
    };
  },
  computed: {
    ...mapGetters({
      chatbot: "chatbot/chatbot",
    }),
    comments() {
      let comments = [];
      this.chatbot.nodes.forEach((n) => {
        n.comments.forEach((c) => {
          comments.push(c);
        });
      });
      if (this.selectedNode) {
        let selectedUserNodes = comments.filter(
          (comment) => comment.node === this.selectedNode
        );
        let remainingNodes = comments.filter(
          (comment) => comment.node !== this.selectedNode
        );
        selectedUserNodes.sort(
          (a, b) => new Date(a.date_sent) - new Date(b.date_sent)
        );
        remainingNodes.sort(
          (a, b) => new Date(a.date_sent) - new Date(b.date_sent)
        );
        return [...selectedUserNodes, ...remainingNodes];
      }

      comments.sort((a, b) => new Date(a.date_sent) - new Date(b.date_sent));
      return comments;
    },
    filteredComments() {
      if (!this.selectedNode) {
        return this.comments;
      }

      const selectedUserNodes = this.comments.filter(
        (comment) => comment.node === this.selectedNode
      );
      const remainingNodes = this.comments.filter(
        (comment) => comment.node !== this.selectedNode
      );
      return [...selectedUserNodes, ...remainingNodes];
    },
  },
  /*watch: {
			selectedNode() {
				this.handleUserChange();
			},
		},*/
  methods: {
    ...mapActions({
      deleteComment: "chatbot/deleteComment",
    }),
    sendDeleteComment(comment) {
      this.commentBeingDeleted = comment.id;
      this.deleteComment({ comment }).then(() => {
        this.commentBeingDeleted = null;
      });
    },
    /*handleUserChange() {


				this.comments = [...selectedUserNodes, ...remainingNodes];
			},*/
    getPrettyDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const hour = date.getHours().toString().padStart(2, "0");
      const minute = date.getMinutes().toString().padStart(2, "0");
      return `${year}-${month}-${day} a las ${hour}:${minute}`;
    },
    getColor(givenString) {
      const charCodeSum = givenString
        .split("")
        .reduce((sum, char) => sum + char.charCodeAt(0), 0);
      const index = charCodeSum % this.colorList.length;
      return this.colorList[index];
    },
  },
};
</script>

<style>
.comment-list-enter-active,
.comment-list-leave-active {
  transition: all 0.5s;
}

.comment-list-enter,
.comment-list-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

.comment-list-move {
  transition: transform 0.5s;
}

.selected-comment {
  background-color: #f7faff !important;
  margin-right: 2px;
  transform: translate(-2px, 0px);
}
</style>
