<template>
  <div>
    <!-- single edge -->
    <transition-weight
      v-model="editTransitionWeight"
      v-if="editTransitionWeight"
      :transition-weight="transitionWeight"
      @deleteTransitionWeight="deleteTransitionWeight"
      :hasAi="hasAi"
      @computeEdges="$emit('computeEdges')"
    />
    <generic-dialog v-model="deleteDialog" width="300">
      ¿Estás seguro de eliminar todos los pesos de transición, así eliminando
      esta arista?
      <div class="d-flex mt-4">
        <v-btn @click="deleteAllWeights()" class="me-auto">Sí, eliminar</v-btn>
        <v-btn @click="deleteDialog = false" class="ms-auto">No</v-btn>
      </div>
    </generic-dialog>
    <!-- edges -->
    <generic-dialog
      v-model="computedValue"
      width="500px"
      title="Edición de pesos de transición"
      title-class="h2"
      @delete="deleteDialog = true"
      :delete-condition="role == 'admin'"
    >
      <!-- buttons -->

      <div class="text-left">
        De <b>{{ getNodeName(selectedEdge.from) }}</b> a
        <b>{{ getNodeName(selectedEdge.to) }}</b>
      </div>
      <div
        v-for="(transition, index) in selectedEdge.weights"
        :key="index"
        class="d-flex mt-2 mb-2 ml-2 pl-2 pr-2 pt-1 pb-1 rounded border"
      >
        <div class="mt-auto mb-auto">
          {{ transition.feature }}: {{ transition.expected_weight }}
        </div>
        <v-btn
          @click="selectTransitionWeight(transition)"
          v-if="role == 'admin'"
          icon
          class="ms-auto"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </div>
      <v-btn
        @click="sendAddTransitionWeight()"
        class="mt-2 mb-2"
        v-if="role == 'admin'"
        >Agregar peso de transición<v-icon class="ms-2">mdi-plus</v-icon></v-btn
      >
    </generic-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import TransitionWeight from '@/components/TransitionWeight.vue';
export default {
  name: 'Edge',
  components: { TransitionWeight },
  data() {
    return {
      transitionWeight: null,
      editTransitionWeight: false,
      deleteDialog: false,
    };
  },

  props: {
    value: {
      required: true,
      type: Boolean,
    },
    selectedEdge: {
      required: true,
    },
  },
  methods: {
    ...mapActions({
      addTransitionWeight: 'chatbot/addTransitionWeight',
      setToastNotification: 'utils/setToastNotification',
      deleteTransitionWeightStore: 'chatbot/deleteTransitionWeight',
    }),
    getNodeName(nodeId) {
      return this.chatbot.nodes.find((n) => n.id == nodeId).name;
    },
    selectTransitionWeight(transition) {
      this.transitionWeight = null;
      this.$nextTick(() => {
        this.transitionWeight = transition;
        if (transition) {
          this.editTransitionWeight = true;
        }
      });
    },
    deleteAllWeights() {
      this.selectedEdge.weights.forEach((w) => {
        this.deleteTransitionWeightStore({ transitionWeight: w }).then(() => {
          this.$emit('computeEdges');
        });
      });

      this.$emit('input', false);
    },
    deleteTransitionWeight() {
      this.$emit('computeEdges');
    },
    sendAddTransitionWeight() {
      if (
        this.selectedEdge.weights.filter((t) => t.feature == 'default').length >
        0
      ) {
        this.setToastNotification({
          toastState: false,
          toastMessage:
            'Ya existe una arista con característica por defecto. Favor modificarla antes de agregar otra.',
        });
      } else {
        this.addTransitionWeight({
          source: this.selectedEdge.from,
          destination: this.selectedEdge.to,
          feature: 'default',
          expected_weight: 0,
        }).then(() => {
          this.$emit('computeEdges');
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      role: 'chatbot/role',
      chatbot: 'chatbot/chatbot',
    }),
    hasAi() {
      let node = this.chatbot.nodes.find((n) => n.id == this.selectedEdge.from);
      if (node) {
        if (node.ai) {
          return true;
        }
      }
      return false;
    },
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
};
</script>
