<template>
  <div>
    <answer-weight
      v-model="editAnswerWeight"
      :answer-weight="answerWeight"
      v-if="answerWeight"
      @selectAnswerWeight="selectAnswerWeight"
    />
    <!-- delete dialog -->
    <generic-dialog v-model="deleteDialog" width="300">
      ¿Estás seguro de eliminar esta respuesta?
      <div class="d-flex mt-4">
        <v-btn @click="sendDeleteAnswer" class="me-auto">Sí, eliminar</v-btn>
        <v-btn @click="deleteDialog = false" class="ms-auto">No</v-btn>
      </div>
    </generic-dialog>
    <!-- answer -->
    <generic-dialog
      title="Edita tu respuesta y asígnale características"
      v-model="computedValue"
      width="600"
      v-if="internalAnswer"
      @save="saveAnswer({ answer: internalAnswer })"
      @delete="deleteDialog = true"
      :delete-condition="role == 'admin'"
      :save-condition="role == 'admin' || role == 'writer'"
    >
      <div class="d-flex text-left mt-7">
        <v-text-field
          :disabled="role == 'reader'"
          :counter="50"
          v-model="internalAnswer.text"
          class="mb-2"
          label="Respuesta"
          dense
          outlined
          hide-details
        >
        </v-text-field>
      </div>
      <div class="d-flex text-left" v-if="isListPicker">
        <v-text-field
          :disabled="role == 'reader'"
          v-model="internalAnswer.description"
          label="Descripción"
          dense
          outlined
          hide-details
        >
        </v-text-field>
        <div class="pt-2">
          <tooltip>
            Si el nodo es de tipo 'list-picker',
            <b>cuando se envía el chatbot por WhatsApp</b>, se puede agregar una
            <b>descripción a la alternativa</b>.
          </tooltip>
        </div>
      </div>
      <div class="d-flex text-left mt-2">
        <v-text-field
          :disabled="role == 'reader'"
          v-model="internalAnswer.number"
          class="mb-2"
          type="number"
          hide-spin-buttons
          label="Orden"
          dense
          outlined
          hide-details
        >
        </v-text-field>
        <div class="pt-2">
          <tooltip>
            El orden relativo de esta respuesta en relación a otras. Las
            respuestas se mostrarán ordenadas de menor a mayor.
          </tooltip>
        </div>
      </div>
      <div class="w-100">
        <div class="text-left pt-2 pb-2">
          <div class="d-flex">
            <div>
              <h3>Características asignadas respondiendo esta opción</h3>
            </div>
            <tooltip>
              Cuando un usuario del chatbot responda la pregunta con esta
              respuesta, se le asignará los siguientes valores para las
              siguientes características:
            </tooltip>
          </div>
        </div>
        <div
          v-for="(weight, index) in internalAnswer.weights"
          :key="index"
          class="d-flex justify-content-center align-items-center w-100"
        >
          <v-card class="d-flex w-100 pa-2" style="min-width: 100%">
            <div class="mt-auto mb-auto">
              {{ weight.feature }}: {{ weight.weight }}
            </div>

            <v-icon
              class="ms-auto"
              v-if="role == 'admin'"
              @click="selectAnswerWeight(weight)"
              >mdi-pencil</v-icon
            >
          </v-card>
        </div>
      </div>

      <v-btn
        :disabled="
          internalAnswer.id === undefined || internalAnswer.id === null
        "
        @click="sendAddAnswerWeight"
        class="mt-4"
        v-if="role == 'admin'"
        >Agregar característica<v-icon class="ms-2">mdi-plus</v-icon></v-btn
      >
    </generic-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import AnswerWeight from "@/components/AnswerWeight.vue";
export default {
  name: "Answer",
  components: { AnswerWeight },
  data() {
    return {
      answerWeight: null,
      deleteDialog: false,
      internalAnswer: null,
      editAnswerWeight: false,
    };
  },
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    answer: {
      required: true,
      type: Object,
    },
    isListPicker: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  mounted() {
    this.internalAnswer = this.answer;
  },

  methods: {
    ...mapActions({
      saveAnswer: "chatbot/saveAnswer",
      deleteAnswer: "chatbot/deleteAnswer",
      addAnswerWeight: "chatbot/addAnswerWeight",
    }),
    sendAddAnswerWeight() {
      this.selectAnswerWeight(null);
      this.addAnswerWeight({ answer: this.internalAnswer });
    },
    selectAnswerWeight(answerWeight) {
      this.answerWeight = null;
      this.$nextTick(() => {
        this.answerWeight = answerWeight;
        if (answerWeight) {
          this.editAnswerWeight = true;
        }
      });
    },
    sendDeleteAnswer() {
      this.deleteAnswer({ answer: this.internalAnswer }).then(() => {
        this.deleteDialog = false;
        this.$emit("input", false);
      });
    },
  },
  computed: {
    ...mapGetters({
      role: "chatbot/role",
    }),
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>
