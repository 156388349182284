<template>
  <generic-dialog
    v-model="computedValue"
    width="500"
    title="Interacciones de recordatorio"
  >
    <div class="text-left">
      Escriba las <b>variables y características</b> que quiere descargar de los
      usuarios presentes en la interacción. Si desea descargar más de una,
      <b>separar por coma</b>. Si no desea descargar ninguna variable ni
      característica, <b>dejar en blanco</b>.

      <br />
      Además, elige el recordatorio de interés para ver las interacciones desde
      ese recordatorio.
    </div>
    <v-text-field
      label="variables"
      v-model="variables"
      placeholder="name,last_name"
    ></v-text-field>
    <v-text-field
      v-model="features"
      label="características"
      placeholder="want_to_talk"
    ></v-text-field>
    <v-select
      item-text="name"
      label="recordatorio"
      v-model="reminder"
      return-object
      :items="reminders"
    ></v-select>
    <v-btn
      :loading="downloading"
      @click="downloadChatbotReminders"
      :disabled="reminder == null"
    >
      Descargar interacciones
    </v-btn>
  </generic-dialog>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ChatbotInteractionDownloader",
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    intervention: {
      required: false,
      default: null,
    },
  },
  mounted() {
    this.downloading = false;
    this.getReminders();
  },
  methods: {
    ...mapActions({
      request: "utils/request",
    }),
    getReminders() {
      this.request({
        type: "get",
        endpoint: `reminder?chatbot__id=${this.chatbot.id}`,
      })
        .then((response) => {
          response.data.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
          this.reminders = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    downloadChatbotReminders() {
      this.downloading = true;
      this.$emit(
        "downloadChatbotReminders",
        this.variables,
        this.features,
        this.reminder
      );
      this.downloading = false;
    },
  },
  data() {
    return {
      variables: "",
      features: "",
      downloading: false,
      reminder: null,
      reminders: [],
    };
  },
  computed: {
    ...mapGetters({
      chatbot: "chatbot/chatbot",
    }),
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>
