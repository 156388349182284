var render = function render(){var _vm=this,_c=_vm._self._c;return _c('generic-dialog',{attrs:{"delete-condition":_vm.resource.id != undefined && _vm.resource.id != null,"save-condition":_vm.validResource,"width":"500","title":"Recurso"},on:{"save":_vm.saveResource,"delete":function($event){_vm.deleteDialog = true},"close":function($event){return _vm.$emit('setEmptyResource')}},model:{value:(_vm.computedValue),callback:function ($$v) {_vm.computedValue=$$v},expression:"computedValue"}},[_c('generic-dialog',{attrs:{"width":"300"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_vm._v(" ¿Estás seguro de eliminar este recurso? "),_c('div',{staticClass:"d-flex mt-4"},[_c('v-btn',{staticClass:"me-auto",on:{"click":_vm.confirmDeleteResource}},[_vm._v("Sí, eliminar")]),_c('v-btn',{staticClass:"ms-auto",on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v("No")])],1)]),_c('v-text-field',{attrs:{"label":"Nombre"},model:{value:(_vm.resource.name),callback:function ($$v) {_vm.$set(_vm.resource, "name", $$v)},expression:"resource.name"}}),_c('v-textarea',{attrs:{"label":"Descripción"},model:{value:(_vm.resource.description),callback:function ($$v) {_vm.$set(_vm.resource, "description", $$v)},expression:"resource.description"}}),_c('v-select',{attrs:{"label":"Tipo de recurso","items":['PDF', 'Sitio Web', 'Texto plano', 'Excel / CSV']},model:{value:(_vm.resource.type),callback:function ($$v) {_vm.$set(_vm.resource, "type", $$v)},expression:"resource.type"}}),(_vm.resource.type == 'Texto plano')?_c('v-textarea',{attrs:{"label":"Contenido"},model:{value:(_vm.resource.content),callback:function ($$v) {_vm.$set(_vm.resource, "content", $$v)},expression:"resource.content"}}):(_vm.resource.type == 'Sitio Web')?_c('v-text-field',{attrs:{"rules":[
      (v) => !!v || 'URL es requerido',
      (v) =>
        /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/.test(
          v
        ) || 'Favor escribe un URL válido.',
    ],"label":"URL"},model:{value:(_vm.resource.content),callback:function ($$v) {_vm.$set(_vm.resource, "content", $$v)},expression:"resource.content"}}):_vm._e(),(
      _vm.resource.file == '' && ['PDF', 'Excel / CSV'].includes(_vm.resource.type)
    )?_c('v-file-input',{attrs:{"accept":_vm.resource.type == 'PDF'
        ? '.pdf'
        : '.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .tsv',"label":"Archivo"},on:{"change":_vm.uploadFile}}):_c('v-btn',{on:{"click":function($event){return _vm.openLink(_vm.resource.file)}}},[_c('v-icon',[_vm._v("mdi-"+_vm._s(_vm.getIcon()))]),_vm._v("Ver Archivo ")],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }