<template>
  <div>
    <generic-dialog v-model="deleteDialog" width="300">
      ¿Estás seguro de eliminar esta transición?
      <div class="d-flex mt-4">
        <v-btn @click="sendDeleteTransitionWeight" class="me-auto"
          >Sí, eliminar</v-btn
        >
        <v-btn @click="deleteDialog = false" class="ms-auto">No</v-btn>
      </div>
    </generic-dialog>
    <generic-dialog
      v-if="internalTransitionWeight !== null"
      v-model="computedValue"
      width="500"
      height="200"
      title="Módulo de edición"
      @save="sendSaveTransitionWeight()"
      :save-condition="role == 'admin'"
      @delete="deleteDialog = true"
      :delete-condition="role == 'admin'"
    >
      <div class="pt-10">
        <v-text-field
          dense
          outlined
          hide-details
          :disabled="role != 'admin'"
          label="Característica"
          v-model="internalTransitionWeight.feature"
        ></v-text-field>
        <v-text-field
          type="number"
          label="Valor"
          dense
          outlined
          class="pt-2"
          hide-details
          :disabled="role != 'admin'"
          hide-spin-buttons
          v-model="internalTransitionWeight.expected_weight"
        >
        </v-text-field>
        <v-textarea
          v-if="hasAi"
          class="pt-2"
          dense
          outlined
          hide-details
          :disabled="role != 'admin'"
          label="Descripción [AI]"
          v-model="internalTransitionWeight.description"
        >
        </v-textarea>
      </div>
    </generic-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'TransitionWeight',
  data() {
    return {
      internalTransitionWeight: null,
      deleteDialog: false,
    };
  },
  mounted() {
    this.internalTransitionWeight = Object.assign({}, this.transitionWeight);
  },
  props: {
    hasAi: {
      required: false,
      default: false,
      type: Boolean,
    },
    value: {
      required: true,
      type: Boolean,
    },
    transitionWeight: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      role: 'chatbot/role',
      chatbot: 'chatbot/chatbot',
    }),
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
  methods: {
    ...mapActions({
      saveTransitionWeight: 'chatbot/saveTransitionWeight',
      deleteTransitionWeight: 'chatbot/deleteTransitionWeight',
    }),
    sendSaveTransitionWeight() {
      this.saveTransitionWeight({
        transitionWeight: this.internalTransitionWeight,
      }).then(() => {
        this.internalTransitionWeight = null;
        this.$emit('computeEdges');
        this.$emit('input', false);
      });
    },
    sendDeleteTransitionWeight() {
      this.deleteTransitionWeight({
        transitionWeight: this.internalTransitionWeight,
      }).then(() => {
        this.deleteDialog = false;
        this.$emit('deleteTransitionWeight');
        this.$emit('input', false);
      });
    },
  },
};
</script>
