<template>
  <div>
    <v-container style="height: 100vh">
      <div class="text-h6 text-left mb-2">Elige una organización.</div>
      <v-row>
        <v-col
          v-for="(org, index) in organizations"
          :key="index"
          cols="12"
          md="4"
        >
          <v-card @click="selectOrganization(org)" class="pa-4">
            <v-progress-circular
              color="primary"
              v-if="loading && selectedOrganization == org.id"
              class="me-1"
              indeterminate
              :size="20"
            ></v-progress-circular
            >{{ org.name }}
          </v-card>
        </v-col>
      </v-row>
      <div class="mt-4 text-left">
        <v-btn @click="logout">Cerrar sesión</v-btn>
      </div>
    </v-container>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Organization",
  computed: {
    ...mapGetters({
      organizations: "chatbot/organizations",
      organization: "chatbot/organization",
    }),
  },
  mounted() {
    this.loading = false;
  },
  data() {
    return {
      loading: false,
      selectedOrganization: null,
    };
  },
  methods: {
    selectOrganization(organization) {
      this.loading = true;
      this.selectedOrganization = organization.id;
      this.setOrganization({ organization }).then(() => {
        this.getChatbots().then(() => {
          this.$router.push({ name: "main" });
        });
      });
    },
    ...mapActions({
      getChatbots: "chatbot/getChatbots",
      logout: "utils/logout",
      setOrganization: "chatbot/setOrganization",
      setOrganizations: "chatbot/setOrganizations",
    }),
  },
};
</script>
