<template>
  <div>
    <!-- delete dialog -->
    <generic-dialog v-model="deleteDialog" width="300">
      <div>¿Estás seguro de eliminar este peso de salida de nodo?</div>
      <div class="d-flex mt-4">
        <v-btn @click="sendDeleteNodeWeight" class="me-auto"
          >Sí, eliminar</v-btn
        >
        <v-btn @click="deleteDialog = false" class="ms-auto">No</v-btn>
      </div>
    </generic-dialog>
    <!-- edit node weight -->
    <generic-dialog
      v-model="computedValue"
      width="500"
      height="200"
      title="Módulo de edición"
      @save="saveNodeWeight({ nodeWeight: internalNodeWeight })"
      @delete="deleteDialog = true"
      :delete-condition="role == 'admin'"
      v-if="internalNodeWeight"
    >
      <div class="pt-10">
        <v-text-field
          dense
          :disabled="role != 'admin'"
          outlined
          hide-details
          label="Característica"
          v-model="internalNodeWeight.feature"
        ></v-text-field>
        <v-text-field
          type="number"
          label="Valor"
          :disabled="role != 'admin'"
          dense
          outlined
          class="pt-2"
          hide-details
          hide-spin-buttons
          v-model="internalNodeWeight.weight"
        >
        </v-text-field>
      </div>
    </generic-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "NodeWeight",
  props: {
    nodeWeight: {
      required: true,
    },
    value: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      deleteDialog: false,
      internalNodeWeight: null,
    };
  },
  mounted() {
    this.internalNodeWeight = { ...this.nodeWeight };
  },
  methods: {
    ...mapActions({
      deleteNodeWeight: "chatbot/deleteNodeWeight",
      saveNodeWeight: "chatbot/saveNodeWeight",
    }),
    sendDeleteNodeWeight() {
      this.deleteNodeWeight({ nodeWeight: this.internalNodeWeight }).then(
        () => {
          this.deleteDialog = false;
          this.internalNodeWeight = null;
          this.$emit("selectNodeWeight", null);
          this.$emit("input", false);
        }
      );
    },
  },
  computed: {
    ...mapGetters({
      role: "chatbot/role",
    }),
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>
