import Vue from "vue";
import Vuex from "vuex";
import utils from "./utils.module";
import createPersistedState from "vuex-persistedstate";
import chatbot from "./chatbot.module";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState({ storage: window.sessionStorage })],
  state: {},
  mutations: {},
  actions: {},
  modules: {
    utils,
    chatbot,
  },
});
