<template>
  <div style="position: absolute; top: 0; z-index: 104">
    <div
      style="
        display: flex;
        flex-direction: column;
        gap: 8px;
        background: transparent;
        width: max-content;
        max-width: 250px;
        margin: 1rem;
      "
    >
      <!-- user -->
      <v-card
        class="rounded-pill text-left d-flex pe-4"
        style="min-width: fit-content !important"
      >
        <v-icon x-large :color="getColor(user)" class="me-2 ms-1"
          >mdi-account-circle</v-icon
        >
        <div class="d-flex flex-column">
          <span :style="'color: ' + getColor(user)">{{ user }}</span>
          <span>{{ roleName }}</span>
        </div>
      </v-card>
      <!-- select -->
      <v-select
        solo
        hide-details
        placeholder="Elige un chatbot"
        :items="sortedChatbots"
        @change="changeChatbot"
        :value="chatbot"
        item-text="label"
        return-object
      ></v-select>

      <!-- add node -->

      <v-btn
        small
        prepend="mdi-chair"
        prepend-icon="mdi-chair"
        @click="$emit('addNodeAndCenter')"
        v-if="buttons.includes('add-node') && role == 'admin'"
      >
        <v-icon left> mdi-plus-circle-outline</v-icon>Agregar nodo
      </v-btn>
      <!-- add edge -->
      <v-btn
        small
        @click="$emit('addEdge')"
        v-if="buttons.includes('add-edge') && role == 'admin'"
        ><v-icon left> mdi-vector-polyline-plus</v-icon>Agregar arista</v-btn
      >
      <!-- add user role -->
      <v-btn
        small
        v-if="buttons.includes('user-role') && isSuperAdmin"
        @click="$emit('setShowUserRole')"
        ><v-icon left> mdi-account-lock-open</v-icon>Asignar roles</v-btn
      >
      <!-- test bot -->
      <v-btn small v-if="buttons.includes('test-bot')" @click="$emit('testBot')"
        ><v-icon left> mdi-chat-outline</v-icon>Testear bot</v-btn
      >

      <v-btn
        small
        v-if="buttons.includes('edit-features')"
        @click="$emit('setShowFeatures')"
        ><v-icon left> mdi-pencil</v-icon
        >{{
          role == "admin" ? "Editar características" : "Ver características"
        }}</v-btn
      >
      <!-- assign variables -->
      <v-btn
        small
        @click="$emit('getVariables')"
        v-if="buttons.includes('get-variables') && role == 'admin'"
      >
        <v-icon left> mdi-account</v-icon>
        Asignar variables
      </v-btn>
      <!-- reset features -->
      <v-btn
        small
        @click="$emit('resetFeatures')"
        v-if="buttons.includes('reset-features') && role == 'admin'"
      >
        <v-icon left> mdi-lock-reset</v-icon>
        Resetear características
      </v-btn>
      <!-- select nodes -->
      <!-- <v-btn
        @click="$emit('setDrag', { drag: false })"
        v-if="buttons.includes('set-drag') && role == 'admin'"
      >
        <v-icon left>mdi-cursor-default-outline</v-icon>
        Duplicar sección
      </v-btn>-->
      <v-btn
        small
        @click="$emit('createChatbotDialog')"
        v-if="buttons.includes('create-chatbot') && isSuperAdmin"
        ><v-icon left> mdi-chat-plus-outline</v-icon>Agregar chatbot</v-btn
      >
      <!-- send chatbot -->
      <v-btn
        small
        @click="$emit('setSendChatbotDialog')"
        v-if="buttons.includes('send-chatbot') && role == 'admin'"
      >
        <v-icon left> mdi-message-arrow-right-outline</v-icon>
        Crear intervención</v-btn
      >
      <!-- send chatbot -->
      <v-btn
        small
        @click="$emit('setSendReminderDialog')"
        v-if="buttons.includes('send-reminder') && role == 'admin'"
      >
        <v-icon left> mdi-bell-badge-outline</v-icon>
        Crear recordatorio</v-btn
      >
      <!-- get interventions -->
      <v-btn
        small
        @click="$emit('setShowInterventions')"
        v-if="buttons.includes('get-interventions') && role == 'admin'"
      >
        <v-icon left> mdi-forum-outline</v-icon>
        Ver intervenciones</v-btn
      >
      <!-- get reminders -->
      <v-btn
        small
        @click="$emit('setShowReminders')"
        v-if="buttons.includes('get-reminders') && role == 'admin'"
      >
        <v-icon left> mdi-message-badge-outline</v-icon>
        Ver recordatorios</v-btn
      >
      <!-- download interactions -->
      <v-btn
        small
        v-if="buttons.includes('download-interactions')"
        @click="$emit('openChatbotInteractions')"
      >
        <v-icon left> mdi-download-outline</v-icon>
        Descargar interacciones</v-btn
      >
      <!-- download reminders -->
      <v-btn
        small
        v-if="buttons.includes('download-reminders')"
        @click="$emit('openChatbotReminders')"
      >
        <v-icon left> mdi-download-outline</v-icon>
        Descargar recordatorios</v-btn
      >
      <!-- edit chatbot -->
      <v-btn
        small
        v-if="buttons.includes('edit-chatbot')"
        @click="$emit('allowEditChatbot')"
      >
        <v-icon left> mdi-chat-processing-outline</v-icon>
        Editar chatbot</v-btn
      >
      <!-- add resource -->
      <v-btn
        small
        v-if="buttons.includes('add-resource')"
        @click="$emit('checkResources')"
      >
        <v-icon left> mdi-file</v-icon>
        Ver recursos</v-btn
      >
      <!-- logout -->
      <v-btn small v-if="buttons.includes('logout')" @click="logout"
        ><v-icon left> mdi-logout</v-icon>Logout</v-btn
      >

      <!-- show Nomenclature -->
      <v-btn
        small
        class="bg--primary color--neutral-100-t"
        @click="$emit('showNomenclature')"
        ><v-icon left> mdi-help-circle-outline</v-icon>Ayuda</v-btn
      >

      <slot> </slot>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ButtonPanel",
  props: {
    buttons: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      colorList: ["#f08080", "#ADD8E6", "#90EE90", "#778899", "#87cefa"],
    };
  },
  methods: {
    ...mapActions({
      recolor: "chatbot/recolor",
      setChatbot: "chatbot/setChatbot",
      logout: "utils/logout",
    }),
    getColor(givenString) {
      const charCodeSum = givenString
        .split("")
        .reduce((sum, char) => sum + char.charCodeAt(0), 0);
      const index = charCodeSum % this.colorList.length;
      return this.colorList[index];
    },
    changeChatbot(chatbot) {
      this.$emit("setShowNetwork", false);
      this.$emit("closeChatbot");
      this.$nextTick(() => {
        let chatbotId = this.chatbots.findIndex((cb) => cb.id == chatbot.id);
        this.setChatbot(chatbotId);
        this.$emit("setShowNetwork", true);
      });
    },
    downloadChatbot() {
      let chatbot = this.selectedChatbot;
      this.deleteKeys(chatbot, ["role", "node", "answer"]);
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/json;charset=utf-8," +
          encodeURIComponent(JSON.stringify(chatbot))
      );
      element.setAttribute("download", `${chatbot.name}.json`);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    deleteKeys(obj, keys) {
      for (let prop in obj) {
        keys.forEach((key) => {
          if (prop === key) {
            delete obj[prop];
          }
        });
        if (typeof obj[prop] === "object") {
          this.deleteKeys(obj[prop], keys);
        }
      }
    },
  },
  computed: {
    sortedChatbots() {
      let chatbots = this.chatbots;
      chatbots.sort((a, b) =>
        a.label.toLowerCase() > b.label.toLowerCase()
          ? 1
          : b.label.toLowerCase() > a.label.toLowerCase()
          ? -1
          : 0
      );
      return chatbots;
    },
    ...mapGetters({
      user: "utils/user",
      chatbots: "chatbot/chatbots",
      chatbot: "chatbot/chatbot",
      role: "chatbot/role",
      isSuperAdmin: "utils/isSuperAdmin",
    }),
    roleName() {
      if (this.isSuperAdmin) {
        return "Superadmin";
      } else if (this.role == "admin") {
        return "Administrador";
      } else if (this.role == "reader") {
        return "Lector";
      } else {
        return "Escritor";
      }
    },
  },
};
</script>
