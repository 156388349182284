<template>
  <v-app>
    <div
      id="app"
      style="max-width: 100vw; overflow-x: hidden; overflow-y: hidden"
    >
      <ToastNotification />
      <router-view />
    </div>
  </v-app>
</template>

<script>
import ToastNotification from "@/components/ToastNotification.vue";
//import { mapActions } from "vuex";
export default {
  name: "App",
  components: { ToastNotification },
  //methods: {
  //  ...mapActions({
  //    getServerStatus: "getServerStatus",
  //  }),
  //},
  //data() {
  //  return {
  //    status: null,
  //  };
  //},
  //mounted() {
  //  this.getServerStatus()
  //    .then((response) => {
  //      this.status = response;
  //    })
  //    .catch((error) => {
  //      this.status = error;
  //    });
  //},
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Karla");
:root {
  --color-black: black;
  font-family: Karla, Helvetica, Arial, sans-serif;
}
* {
  margin: 0;
  padding: 0;
}
*,
*::after,
*::before {
  box-sizing: border-box;
}
body {
  margin: 0;
}
html {
  scroll-behavior: smooth !important;
  box-sizing: border-box;
  width: 100%;
}
#app {
  font-family: Karla;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #424242;
  position: relative;
}
</style>
