var render = function render(){var _vm=this,_c=_vm._self._c;return _c('generic-dialog',{attrs:{"width":"500"},model:{value:(_vm.computedValue),callback:function ($$v) {_vm.computedValue=$$v},expression:"computedValue"}},[_c('generic-dialog',{attrs:{"width":"300"},model:{value:(_vm.showInstructions),callback:function ($$v) {_vm.showInstructions=$$v},expression:"showInstructions"}},[_c('div',[_vm._v(" Recordatorio fue creado. Para ver estado de carga de usuarios de recordatorio, ir al panel "),_c('b',[_vm._v("ver recordatorios")]),_vm._v(". Una vez cargado los usuarios, en el mismo panel hacer click en \"Comenzar envío\". ")]),_c('v-btn',{staticClass:"mt-2",on:{"click":function($event){return _vm.$emit('input', false)}}},[_vm._v("OK")])],1),_c('div',[_vm._v(" Para hacer envío de recordatorio, debe subir un .csv con la información de los usuarios a enviar. La única columna obligatoria es \"number\", con formato '569XXXXXXXX'. Otras columnas se utilizarán como variables de flujo de chatbot. ")]),_c('v-text-field',{attrs:{"label":"Nombre del recordatorio","hide-details":""},model:{value:(_vm.reminderName),callback:function ($$v) {_vm.reminderName=$$v},expression:"reminderName"}}),_c('v-autocomplete',{attrs:{"label":"Nodo desde el cual enviar recordatorio","items":_vm.sortedNodes,"item-text":"name","item-value":"name","hide-details":""},model:{value:(_vm.nodeName),callback:function ($$v) {_vm.nodeName=$$v},expression:"nodeName"}}),_c('div',{staticClass:"d-flex"},[_c('v-text-field',{attrs:{"min":"200","max":"1000","type":"number","label":"n_min","hide-spin-buttons":"","rules":[
        (v) => v >= 200 || 'Valor mínimo de 200',
        (v) => v <= 1000 || 'Valor máximo de 1000',
      ]},model:{value:(_vm.nMin),callback:function ($$v) {_vm.nMin=$$v},expression:"nMin"}}),_c('v-text-field',{attrs:{"min":"201","hide-spin-buttons":"","max":"1000","type":"number","rules":[
        (v) => v >= 201 || 'Valor mínimo de 201',
        (v) => v <= 2000 || 'Valor máximo de 2000',
      ],"label":"n_max"},model:{value:(_vm.nMax),callback:function ($$v) {_vm.nMax=$$v},expression:"nMax"}}),_c('tooltip',{staticClass:"mt-4"},[_vm._v("Al momento de enviar el chatbot, se enviarán batches de un número aleatorio entre "),_c('b',[_vm._v(_vm._s(_vm.nMin))]),_vm._v(" y "),_c('b',[_vm._v(_vm._s(_vm.nMax))]),_vm._v(". ")])],1),(parseInt(_vm.nMin) > parseInt(_vm.nMax))?_c('div',{staticClass:"color--error text-label"},[_vm._v(" El n mínimo no puede ser mayor al n máximo. ")]):_vm._e(),_c('div',{staticClass:"d-flex"},[_c('v-text-field',{attrs:{"min":"5","max":"60","type":"number","hide-spin-buttons":"","label":"t_min","rules":[
        (v) => v >= 1 || 'Valor mínimo de 1',
        (v) => v <= 60 || 'Valor máximo de 60',
      ]},model:{value:(_vm.tMin),callback:function ($$v) {_vm.tMin=$$v},expression:"tMin"}}),_c('v-text-field',{attrs:{"min":"10","max":"90","type":"number","hide-spin-buttons":"","label":"t_max","rules":[
        (v) => v >= 2 || 'Valor mínimo de 2',
        (v) => v <= 90 || 'Valor máximo de 90',
      ]},model:{value:(_vm.tMax),callback:function ($$v) {_vm.tMax=$$v},expression:"tMax"}}),_c('tooltip',{staticClass:"mt-4"},[_vm._v("Al momento de enviar el chatbot, entre batches, se esperará un tiempo aleatorio entre "),_c('b',[_vm._v(_vm._s(_vm.tMin))]),_vm._v(" y "),_c('b',[_vm._v(_vm._s(_vm.tMax))]),_vm._v(" segundos. ")])],1),(parseInt(_vm.tMin) > parseInt(_vm.tMax))?_c('div',{staticClass:"color--error text-label"},[_vm._v(" El t mínimo no puede ser mayor al t máximo. ")]):_vm._e(),_c('div',{staticClass:"text-left",staticStyle:{"font-style":"italic"}},[_vm._v(" Al momento de enviar el chatbot, se enviarán batches de un número aleatorio entre "),_c('b',[_vm._v(_vm._s(_vm.nMin))]),_vm._v(" y "),_c('b',[_vm._v(_vm._s(_vm.nMax))]),_vm._v(". Después de cada batch, se esperará un tiempo aleatorio de entre "),_c('b',[_vm._v(_vm._s(_vm.tMin))]),_vm._v(" y "),_c('b',[_vm._v(_vm._s(_vm.tMax))]),_vm._v(" segundos. ")]),_c('v-file-input',{attrs:{"label":"Archivo .csv","accept":".csv"},on:{"change":_vm.uploadUsers},model:{value:(_vm.usersFile),callback:function ($$v) {_vm.usersFile=$$v},expression:"usersFile"}}),_c('v-btn',{attrs:{"disabled":_vm.reminderName == '' || !_vm.validNumbers},on:{"click":_vm.sendReminder}},[_vm._v("Crear recordatorio")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }