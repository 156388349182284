<template>
  <v-dialog v-bind="$attrs" v-model="computedValue">
    <v-overlay
      @click="persistent ? $emit('persist') : $emit('input', false)"
    ></v-overlay>
    <v-card :style="cardStyle" class="dialog pa-5">
      <div class="w-100 d-flex flex-right">
        <div :is="titleClass" v-if="title" class="text-start">
          {{ title }}
        </div>
        <div class="ms-auto">
          <slot name="buttons"> </slot>
        </div>

        <v-tooltip top v-if="hasSave && saveCondition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              :disabled="saveDisabled"
              icon
              v-on="on"
              @click="$emit('save')"
            >
              <v-icon color="green" :class="persistent ? 'pulsating-icon' : ''"
                >mdi-content-save</v-icon
              >
            </v-btn>
          </template>
          <span>Guardar</span>
        </v-tooltip>

        <v-tooltip top v-if="hasDelete && deleteCondition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              :disabled="deleteDisabled"
              icon
              v-on="on"
              @click="$emit('delete')"
            >
              <v-icon color="red">mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Eliminar</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              icon
              v-on="on"
              @click="
                hasClose
                  ? $emit('close')
                  : persistent
                  ? $emit('persist')
                  : $emit('input', false)
              "
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>Cerrar</span>
        </v-tooltip>
      </div>
      <slot></slot>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'GenericDialog',
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    cardStyle: {
      required: false,
      type: String,
      default: '',
    },
    deleteDisabled: {
      required: false,
      default: false,
      type: Boolean,
    },
    saveDisabled: {
      required: false,
      default: false,
      type: Boolean,
    },
    saveCondition: {
      required: false,
      type: Boolean,
      default: true,
    },
    deleteCondition: {
      required: false,
      type: Boolean,
      default: true,
    },

    titleClass: {
      required: false,
      default: 'h3',
      type: String,
    },
    title: {
      required: false,
      default: '',
      type: String,
    },
    persistent: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  computed: {
    hasClose() {
      if (this.$listeners) {
        if (this.$listeners.close) {
          return true;
        }
      }
      return false;
    },
    hasSave() {
      if (this.$listeners) {
        if (this.$listeners.save) {
          return true;
        }
      }
      return false;
    },
    hasDelete() {
      return this.$listeners && this.$listeners.delete;
    },
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
};
</script>
<style>
@keyframes pulsate {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.pulsating-icon {
  animation: pulsate 1s ease-in-out infinite;
}
</style>
