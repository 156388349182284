<template>
  <div>
    <generic-dialog :width="width" v-model="showTooltip" v-if="showTooltip">
      <div class="mb-2 d-flex">
        <v-icon
          color="primary"
          style="padding-top: 2px"
          class="ms-auto me-auto mb-2"
          x-large
          >mdi-help-circle-outline</v-icon
        >
      </div>
      <div>
        <slot> </slot>
      </div>
    </generic-dialog>
    <div class="ms-2 mt-auto mb-auto">
      <v-icon color="primary" @click="showTooltip = true"
        >mdi-help-circle-outline</v-icon
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "Tooltip",
  props: {
    width: {
      default: 300,
      required: false,
      type: Number,
    },
  },
  data() {
    return {
      showTooltip: false,
    };
  },
};
</script>
