<template>
  <div>
    <!-- delete dialog -->
    <generic-dialog v-model="deleteDialog" width="300">
      <div>
        ¿Estás seguro de eliminar esta equivalencia de variables del nodo?
      </div>

      <div class="d-flex mt-4">
        <v-btn @click="sendDeleteNodeVariable" class="me-auto"
          >Sí, eliminar</v-btn
        >
        <v-btn @click="deleteDialog = false" class="ms-auto">No</v-btn>
      </div>
    </generic-dialog>
    <!-- edit node variable -->
    <generic-dialog
      v-model="computedValue"
      @save="saveNodeVariable({ nodeVariable: internalNodeVariable })"
      @delete="deleteDialog = true"
      title="Módulo de edición"
      :delete-condition="role == 'admin'"
      width="500"
      height="200"
      v-if="internalNodeVariable"
    >
      <div class="pt-10">
        <v-text-field
          dense
          :disabled="role != 'admin'"
          outlined
          hide-details
          label="Variable"
          v-model="internalNodeVariable.variable"
        ></v-text-field>
        <v-text-field
          type="number"
          label="Equivalencia"
          :disabled="role != 'admin'"
          dense
          outlined
          class="pt-2"
          hide-details
          hide-spin-buttons
          v-model="internalNodeVariable.number"
        >
        </v-text-field>
      </div>
    </generic-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "NodeVariable",
  props: {
    nodeVariable: {
      required: true,
    },
    value: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      deleteDialog: false,
      internalNodeVariable: null,
    };
  },
  mounted() {
    this.internalNodeVariable = { ...this.nodeVariable };
  },
  methods: {
    ...mapActions({
      deleteNodeVariable: "chatbot/deleteNodeVariable",
      saveNodeVariable: "chatbot/saveNodeVariable",
    }),
    sendDeleteNodeVariable() {
      this.deleteNodeVariable({ nodeVariable: this.internalNodeVariable }).then(
        () => {
          this.deleteDialog = false;
          this.internalNodeVariable = null;
          this.$emit("selectNodeVariable", null);
          this.$emit("input", false);
        }
      );
    },
  },
  computed: {
    ...mapGetters({
      role: "chatbot/role",
    }),
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>
