<template>
  <div>
    <!-- delete dialog -->
    <generic-dialog v-model="deleteDialog" width="300" :save-condition="true">
      <v-card class="dialog">
        ¿Estás seguro de eliminar este peso de respuesta?
        <div class="d-flex mt-4">
          <v-btn @click="sendDeleteAnswerWeight" class="me-auto"
            >Sí, eliminar</v-btn
          >
          <v-btn @click="deleteDialog = false" class="ms-auto">No</v-btn>
        </div>
      </v-card>
    </generic-dialog>
    <!-- edit answer weight -->
    <generic-dialog
      v-model="computedValue"
      width="500"
      height="200"
      title="Módulo de edición"
      v-if="internalAnswerWeight"
      @save="saveAnswerWeight({ answerWeight: internalAnswerWeight })"
      @delete="deleteDialog = true"
      :delete-condition="role == 'admin'"
    >
      <div class="pt-10">
        <v-text-field
          dense
          :disabled="role != 'admin'"
          outlined
          hide-details
          label="Característica"
          v-model="internalAnswerWeight.feature"
        ></v-text-field>
        <v-text-field
          type="number"
          label="Valor"
          :disabled="role != 'admin'"
          dense
          outlined
          class="pt-2"
          hide-details
          hide-spin-buttons
          v-model="internalAnswerWeight.weight"
        >
        </v-text-field>
      </div>
    </generic-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import GenericDialog from './GenericDialog.vue';
export default {
  components: { GenericDialog },
  name: 'AnswerWeight',
  props: {
    answerWeight: {
      required: true,
    },
    value: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      deleteDialog: false,
      internalAnswerWeight: null,
    };
  },
  mounted() {
    this.internalAnswerWeight = { ...this.answerWeight };
  },
  methods: {
    ...mapActions({
      deleteAnswerWeight: 'chatbot/deleteAnswerWeight',
      saveAnswerWeight: 'chatbot/saveAnswerWeight',
    }),
    sendDeleteAnswerWeight() {
      this.deleteAnswerWeight({ answerWeight: this.internalAnswerWeight }).then(
        () => {
          this.deleteDialog = false;
          this.internalAnswerWeight = null;
          this.$emit('selectAnswerWeight', null);
          this.$emit('input', false);
        }
      );
    },
  },
  computed: {
    ...mapGetters({
      role: 'chatbot/role',
    }),
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
};
</script>
