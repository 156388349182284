<template>
  <div class="vw-100 h-100 d-flex" style="height: 100vh; background: lightgray">
    <v-card
      class="ma-auto pa-6 text-left"
      style="max-width: 600px; max-height: 300px"
    >
      <h1>
        <div>
          <v-icon x-large class="me-2 mb-2">mdi-robot</v-icon>Editor de Chatbots
        </div>
      </h1>
      <h2>Iniciar sesión</h2>
      <v-form @submit.prevent="checkCredentials">
        <v-text-field v-model="username" placeholder="Usuario"></v-text-field>
        <v-text-field
          v-model="password"
          placeholder="Contraseña"
          type="password"
        ></v-text-field>
        <v-btn type="submit" :loading="loading">Iniciar sesión</v-btn>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      username: "",
      password: "",
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      request: "utils/request",
      login: "utils/login",
    }),
    checkCredentials() {
      this.loading = true;
      this.login({
        username: this.username,
        password: this.password,
        captcha: "Este es el código secreto",
        stay: false,
      }).catch(() => {
        this.loading = false;
      });
    },
  },
};
</script>
