<template>
  <generic-dialog
    @save="saveResource"
    @delete="deleteDialog = true"
    :delete-condition="resource.id != undefined && resource.id != null"
    :save-condition="validResource"
    @close="$emit('setEmptyResource')"
    width="500"
    title="Recurso"
    v-model="computedValue"
  >
    <generic-dialog v-model="deleteDialog" width="300">
      ¿Estás seguro de eliminar este recurso?
      <div class="d-flex mt-4">
        <v-btn @click="confirmDeleteResource" class="me-auto"
          >Sí, eliminar</v-btn
        >
        <v-btn @click="deleteDialog = false" class="ms-auto">No</v-btn>
      </div>
    </generic-dialog>
    <v-text-field label="Nombre" v-model="resource.name"></v-text-field>
    <v-textarea label="Descripción" v-model="resource.description"></v-textarea>
    <v-select
      label="Tipo de recurso"
      :items="['PDF', 'Sitio Web', 'Texto plano', 'Excel / CSV']"
      v-model="resource.type"
    ></v-select>
    <v-textarea
      label="Contenido"
      v-if="resource.type == 'Texto plano'"
      v-model="resource.content"
    ></v-textarea>
    <v-text-field
      v-else-if="resource.type == 'Sitio Web'"
      :rules="[
        (v) => !!v || 'URL es requerido',
        (v) =>
          /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/.test(
            v
          ) || 'Favor escribe un URL válido.',
      ]"
      v-model="resource.content"
      label="URL"
    ></v-text-field>
    <v-file-input
      @change="uploadFile"
      :accept="
        resource.type == 'PDF'
          ? '.pdf'
          : '.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .tsv'
      "
      v-if="
        resource.file == '' && ['PDF', 'Excel / CSV'].includes(resource.type)
      "
      label="Archivo"
    ></v-file-input>
    <v-btn v-else @click="openLink(resource.file)">
      <v-icon>mdi-{{ getIcon() }}</v-icon
      >Ver Archivo
    </v-btn>
  </generic-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'ChatbotResource',
  data() {
    return {
      deleteDialog: false,
      name: 'Vacantes.PDF',
      validResource: false,
      type: 'pdf',
    };
  },
  watch: {
    resource: {
      handler: function (newValue) {
        this.resource = newValue;
        this.validResource = this.validateResource();
      },
      deep: true,
    },
  },
  methods: {
    openLink(link) {
      window.open(link, '_blank');
    },
    ...mapActions({
      request: 'utils/request',
      addResource: 'chatbot/addResource',
      deleteResource: 'chatbot/deleteResource',
      saveResource: 'chatbot/saveResource',
    }),
    getIcon() {
      return {
        PDF: 'file-pdf-box',
        web: 'web',
        'Excel / CSV': 'file-excel-box',
      }[this.resource.type];
    },
    confirmDeleteResource() {
      this.deleteResource({
        resourceId: this.resource.id,
      }).then(() => {
        this.deleteDialog = false;
        this.$emit('reloadResource');
        this.$emit('input', false);
      });
    },
    validateResource() {
      if (this.resource.name == '') {
        return false;
      } else if (this.resource.content == '') {
        return false;
      }

      return true;
    },
    saveResource() {
      this.resource.chatbot = this.chatbot.id;
      if (this.resource.id) {
        this.saveResource({ resource: this.resource });
      } else {
        this.addResource({ resource: this.resource }).then(() => {
          this.$emit('reloadResource');
        });
      }
    },
    uploadFile(event) {
      var file = event;
      var reader = new FileReader();
      reader.onloadend = () => {
        let result = reader.result;
        this.resource.content = result;
      };
      if (['text/csv'].includes(event.type)) {
        reader.readAsText(file);
      } else {
        reader.readAsDataURL(file);
      }
    },
  },
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    resource: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      chatbot: 'chatbot/chatbot',
    }),
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
};
</script>
