<template>
  <!-- variables -->
  <generic-dialog
    v-model="computedValue"
    title="Asignación de variables"
    title-class="h2"
    max-width="500"
  >
    <div class="text-body text-left ps-2 mt-2 mb-2">
      Agrega las variables que desees asignarte para testear el flujo. Para
      asignar <b>características</b>, agregar 'F_' antes.
    </div>
    <div style="max-height: 380px; overflow-y: auto">
      <div
        v-for="(variable, index) in variables"
        :key="index"
        class="d-flex ml-2 pl-2 mb-2 pr-2 rounded"
      >
        <v-text-field
          outlined
          hide-details
          dense
          class="ps-2 pe-2"
          label="variable"
          v-model="variable.variable"
        ></v-text-field>
        <v-text-field
          outlined
          hide-details
          dense
          class="ps-2 pe-2"
          label="value"
          v-model="variable.value"
        ></v-text-field>
        <v-icon
          @click="
            variables = variables.filter((v) => v.variable != variable.variable)
          "
          >mdi-delete</v-icon
        >
      </div>
    </div>

    <v-btn @click="variables.push({ variable: '', value: '' })">
      Agregar variable<v-icon>mdi-plus</v-icon></v-btn
    >
    <br />
    <v-btn dark x-large class="mt-2" @click="setVariables"
      >Asignar variables</v-btn
    >
  </generic-dialog>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Variables",
  props: {
    value: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      variables: [],
    };
  },
  mounted() {
    this.request({
      endpoint: `user_variable?chatbot=${this.chatbot.id}`,
      type: "get",
    }).then((response) => {
      this.variables = response.data;
    });
  },
  methods: {
    ...mapActions({
      request: "utils/request",
    }),
    setVariables() {
      let body = this.variables.reduce((acc, { variable, value }) => {
        acc[variable] = value;
        return acc;
      }, {});
      this.request({
        endpoint: `set_chatbot_variables/${this.chatbot.name}/`,
        type: "post",
        body: body,
        successMessage: "Variables set correctly.",
        errorMessage: "Variables could not be set.",
      }).then(() => {
        this.editVariables = false;
      });
    },
  },
  computed: {
    ...mapGetters({
      chatbot: "chatbot/chatbot",
    }),
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>
