<template>
  <div
    class="w-100 h-100"
    style="
      min-height: 100vh !important;
      max-height: 100vh !important;
      max-width: 100vw !important;
      overflow: hidden !important;
    "
  >
    <!-- if needs updating -->
    <!--<div
      style="
        position: absolute;
        z-index: 9999;
        bottom: 10px;
        right: 10px;
        max-width: 400px;
      "
      class="text-left"
      v-if="!updated"
      v-show="!updated"
    >
      <v-card class="ps-4 pe-4 pt-2 pb-2 bg--error-40-t">
        <div class="w-100 d-flex flex-end">
          <v-icon class="ms-auto" @click="updated = true">mdi-close</v-icon>
        </div>
        La versión de chatbot mostrada no es la más actualizada. Favor
        <span
          @click="updateChatbot()"
          style="cursor: pointer !important; pointer-events: auto"
          class="bold clickable color--primary-40-s"
        >
          actualizar</span
        >.</v-card
      >
    </div>-->
    <chatbot-editor v-model="editChatbot" v-if="editChatbot" />
    <chatbot-nomenclature v-model="showNomenclature" />
    <chatbot-interaction-downloader
      @downloadChatbotInteractions="downloadChatbotInteractions"
      :intervention="currentIntervention"
      v-model="showInteractions"
    />
    <chatbot-reminder-downloader
      @downloadChatbotReminders="downloadChatbotReminders"
      v-model="showReminderDownloader"
    />
    <div
      v-if="view == 'chatbot-usage'"
      style="position: absolute; top: 122px; left: 280px; z-index: 1000"
    >
      <v-card class="pa-2">
        Total nodos accedidos: {{ totalInteractions }}
      </v-card>
    </div>
    <chatbot-intervention-selector
      @selectIntervention="selectIntervention"
      v-if="view == 'chatbot-usage'"
    />
    <!-- comments -->
    <comments
      :selectedNode="node ? node.id : null"
      @clickComment="centerNodeComment"
    />
    <!-- chatbot sender -->
    <chatbot-sender v-model="sendChatbot" />
    <!-- reminder sender -->
    <reminder-sender v-model="sendReminder" />
    <!-- chatbot intervention -->
    <chatbot-intervention v-model="showInterventions" />
    <!-- chatbot reminder -->
    <chatbot-reminder v-model="showReminders" />
    <!-- chatbot creator -->
    <chatbot-creator v-model="createChatbot" />
    <!-- variables -->
    <variables v-model="editVariables" />
    <!-- features -->
    <features v-model="showFeatures" />
    <!-- chatbot interaction -->
    <user-role v-model="showUserRole" v-if="showUserRole" />
    <!-- chatbot resource -->
    <chatbot-resource-group v-model="showResources" v-if="showResources" />
    <!-- chatbot test -->
    <chatbot
      @closeChatbot="stopTestingChatbot"
      @colorNode="colorNode"
      v-if="testChatbot && node"
      style="
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 3005;
        max-height: 70vh;
      "
      :currentNode="node"
      :nodeCharacteristics="{}"
      :chatbotName="chatbot.name"
    />
    <!-- interaction selector -->
    <!-- button panel -->
    <button-panel
      :buttons="shownButtons"
      @resetFeatures="resetFeatures"
      @checkResources="checkResources"
      @addEdge="addEdge"
      @addNodeAndCenter="currentInstruction = 4"
      @testBot="testBot"
      @setShowFeatures="setShowFeatures"
      @setShowInterventions="setShowInterventions"
      @setShowReminders="setShowReminders"
      @getVariables="getVariables"
      @allowEditChatbot="editChatbot = true"
      @createChatbotDialog="createChatbotDialog"
      @setSendChatbotDialog="setSendChatbotDialog"
      @setSendReminderDialog="setSendReminderDialog"
      @closeChatbot="stopTestingChatbot"
      @openChatbotInteractions="openChatbotInteractions"
      @openChatbotReminders="openChatbotReminders"
      @setShowUserRole="setShowUserRole"
      @setDrag="startDrag"
      @showNomenclature="showNomenclature = true"
    >
      <v-btn
        small
        v-if="organizations.length > 1"
        @click="$router.push({ name: 'organization' })"
      >
        Cambiar organización
      </v-btn>
      <v-autocomplete
        solo
        hide-details
        :items="computedNodes"
        item-text="allText"
        item-value="id"
        return-object
        placeholder="Busca un nodo"
        @change="centerNode"
      >
        <template v-slot:selection="{ item }">
          {{ item.name }}
        </template>
        <template v-slot:item="{ item }">
          {{ item.name }}
        </template>
      </v-autocomplete>

      <v-btn small v-if="role == 'admin'" dark @click="swapView()">
        {{ view == 'chatbot-edit' ? 'Ir a uso y envío' : 'Ir a edición' }}
      </v-btn>
    </button-panel>
    <!-- instruction -->
    <div
      v-if="instruction"
      class="instruction-panel absolute pa-2 ms-auto me-auto rounded"
    >
      {{ instruction }}
      <br />
      <v-btn class="ms-auto me-auto" v-if="currentInstruction == 3">
        Duplicar</v-btn
      >
    </div>
    <!-- node -->
    <node
      :node="node"
      v-if="node && !testChatbot"
      @setNode="setNode"
      @testBotAtNode="testBotAtNode"
    />
    <!-- edges -->
    <edge
      v-model="editEdge"
      v-if="selectedEdge"
      :selected-edge="selectedEdge"
      @computeEdges="computeEdges"
    />
    <!-- TEST - shapes -->
    <custom-shape
      v-for="node in []"
      :key="node.id"
      :style="node.style"
    ></custom-shape>
    <!-- network -->
    <network
      ref="network"
      v-if="showNetwork && !loading"
      :nodes="computedNodes"
      :events="[
        'click',
        'hoverNode',
        'blurNode',
        'dragStart',
        'dragging',
        'dragEnd',
        'mouseLeave',
      ]"
      :edges="computedEdges"
      :options="options"
      @click="clickNetwork"
      @hover-node="hoverNode"
      @blur-node="blurNode"
      @drag-end="onNodeDragEnd"
      style="
        width: 100vw;
        height: 100vh;
        background: radial-gradient(#939393, #c8c8c8);
        z-index: 100;
      "
    >
    </network>
    <div
      v-if="loading"
      style="
        width: 100vw;
        height: 100vh;
        background: radial-gradient(#939393, #c8c8c8);
        z-index: 100;
        display: flex;
      "
    >
      <v-progress-circular
        indeterminate
        color="primary"
        :size="50"
        :width="15"
        class="ms-auto me-auto mt-auto mb-auto"
      ></v-progress-circular>
    </div>

    <!-- hover node info -->
    <transition name="popup-fade-in">
      <div
        v-show="nodeHovered"
        id="popupContainer"
        ref="popupContainer"
        style="
          pointer-events: none;
          z-index: 1002;
          position: absolute;
          top: 50vh;
          left: 50vw;
          width: 340px;
          height: auto;
          overflow-y: hidden;
        "
      >
        <v-card class="w-100 text-center pa-2 relative" v-if="nodeHovered">
          <div
            class="text-left text-label"
            style="color: gray; max-height: 8px"
          >
            PREVIEW
          </div>
          <div class="text-body">{{ nodeHovered.name }}</div>
          <div class="text-label">
            {{ trimText(nodeHovered.text, 500) }}
          </div>
          <v-card
            class="text-label w-100 ms-4 me-4 mb-1"
            v-for="(answer, index) in nodeHovered.answers"
            :key="index"
          >
            {{ answer.text }}
          </v-card>
          <v-container class="text-label mt-2" v-if="nodeHovered">
            <v-row>
              <v-col
                cols="6"
                style="white-space: nowrap !important; overflow-x: hidden"
              >
                <v-row class="bold">Nodos entrantes:</v-row>
                <v-row
                  v-for="(inNode, index) in computedEdges.filter(
                    (e) => e.to == nodeHovered.id,
                  )"
                  :key="index"
                >
                  <div
                    style="
                      text-align: left;
                      max-width: 90% !important;
                      width: 90% !important;
                      overflow-x: hidden;
                    "
                  >
                    • {{ getNodeName(inNode.from) }}
                  </div>
                </v-row>
              </v-col>
              <v-col
                cols="6"
                style="white-space: nowrap !important; overflow-x: hidden"
              >
                <v-row class="bold">Nodos salientes:</v-row>
                <v-row
                  v-for="(outNode, index) in computedEdges.filter(
                    (e) => e.from == nodeHovered.id,
                  )"
                  :key="index"
                >
                  <div
                    style="
                      text-align: left;
                      max-width: 90% !important;
                      width: 90% !important;
                      overflow-x: hidden;
                    "
                  >
                    • {{ getNodeName(outNode.to) }}
                  </div>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </div>
    </transition>
    <!-- main view -->
    <!-- <div :is="view"></div> -->
    <!-- end main view -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import Chatbot from '@/components/Chatbot.vue';
import ButtonPanel from '@/components/ButtonPanel.vue';
import Node from '@/components/Node.vue';
import Edge from '@/components/Edge.vue';
import Features from '@/components/Features.vue';
import Variables from '@/components/Variables.vue';
import ChatbotCreator from '@/components/ChatbotCreator.vue';
import ChatbotSender from '@/components/ChatbotSender.vue';
import ReminderSender from '@/components/ReminderSender.vue';
import ChatbotIntervention from '@/components/ChatbotIntervention.vue';
import ChatbotReminder from '@/components/ChatbotReminder.vue';
import ChatbotNomenclature from '@/components/ChatbotNomenclature.vue';
import ChatbotEditor from '@/components/ChatbotEditor.vue';
import ChatbotResourceGroup from '@/components/ChatbotResourceGroup.vue';
import ChatbotInterventionSelector from '@/components/ChatbotInterventionSelector.vue';
import ChatbotInteractionDownloader from '@/components/ChatbotInteractionDownloader.vue';
import ChatbotReminderDownloader from '@/components/ChatbotReminderDownloader.vue';
import UserRole from '@/components/UserRole.vue';
import Comments from '@/components/Comments.vue';
export default {
  name: 'Main',
  components: {
    Chatbot,
    ButtonPanel,
    Node,
    Edge,
    Features,
    Variables,
    ChatbotIntervention,
    ChatbotReminder,
    ChatbotCreator,
    ChatbotSender,
    ReminderSender,
    Comments,
    ChatbotInterventionSelector,
    ChatbotNomenclature,
    ChatbotInteractionDownloader,
    ChatbotReminderDownloader,
    UserRole,
    ChatbotResourceGroup,
    ChatbotEditor,
  },
  mounted() {
    this.initialize();
  },
  data() {
    return {
      showResources: false,
      editChatbot: false,
      loadingIntervention: false,
      showNomenclature: false,
      updated: true,
      timer: null,
      totalInteractions: 0,
      showEdgeInteractions: false,
      loading: false,
      view: 'chatbot-edit',
      showNetwork: false,
      nodeHovered: null,
      hoveredEdgesFrom: [],
      hoveredEdgesTo: [],
      // node edition
      node: null,
      // edges
      computedEdges: [],
      // edge creating
      currentInstruction: 0,
      startNode: null,
      endNode: null,
      // edge editing
      editEdge: false,
      selectedEdge: null,
      // chatbot testing
      testChatbot: false,
      previousColor: null,
      // feature edition
      showFeatures: false,
      // variable edition
      editVariables: false,
      // chatbot creation
      createChatbot: false,
      // chatbot sending
      sendChatbot: false,
      // reminder sending
      sendReminder: false,
      // node / edge selecting
      selecting: false,
      selectedNodes: [],
      selectedEdges: [],
      // node coloring refresh
      refresh: 0,
      // chatbot edition
      // startContentSid: "",
      // node colors
      coloredNode: null,
      coloredNodes: [],
      coloredEdges: [],
      // interventions
      showInterventions: false,
      showReminders: false,
      currentIntervention: null,
      // interaction download
      showInteractions: false,
      showReminderDownloader: false,
      // user role
      showUserRole: false,
    };
  },
  watch: {
    hoveredEdgesFrom(val) {
      if (val == []) {
        this.computeEdges();
      }
    },
    hoveredEdgesTo(val) {
      if (val == []) {
        this.computeEdges();
      }
    },
    chatbot() {
      this.loading = true;
      this.currentIntervention = null;

      if (this.view == 'chatbot-usage') {
        this.loading = true;
        this.getInteractions({ intervention: this.currentIntervention }).then(
          () => {
            this.sumInteractions();
            this.showEdgeInteractions = true;
            this.computeEdges();
            this.loading = false;
          },
        );
      } else {
        this.removeInteractions().then(() => {
          this.showEdgeInteractions = false;
          this.loading = false;
        });
      }
      this.computeEdges();
      this.recolor();
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    checkResources() {
      this.showResources = true;
    },
    downloadChatbotReminders(variables, features, reminder) {
      let text = `Se le enviará un correo con las interacciones del recordatorio ${reminder.name} del chatbot.`;

      let endpoint = `download_chatbot/${this.chatbot.name}/?reminder=${reminder.id}`;
      this.request({
        type: 'get',
        params: {
          params: {
            variables,
            features,
          },
        },
        endpoint,
      })
        .then(() => {})
        .finally(() => {
          this.setToastNotification({
            toastState: true,
            toastMessage: text,
          });
          this.showInteractions = false;
        });
    },
    initialize() {
      if (this.chatbots) {
        this.chatbots.forEach((chatbot) => {
          if (!('label' in chatbot)) {
            this.logout();
          }
        });
      }

      // every 60 seconds, check if chatbot has been updated
      this.checkChatbot();
      this.timer = setInterval(() => {
        this.checkChatbot();
      }, 30000);

      this.computeEdges();
      this.showNetwork = true;

      this.recolor();
      this.loading = true;
      this.removeInteractions().then(() => {
        this.showEdgeInteractions = false;
        this.view = 'chatbot-edit';
        this.$nextTick(() => {
          this.loading = false;
        });
      });
      this.options.interaction.dragView = true;
      this.options.interaction.dragNodes = true;
    },
    resetFeatures() {
      this.request({
        type: 'get',
        errorMessage: 'error resetting features',
        successMessage: 'Features reset successfully',
        endpoint: `delete_progress/${this.chatbot.name}/?features=1`,
      });
    },
    updateChatbot() {
      this.loading = true;
      this.showNetwork = false;
      this.getChatbot({ chatbotId: this.chatbot.id }).then(() => {
        this.refresh++;
        this.computeEdges();
        this.$nextTick(() => {
          this.loading = false;
          this.showNetwork = true;
          this.updated = true;
        });
      });
    },
    checkChatbot() {
      /* Check if chatbot has been updated or not. */
      if (this.chatbot) {
        this.request({
          type: 'get',
          endpoint: `chatbot/${this.chatbot.id}?required_fields=updated`,
        }).then((response) => {
          if (this.chatbot.updated != response.data.updated) {
            this.updated = false;
          }
        });
      }
    },
    addNodeAndCenter(x, y) {
      this.addNode({ x, y }).then((data) => {
        this.centerNode(data);
        this.currentInstruction = null;
      });
    },
    onNodeDragEnd(event) {
      if (event.nodes && event.nodes.length > 0) {
        let nodeId = event.nodes[0];
        let newPosition = this.$refs.network.getPositions([nodeId]);
        let finalX = newPosition[nodeId].x;
        let finalY = newPosition[nodeId].y;
        let node = this.chatbot.nodes.find((n) => n.id == nodeId);
        this.saveNode({
          node: {
            id: nodeId,
            pos_x: finalX,
            pos_y: finalY,
            name: node.name,
            silent: true,
          },
          callback: true,
        });
        node.x = finalX;
        node.y = finalY;
      }
    },
    sumInteractions() {
      let total = 0;
      this.chatbot.nodes.forEach((n) => {
        if (n.count) {
          total += n.count;
        }
      });
      this.totalInteractions = total;
    },
    ...mapActions({
      logout: 'utils/logout',
      addNode: 'chatbot/addNode',
      addTransitionWeight: 'chatbot/addTransitionWeight',
      setToastNotification: 'utils/setToastNotification',
      request: 'utils/request',
      asyncFetch: 'utils/asyncFetch',
      changeNodeColor: 'chatbot/changeNodeColor',
      getInteractions: 'chatbot/getInteractions',
      removeInteractions: 'chatbot/removeInteractions',
      recolor: 'chatbot/recolor',
      saveNode: 'chatbot/saveNode',
      getChatbot: 'chatbot/getChatbot',
      setOrganizations: 'chatbot/setOrganizations',
      setOrganization: 'chatbot/setOrganization',
    }),
    selectIntervention(data) {
      this.loadingIntervention = true;
      // this.loading = true;
      if (data && data.includes('Todas') && data.length == 1) {
        data = null;
        this.currentIntervention = null;
      } else {
        this.currentIntervention = data.join();
      }
      this.sumInteractions();

      this.getInteractions({ intervention: this.currentIntervention })
        .then(() => {
          this.loadingIntervention = false;
          this.sumInteractions();
          this.showEdgeInteractions = true;
          this.computeEdges();
          // this.loading = false;
        })
        .catch(() => {
          this.loadingIntervention = false;
          // this.loading = false;
        });
    },

    getColor(node) {
      if (
        (this.coloredNode && this.coloredNode == node.id) ||
        this.coloredNodes.includes(node.id)
      ) {
        return 'white';
      }
      if (node.start) {
        return 'lightgreen';
      }
      if (node.ai) {
        return '#d383d2';
      }
      if (node.preprocess) {
        return '#d383d2';
      }
      if (node.special) {
        return '#F29B9B';
      }
      return 'lightblue';
    },
    addSelectedNode(nodeId) {
      if (this.selectedNodes.includes(nodeId)) {
        this.selectedNodes = this.selectedNodes.filter((n) => n != nodeId);
      } else {
        this.selectedNodes.push(nodeId);
      }
    },
    addSelectedEdge(edgeId) {
      let edge = this.computedEdges.find((edge) => edge.id == edgeId);
      if (this.selectedEdges.includes(edgeId)) {
        this.selectedEdges = this.selectedEdges.filter((e) => e != edgeId);
        edge.color = 'lightgrey';
      } else {
        edge.color = '#2B7CE9';
        this.selectedEdges.push(edgeId);
        if (!this.selectedNodes.includes(edge.to)) {
          this.addSelectedNode(edge.to);
        }
        if (!this.selectedNodes.includes(edge.from)) {
          this.addSelectedNode(edge.from);
        }
      }
    },
    startDrag() {
      if (this.view == 'chatbot-edit') {
        this.selecting = true;
        this.currentInstruction = 3;
      }
    },
    downloadChatbot() {
      let chatbot = this.chatbot;
      this.deleteKeys(chatbot, ['role', 'node', 'answer']);
      var element = document.createElement('a');
      element.setAttribute(
        'href',
        'data:text/json;charset=utf-8,' +
          encodeURIComponent(JSON.stringify(chatbot)),
      );
      element.setAttribute('download', `${chatbot.name}.json`);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    deleteKeys(obj, keys) {
      for (let prop in obj) {
        keys.forEach((key) => {
          if (prop === key) {
            delete obj[prop];
          }
        });
        if (typeof obj[prop] === 'object') {
          this.deleteKeys(obj[prop], keys);
        }
      }
    },
    openChatbotInteractions() {
      this.showInteractions = true;
    },
    openChatbotReminders() {
      this.showReminderDownloader = true;
    },
    downloadChatbotInteractions(variables, features) {
      let text = 'Se le enviará un correo con las interacciones del chatbot.';

      if (this.currentIntervention) {
        text = `Se le enviará un correo con las interacciones del chatbot para la intervención ${this.currentIntervention}.`;
      }
      let endpoint = `download_chatbot/${this.chatbot.name}/`;
      if (this.currentIntervention) {
        endpoint = `download_chatbot/${this.chatbot.name}/?intervention=${this.currentIntervention}`;
      }
      this.request({
        type: 'get',
        params: {
          params: {
            variables,
            features,
          },
        },
        endpoint,
      })
        .then(() => {})
        .finally(() => {
          this.setToastNotification({
            toastState: true,
            toastMessage: text,
          });
          this.showInteractions = false;
        });
    },

    swapView() {
      this.blurNode();
      this.loading = true;
      if (this.view == 'chatbot-edit') {
        this.options.interaction.dragNodes = false;
        // get interactions
        this.getInteractions({ intervention: this.currentIntervention })
          .then(() => {
            this.sumInteractions();
            this.showEdgeInteractions = true;
            this.computeEdges();
            this.view = 'chatbot-usage';
            this.$nextTick(() => {
              this.loading = false;
            });
          })
          .catch((error) => {
            console.log(error);
            this.view = 'chatbot-usage';
            this.$nextTick(() => {
              this.loading = false;
            });
          });
      } else {
        this.options.interaction.dragNodes = true;

        this.currentIntervention = false;
        this.removeInteractions().then(() => {
          this.showEdgeInteractions = false;
          this.view = 'chatbot-edit';
          this.computeEdges();
          this.$nextTick(() => {
            this.loading = false;
          });
        });
      }
    },
    setSendChatbotDialog() {
      this.sendChatbot = true;
    },
    setSendReminderDialog() {
      this.sendReminder = true;
    },
    createChatbotDialog() {
      this.createChatbot = true;
    },
    getVariables() {
      this.editVariables = true;
    },
    setShowFeatures() {
      this.stopTestingChatbot();
      this.showFeatures = true;
    },
    setShowInterventions() {
      this.showInterventions = true;
    },
    setShowReminders() {
      this.showReminders = true;
    },
    setShowUserRole() {
      this.showUserRole = true;
    },
    uncolor() {
      if (this.previousColor && this.coloredNode) {
        this.changeNodeColor({
          node: this.coloredNode,
          color: this.previousColor,
        });
        this.previousColor = null;
        this.coloredNode = null;
      }
      this.coloredNodes = [];
      this.coloredEdges = [];
      this.computedEdges.forEach((e) => {
        e.color = 'lightgray';
      });
    },
    addEdge() {
      this.currentInstruction = 1;
    },
    colorNode(data) {
      if (data) {
        if (this.previousColor) {
          /*let oldNode = this.chatbot.nodes.find(
            (node) => node.id == this.coloredNode
          );
          this.changeNodeColor({
            node: oldNode.id,
            color: this.previousColor,
          });*/
        }
        // new: color edge too
        if (this.coloredNode && data) {
          let foundEdge = this.computedEdges.find(
            (edge) => edge.id == this.getEdgeId(this.coloredNode, data),
          );
          if (foundEdge) {
            foundEdge.color = 'white';
          }
        }

        // end new
        let newNode = this.chatbot.nodes.find((node) => node.id == data);
        this.travelToNode(newNode);
        this.coloredNode = data;
        this.previousColor = newNode.color.background;
        this.changeNodeColor({ node: newNode.id, color: 'white' });
        this.coloredNodes.push(newNode.id);
        this.refresh++;
      }
    },
    stopTestingChatbot() {
      this.node = null;
      this.blurNode();
      this.testChatbot = false;
      this.refresh = 0;
      this.uncolor();
      // end test
    },
    testBot() {
      this.stopTestingChatbot();
      this.$nextTick(() => {
        this.request({
          type: 'get',
          endpoint: `delete_progress/${this.chatbot.name}/`,
        }).then(() => {
          this.testChatbot = true;
          this.node = this.chatbot.nodes.filter((n) => n.start)[0];
        });
      });
    },
    // here
    testBotAtNode(node) {
      this.stopTestingChatbot();
      this.$nextTick(() => {
        this.request({
          type: 'get',
          endpoint: `delete_progress/${this.chatbot.name}/`,
        }).then(() => {
          this.testChatbot = true;
          this.node = node;
        });
      });
    },
    centerNode(node) {
      var container = this.$refs.network;
      let positions = container.getPositions(node['id'])[node.id];
      container.moveTo({
        position: { x: positions.x, y: positions.y },
        scale: 2,
        animation: {
          duration: 500,
          easingFunction: 'linear',
        },
      });
      setTimeout(() => {
        this.node = node;
        this.selectedNodeInformation = this.chatbot.nodes.filter(
          (n) => n.id == node.id,
        )[0];
      }, 650);
    },
    centerNodeComment(node) {
      var container = this.$refs.network;
      let positions = container.getPositions(node)[node];
      container.moveTo({
        position: { x: positions.x, y: positions.y },
        scale: 2,
        animation: {
          duration: 500,
          easingFunction: 'linear',
        },
      });
      setTimeout(() => {
        this.selectedNodeInformation = this.computedNodes.filter(
          (n) => n.id == node,
        )[0];
        this.node = this.selectedNodeInformation;
      }, 650);
    },
    travelToNode(node) {
      var container = this.$refs.network;
      let positions = container.getPositions(node['id'])[node.id];
      container.focus(node.id, {
        position: { x: positions.x, y: positions.y },
        animation: false /*{
          duration: 500,
          easingFunction: "linear",
        },*/,
      });
      /*container.moveTo({
        position: { x: positions.x, y: positions.y },
        animation: {
          duration: 500,
          easingFunction: "linear",
        },
      });*/
    },
    computeEdges() {
      let finalEdges = [];
      let transitions = [];
      if (!this.chatbot) {
        return [];
      }
      this.chatbot.edges.forEach((t) => {
        let id = this.getEdgeId(t.source, t.destination);
        if (!transitions.includes(id)) {
          // add first transition
          transitions.push(id);
          // add edge
          let width = 1;
          if (
            this.showEdgeInteractions &&
            'count' in t &&
            'maxCount' in t &&
            t.maxCount > 10
          ) {
            width = Math.round((15 * t.count) / t.maxCount);
            if (width < 1) {
              width = 1;
            }
          }
          let color = 'lightgray';
          // let label = "";
          if (this.nodeHovered && this.hoveredEdgesFrom.includes(id)) {
            color = '#0466c8';
            // label = "desde";
          } else if (this.nodeHovered && this.hoveredEdgesTo.includes(id)) {
            color = '#ff7b00';
            // label = "hacia";
          }
          /*if (
            this.hoveredEdgesFrom.length == 0 &&
            this.hoveredEdgesTo.length == 0
          ) {
            color = "lightgray";
          }*/

          finalEdges.push({
            description: t.description,
            from: t.source,
            to: t.destination,
            avoidOverlap: true,
            arrows: 'to',
            id,
            color,
            width: width,
            weights: [
              {
                description: t.description,
                expected_weight: t.expected_weight,
                feature: t.feature,
                id: t.id,
              },
            ],
          });
        } else {
          // add new edge
          finalEdges
            .find((edge) => edge.id == id)
            .weights.push({
              expected_weight: t.expected_weight,
              feature: t.feature,
              id: t.id,
              description: t.description,
            });
        }
      });

      // NEW: add smooth edges when necessary
      finalEdges.forEach((e) => {
        let id = this.getEdgeId(e.from, e.to);
        let reverseId = this.getEdgeId(e.to, e.from);
        if (transitions.includes(id) && transitions.includes(reverseId)) {
          e.smooth = {
            enabled: true,
            type: 'curvedCW',
            roundness: 0.3,
          };
        }
      });
      this.computedEdges = finalEdges;
      if (this.selectedEdge) {
        this.selectedEdge = this.computedEdges.find(
          (edge) => edge.id == this.selectedEdge.id,
        );
      }
    },
    hoverNode(node) {
      if (this.testChatbot) {
        return;
      }
      setTimeout(() => {
        if (this.currentInstruction == 0 || this.currentInstruction == null) {
          this.nodeHovered = null;
          let position = node.pointer.DOM;
          let nodeId = node.node;
          let container = this.$refs.network.$el;
          if (this.view == 'chatbot-edit') {
            container.style.cursor = 'pointer';
          }
          const popupContainer = this.$refs.popupContainer;
          popupContainer.style.display = 'block';
          popupContainer.style.top = `${position.y + 10}px`;
          popupContainer.style.left = `${position.x - 150}px`;
          this.nodeHovered = this.chatbot.nodes.filter(
            (node) => node.id == nodeId,
          )[0];
          // new: color edges
          const connectedEdges = this.$refs.network.getConnectedEdges(nodeId);
          connectedEdges.forEach((e) => {
            if (e.startsWith(`${nodeId}`)) {
              this.hoveredEdgesTo.push(e);
            } else if (e.endsWith(`${nodeId}`)) {
              this.hoveredEdgesFrom.push(e);
            }
          });
          this.computeEdges();
        }
      }, 100);
    },
    setShowNetwork(bool) {
      this.showNetwork = bool;
    },
    clickNetwork(event) {
      if (event.nodes.length > 0) {
        if (this.currentInstruction == 4) {
          this.currentInstruction = null;
        }
        if (this.view == 'chatbot-edit') {
          this.selectNode(event);
        }
      } else if (event.edges.length > 0) {
        if (this.currentInstruction == 4) {
          this.currentInstruction = null;
        }
        this.selectEdge(event);
      } else if (this.currentInstruction == 4) {
        let x = Math.round(event.pointer.canvas.x);
        let y = Math.round(event.pointer.canvas.y);
        this.addNodeAndCenter(x, y);
      } else {
        this.node = null;
        this.edge = null;
        this.selectedNodes = [];
        this.selectedEdges = [];
        this.computedEdges.forEach((e) => {
          e.color = 'lightgrey';
        });
        this.currentInstruction = null;
        if (this.testChatbot) {
          this.testChatbot = null;
          this.uncolor();
        }
      }
    },
    selectNode(event) {
      if (this.testChatbot) {
        return;
      }
      this.blurNode();

      if (this.currentInstruction == 1) {
        // starting to create edge
        this.startNode = parseInt(event.nodes[0]);
        this.currentInstruction = 2;
      } else if (this.currentInstruction == 2) {
        // finish creating edge
        this.endNode = parseInt(event.nodes[0]);
        this.addTransitionWeight({
          source: this.startNode,
          destination: this.endNode,
          feature: 'default',
          expected_weight: 0,
        }).then(() => {
          this.startNode = null;
          this.endNode = null;
          this.currentInstruction = 0;
          this.computeEdges();
        });
      } else if (this.currentInstruction == 3) {
        this.addSelectedNode(event.nodes[0]);
      } else {
        // select node
        this.node = this.chatbot.nodes.filter((n) => n.id == event.nodes[0])[0];
      }
    },

    selectEdge(event) {
      if (this.currentInstruction == 3) {
        this.addSelectedEdge(event.edges[0]);
      } else {
        this.selectedEdge = this.computedEdges.find(
          (edge) => edge.id == event.edges[0],
        );

        this.editEdge = true;
      }
    },
    blurNode() {
      setTimeout(() => {
        let container = this.$refs.network.$el;
        container.style.cursor = 'default';
        this.nodeHovered = null;
        this.hoveredEdgesFrom = [];
        this.hoveredEdgesTo = [];
        this.computeEdges();
      }, 100);
      this.hoveredEdgesFrom = [];
      this.hoveredEdgesTo = [];
    },
    setNode(node) {
      this.node = node;
    },
    getNodeName(nodeId) {
      if (nodeId) {
        return this.chatbot.nodes.find((n) => n.id == nodeId).name;
      }
      return '';
    },
    getEdgeId(startNode, endNode) {
      return `${startNode}-${endNode}`;
    },
    trimText(text, N) {
      if (text.length > N) {
        return text.substring(0, N) + '...';
      }
      return text;
    },
  },
  computed: {
    ...mapGetters({
      organization: 'chatbot/organization',
      organizations: 'chatbot/organizations',
      chatbots: 'chatbot/chatbots',
      chatbot: 'chatbot/chatbot',
      options: 'chatbot/options',
      role: 'chatbot/role',
      isSuperAdmin: 'utils/isSuperAdmin',
    }),

    shownButtons() {
      if (this.view == 'chatbot-edit') {
        return [
          'add-node',
          'add-edge',
          'test-bot',
          'edit-features',
          'get-variables',
          'reset-features',
          'create-chatbot',
          'user-role',
          'set-drag',
          'edit-chatbot',
          'add-resource',
          'logout',
        ];
      } else {
        return [
          'test-bot',
          'download-interactions',
          'download-reminders',
          'get-interventions',
          'get-reminders',
          'send-chatbot',
          'send-reminder',
          'logout',
        ];
      }
    },
    //
    instruction() {
      if (this.currentInstruction == 1) {
        return 'Selecciona el nodo de origen de la arista.';
      } else if (this.currentInstruction == 2) {
        return 'Selecciona el nodo de destino de la arista.';
      } else if (this.currentInstruction == 3) {
        return "Selecciona los nodos y aristas que deseas duplicar. Una vez terminada la selección, haz click en 'duplicar'.";
      } else if (this.currentInstruction == 4) {
        return 'Haz click en el diagrama para colocar un nodo.';
      }
      return '';
    },
    computedNodes() {
      this.refresh;
      // needed to update node coloring.
      // why? only god knows why.
      if (!this.chatbot) {
        return [];
      }
      return this.chatbot.nodes.map((n) => {
        let text = `${n.text} ${n.name}`;
        n.answers.forEach((a) => {
          text += a.text;
        });

        // n.image = require("@/assets/test.png");
        // n.shape = "image";
        n.allText = text;
        if (n.start) {
          if (
            (this.coloredNode && n.id == this.coloredNode) ||
            this.coloredNodes.includes(n.id)
          ) {
            n.color.background = 'white';
          } else {
            n.color.background = 'lightgreen';
          }
        }
        if (this.selectedNodes.includes(n.id)) {
          n.selected = true;
          n.color.border = '#2B7CE9';
          n.color.borderWidth = '10px';
        } else {
          if (!('color' in n)) {
            n.color = {};
          }
          // n.color.border = "linear-gradient(to right, red, purple);";
          n.color.background = this.getColor(n);
          n.color.border = n.color.background;
          n.selected = false;
        }
        // n.label = n.name;

        // new: position
        if (n.pos_x) {
          n.x = n.pos_x;
        }
        if (n.pos_y) {
          n.y = n.pos_y;
        }
        // TEST: position?
        n.style = `position: absolute; left: ${n.x}px; top: ${n.y}px;`;
        return n;
      });
    },
  },
};
</script>
<style>
.dialog {
  padding: 1rem 2rem;
  z-index: 1003 !important;
}

.badge {
  border: 2px dashed blue;
}

.top-right {
  position: absolute;
  right: 5px;
  top: 5px;
}
.popup-fade-in-enter-active {
  transition: opacity 0.3s;
}
.popup-fade-in-leave-active {
  transition: opacity 0.1s;
}

.popup-fade-in-enter,
.popup-fade-in-leave-to {
  opacity: 0;
}
.instruction-panel {
  background: white;
  top: 10px;
  left: 50%;
  right: 50%;
  transform: translate(-50%, 0);
  max-width: 50vw;
  min-width: 50vw;
  position: absolute;
  z-index: 1000;
}
</style>
