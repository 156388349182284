<template>
  <div>
    <input
      ref="input"
      type="file"
      name="image"
      accept="image/*"
      @change="setImage"
    />

    <div class="content d-flex flex-column">
      <div
        :style="{ height: imgSrc ? 'auto' : '0px !important' }"
        class="d-flex flex-row"
        style="position: relative"
      >
        <section class="cropper-area">
          <div class="img-cropper">
            <vue-cropper
              ref="cropper"
              :aspect-ratio="1 / 1"
              :src="imgSrc"
              preview=".preview"
            />
          </div>
        </section>
        <section v-if="cropImg" style="top: 5px; left: 5px; position: absolute">
          <v-avatar size="64px" class="elevation-2 ms-auto">
            <img v-if="cropImg" :src="cropImg" alt="avatar" />
            <div v-else>No avatar selected</div>
          </v-avatar>
          <div class="text-caption ms-2" v-if="imgSrc">Preview</div>
        </section>
      </div>

      <div class="actions" :style="{ marginTop: imgSrc ? '0px' : '74px' }">
        <v-btn class="me-2" @click="cropImage" v-if="imgSrc">Guardar</v-btn>
        <v-btn class="me-2" @click="reset" v-if="imgSrc">Resetear</v-btn>
        <v-btn class="me-2" @click="showFileChooser">Subir imagen</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  components: {
    VueCropper,
  },
  props: {
    imageSource: {
      required: false,
      default: "",
    },
  },
  data() {
    return {
      cropImg: "",
      data: null,
      imgSrc: "",
    };
  },
  mounted() {
    if (this.imageSource) {
      this.cropImg = this.imageSource;
    }
  },
  methods: {
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.$emit("setImage", this.cropImg);
    },

    reset() {
      this.$refs.cropper.reset();
    },

    setImage(e) {
      this.cropImg = "";
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
input[type="file"] {
  display: none;
}

.cropper-area {
  width: 100%;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 5px 0;
}

.header h2 {
  margin: 0;
}

.header a {
  text-decoration: none;
  color: black;
}

.content {
  display: flex;
  justify-content: space-between;
}

.actions {
  margin-top: 1rem;
}

.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062cc;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

textarea {
  width: 100%;
  height: 100px;
}

.preview-area {
  width: 307px;
}

.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}

.preview-area p:last-of-type {
  margin-top: 1rem;
}

.preview {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}

.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}

.cropped-image img {
  max-width: 100%;
  border: 1px solid black;
  border-radius: 50%;
}

/*cropper circle*/

.cropper-crop-box,
.cropper-view-box {
  border-radius: 50%;
}

.cropper-view-box {
  box-shadow: 0 0 0 1px #39f;
  outline: 0;
}

.cropper-face {
  background-color: inherit !important;
}

.cropper-dashed,
.cropper-point.point-se,
.cropper-point.point-sw,
.cropper-point.point-nw,
.cropper-point.point-ne,
.cropper-line {
  display: none !important;
}

.cropper-view-box {
  outline: inherit !important;
}
</style>
