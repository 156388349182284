<template>
  <generic-dialog v-model="computedValue" width="500">
    <generic-dialog v-model="showInstructions" width="300">
      <div>
        Intervención fue creada. Para ver estado de carga de usuarios de
        intervención, ir al panel <b>ver intervenciones</b>. Una vez cargado los
        usuarios, en el mismo panel hacer click en "Comenzar envío".
      </div>
      <v-btn class="mt-2" @click="$emit('input', false)">OK</v-btn>
    </generic-dialog>
    <div>
      Para hacer envío de chatbot, debe subir un .csv con la información de los
      usuarios a enviar. La única columna obligatoria es "number", con formato
      '569XXXXXXXX'. Otras columnas se utilizarán como variables ó
      características de flujo de chatbot. Para enviar variables, simplemente
      colocar una columna con el nombre de la variable (por ejemplo, 'name').
      Para setear características, agregar 'F_' antes del nombre de esa columna.
      Por ejemplo, si se quiere setear la característica 'validado', Enviar
      columna 'F_validado'.
    </div>
    <v-text-field
      v-model="interventionName"
      label="Nombre de la intervención"
      hide-details
    ></v-text-field>
    <div class="d-flex">
      <v-text-field
        min="200"
        max="1000"
        type="number"
        label="n_min"
        hide-spin-buttons
        v-model="nMin"
        :rules="[
          (v) => v >= 200 || 'Valor mínimo de 200',
          (v) => v <= 1000 || 'Valor máximo de 1000',
        ]"
      ></v-text-field>
      <v-text-field
        min="201"
        hide-spin-buttons
        max="1000"
        type="number"
        :rules="[
          (v) => v >= 201 || 'Valor mínimo de 201',
          (v) => v <= 2000 || 'Valor máximo de 2000',
        ]"
        label="n_max"
        v-model="nMax"
      ></v-text-field>
      <tooltip class="mt-4"
        >Al momento de enviar el chatbot, se enviarán batches de un número
        aleatorio entre <b>{{ nMin }}</b> y <b>{{ nMax }}</b
        >.
      </tooltip>
    </div>
    <div v-if="parseInt(nMin) > parseInt(nMax)" class="color--error text-label">
      El n mínimo no puede ser mayor al n máximo.
    </div>
    <div class="d-flex">
      <v-text-field
        min="5"
        max="60"
        type="number"
        hide-spin-buttons
        label="t_min"
        v-model="tMin"
        :rules="[
          (v) => v >= 1 || 'Valor mínimo de 1',
          (v) => v <= 60 || 'Valor máximo de 60',
        ]"
      ></v-text-field>

      <v-text-field
        min="10"
        max="90"
        type="number"
        hide-spin-buttons
        label="t_max"
        v-model="tMax"
        :rules="[
          (v) => v >= 2 || 'Valor mínimo de 2',
          (v) => v <= 90 || 'Valor máximo de 90',
        ]"
      ></v-text-field>
      <tooltip class="mt-4"
        >Al momento de enviar el chatbot, entre batches, se esperará un tiempo
        aleatorio entre <b>{{ tMin }}</b> y <b>{{ tMax }}</b> segundos.
      </tooltip>
    </div>
    <div v-if="parseInt(tMin) > parseInt(tMax)" class="color--error text-label">
      El t mínimo no puede ser mayor al t máximo.
    </div>
    <div style="font-style: italic" class="text-left">
      Al momento de enviar el chatbot, se enviarán batches de un número
      aleatorio entre <b>{{ nMin }}</b> y <b>{{ nMax }}</b
      >. Después de cada batch, se esperará un tiempo aleatorio de entre
      <b>{{ tMin }}</b> y <b>{{ tMax }}</b> segundos.
    </div>
    <v-file-input
      v-model="usersFile"
      label="Archivo .csv"
      accept=".csv"
      @change="uploadUsers"
    ></v-file-input>
    <v-btn
      @click="sendChatbot"
      :loading="loading"
      :disabled="interventionName == '' || !validNumbers"
      >Crear intervención</v-btn
    >
  </generic-dialog>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ChatbotSender",
  data() {
    return {
      nMin: 250,
      nMax: 300,
      tMin: 30,
      tMax: 60,
      loading: false,
      interventionName: "",
      showInstructions: false,
      usersFile: null,
      usersContent: {},
    };
  },
  props: {
    value: {
      required: true,
      type: Boolean,
    },
  },
  methods: {
    ...mapActions({
      request: "utils/request",
      setToastNotification: "utils/setToastNotification",
    }),
    uploadUsers() {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        const csvData = reader.result;
        const jsonData = this.csvToJson(csvData);

        // Example: Store the parsed JSON data in a component property
        this.usersContent = jsonData;
      };
      reader.readAsText(file);
    },
    csvToJson(csvData) {
      const lines = csvData.split("\n");
      let headers = lines[0].split(",");
      headers = headers.map((h) => h.replace(/[^A-Z0-9_]/gi, ""));
      const jsonData = [];

      for (let i = 1; i < lines.length; i++) {
        const currentLine = lines[i].replace(/\r$/, "").split(","); // Updated line: remove \r at the end of the line
        const jsonObject = {};

        for (let j = 0; j < headers.length; j++) {
          jsonObject[headers[j]] = currentLine[j];
        }

        jsonData.push(jsonObject);
      }
      return jsonData;
    },
    sendChatbot() {
      this.loading = true;
      this.request({
        type: "post",
        body: {
          users: this.usersContent,
          name: this.interventionName,
          n_min: this.nMin,
          n_max: this.nMax,
          t_min: this.tMin,
          t_max: this.tMax,
        },
        endpoint: `send_chatbot/${this.chatbot.name}/`,
      })
        .then(() => {
          this.usersFile = null;
          this.usersContent = null;
          this.showInstructions = true;
          this.loading = false;
        })
        .catch((error) => {
          this.usersFile = null;
          this.usersContent = null;
          this.loading = false;
          if (error.response.status == 400) {
            this.setToastNotification({
              toastState: false,
              toastMessage: "Error: Intervención con ese nombre ya existe.",
            });
          } else {
            this.showInstructions = true;
          }
        });
    },
  },

  computed: {
    ...mapGetters({
      chatbot: "chatbot/chatbot",
    }),
    validNumbers() {
      if (parseInt(this.tMin) > parseInt(this.tMax)) {
        return false;
      }
      if (parseInt(this.nMin) > parseInt(this.nMax)) {
        return false;
      }
      if (parseInt(this.nMin) > 1000 || parseInt(this.nMin) < 200) {
        return false;
      }
      if (parseInt(this.nMax) > 2000 || parseInt(this.nMax) < 201) {
        return false;
      }
      if (parseInt(this.tMin) > 60 || parseInt(this.tMin) < 1) {
        return false;
      }
      if (parseInt(this.tMax) > 90 || parseInt(this.tMax) < 2) {
        return false;
      }
      return true;
    },
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>
