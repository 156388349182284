<template>
  <div style="position: absolute; top: 66px; left: 280px; z-index: 1000">
    <v-card rounded>
      <v-select
        hide-details
        multiple
        label="Intervenciones"
        rounded
        @change="sendIntervention"
        :disabled="false"
        :items="interventions"
        item-text="name"
        item-value="name"
        v-model="currentIntervention"
      >
      </v-select>
    </v-card>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ChatbotInterventionSelector",
  mounted() {
    this.getInterventions();
  },
  data() {
    return {
      currentIntervention: null,
      interventions: [],
      timer: null,
    };
  },
  computed: {
    ...mapGetters({
      chatbot: "chatbot/chatbot",
    }),
  },
  watch: {
    chatbot() {
      this.getInterventions();
    },
  },

  methods: {
    ...mapActions({
      request: "utils/request",
    }),
    sendIntervention() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.$emit("selectIntervention", this.currentIntervention);
      }, 800);
    },
    getInterventions() {
      this.currentIntervention = null;
      this.$emit("selectIntervention", null);
      this.request({
        type: "get",
        endpoint: `intervention?chatbot__id=${this.chatbot.id}`,
      }).then((response) => {
        response.data.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        this.interventions = response.data;
        this.interventions.unshift({ id: null, name: "Todas" });
        // this.interventions[0].status = "paused"; // "finished"; // finished, ongoing, paused, loading
      });
    },
  },
};
</script>
