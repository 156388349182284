<template>
	<v-dialog
		style="z-index: 1005"
		v-model="computedValue"
		@click:outside="$emit('selectedNull')"
		width="700"
	>
		<v-card class="pa-5">
			<!-- start title -->
			<h2 class="text-start">{{ name }}</h2>
			<!-- end title -->
			<!-- start buttons -->
			<div
				class="text-right pe-5 pt-3"
				style="position: absolute; top: 0; right: 0"
			>
				<v-tooltip v-if="commentButton" top>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							@click="$emit('clickAddComment')"
							icon
							v-bind="attrs"
							v-on="on"
						>
							<v-icon> mdi-forum-plus-outline</v-icon>
						</v-btn>
					</template>
					<span>Agregar comentario</span>
				</v-tooltip>

				<v-btn v-if="saveButton" icon @click="clickSave"
					><v-icon>mdi-content-save</v-icon></v-btn
				>
				<v-btn
					v-if="deleteButton"
					icon
					class="me-2"
					@click="$emit('setDeletePopup-deleteNode')"
					><v-icon>mdi-delete</v-icon></v-btn
				>
				<v-btn icon @click="$emit('selectedNull')"
					><v-icon>mdi-close</v-icon></v-btn
				>
			</div>
			<!-- end buttons -->

			<!-- start description -->
			<div v-if="description != ''" class="text-start mt-4 mb-10">
				{{ description }}
			</div>
			<!-- end description -->

			<!-- start fields -->
			<div v-for="(field, index) in fields" :key="index">
				<v-text-field
					v-if="field.name"
					outlined
					dense
					hide-details
					:label="field.name"
					v-model="fieldNameModel"
				></v-text-field>
				<v-text-field
					v-if="field.value"
					outlined
					dense
					hide-details
					:label="field.value"
					v-model="fieldValueModel"
				></v-text-field>
				<!-- for node edition -->
				<v-text-field
					v-if="field.text"
					outlined
					dense
					hide-details
					:label="field.text"
					v-model="fieldTextModel"
				></v-text-field>
				<!-- for comments -->
				<v-textarea
					v-if="field.comment"
					counter
					outlined
					dense
					rows="4"
					maxlength="500"
					v-model="fieldCommentModel"
				/>
				<!-- for answers -->
				<v-text-field
					v-if="field.answer"
					outlined
					dense
					hide-details
					:label="field.answer"
					v-model="fieldAnswerModel"
				></v-text-field>
				<!-- node edition checkboxes -->
				<v-checkbox
					v-if="field.initial"
					style="max-width: 200px"
					hide-details
					label="nodo inicial"
					v-model="startNode"
				></v-checkbox>
				<v-checkbox
					v-if="field.special"
					style="max-width: 200px"
					hide-details
					class="mb-2"
					label="Nodo especial (requiere desarrollo)"
					v-model="specialNode"
				></v-checkbox>
			</div>
			<!-- end fields -->

			<!-- start custom list -->
			<div>
				<CustomList v-for="(list, index) in lists" :key="index" v-bind="list">
				</CustomList>
			</div>
			<!-- end custom list -->

			<!-- start test from here button -->
			<v-btn
				v-if="testButton"
				class="mt-3"
				color="#FFCCBC"
				@click="editTestFeatures = true"
				>Testear bot desde aquí</v-btn
			>
			<!-- end test from here button -->
		</v-card>
	</v-dialog>
</template>
<script>
	import CustomList from '../components/CustomList.vue';
	export default {
		name: 'CustomDialog',
		components: {
			CustomList,
		},
		data() {
			return {
				dialogModel: false,
				fieldNameModel: '',
				fieldValueModel: '',
				fieldTextModel: '',
				fieldAnswerModel: '',
				startNode: '',
				specialNode: '',
			};
		},
		props: {
			name: {
				type: String,
				default: '',
			},
			description: {
				type: String,
				default: '',
			},
			endpoint: {
				type: String,
				default: '',
			},
			fields: {
				type: Array,
			},
			lists: {
				type: Array,
			},
			commentButton: {
				type: Boolean,
				default: false,
			},
			saveButton: {
				type: Boolean,
				default: false,
			},
			deleteButton: {
				type: Boolean,
				default: false,
			},
			testButton: {
				type: Boolean,
				default: false,
			},
			value: {
				type: Boolean,
			},
		},
		computed: {
			computedValue: {
				get() {
					return this.value;
				},
				set(v) {
					this.$emit('input', v);
				},
			},
		},
		mounted() {},
		methods: {
			clickSave(saveType, params) {
				if (saveType === 'saveFeature') {
					this.saveFeature(params.featureId, params.text);
				}
				if (saveType === 'saveAnswer') {
					this.saveAnswer();
				}
				if (saveType === 'saveNodeWeight') {
					this.saveNodeWeight();
				}
				if (saveType === 'saveNode') {
					this.saveNode();
				}
				if (saveType === 'saveAnswerWeight') {
					this.saveAnswerWeight();
				}
				if (saveType === 'saveTransitionWeight') {
					this.saveTransitionWeight();
				}
				if (saveType === 'saveComment') {
					this.saveTransitionWeight();
				}
			},
			clickDelete(deleteType, params) {
				if (deleteType === 'deleteFeature') {
					this.deleteFeature(params);
				}
			},
			saveFeature(featureId, text, endpoint) {
				if (featureId !== null && featureId !== undefined) {
					// patch
					this.request({
						errorMessage: 'error updating feature',
						successMessage: 'Feature updating successfully',
						type: 'patch',
						endpoint: `${endpoint}/${featureId}/`,
						body: {
							text,
						},
					}).then(() => {
						//change the values in frontend probably with main or store
					});
				} else {
					// create
					this.request({
						errorMessage: 'Error creating feature',
						successMessage: 'Feature created successfully',
						type: 'post',
						endpoint: `${endpoint}/`,
						body: {
							text,
						},
					}).then(() => {
						//change the values in frontend probably with main or store
					});
				}
			},
			saveAnswer(answerId, text, endpoint) {
				if (answerId !== undefined && answerId !== null) {
					this.request({
						errorMessage: 'Error updating answer',
						successMessage: 'Answer updated successfully',
						type: 'patch',
						endpoint: `${endpoint}/${answerId}/`,
						body: {
							text,
						},
					}).then(() => {
						//change the values in frontend probably with main or store
					});
				} else {
					this.request({
						errorMessage: 'Error creating answer',
						successMessage: 'Answer created successfully',
						type: 'post',
						endpoint: `${endpoint}/`,
						body: {
							text,
							node: this.selectedNode,
						},
					}).then(() => {
						//change the values in frontend probably with main or store
					});
				}
			},
			saveNodeWeight(weightId, weight, feature, endpoint) {
				if (weightId !== null && weightId !== undefined) {
					// update
					this.request({
						errorMessage: 'Error updating weight',
						successMessage: 'Node weight updated successfully',
						type: 'patch',
						endpoint: `${endpoint}/${weightId}/`,
						body: {
							weight,
							feature,
						},
					}).then(() => {
						//change the values in frontend probably with main or store
					});
				} else {
					// create
					this.request({
						errorMessage: 'Error creating weight',
						successMessage: 'Node weight created successfully',
						type: 'post',
						endpoint: `${endpoint}/`,
						body: {
							weight,
							feature,
							node: this.selectedNode,
						},
					}).then(() => {
						//change the values in frontend probably with main or store
					});
				}
			},
			saveNode(endpoint) {
				let patchInformation = this.selectedNodeInformation;
				this.request({
					errorMessage: 'Error updating node',
					successMessage: 'Node updated successfully',
					type: 'patch',
					endpoint: `${endpoint}/${this.selectedNode}/`,
					body: {
						name: patchInformation.name,
						text: patchInformation.text,
						start: patchInformation.start,
					},
				}).then(() => {
					//change the values in frontend probably with main or store
				});
			},
			saveAnswerWeight(weightId, weight, feature, answerId, endpoint) {
				if (weightId !== null && weightId !== undefined) {
					// save
					this.request({
						errorMessage: 'Error updating answer weight',
						successMessage: 'Answer weight udpated successfully',
						endpoint: `${endpoint}/${weightId}/`,
						body: { weight, feature },
						type: 'patch',
					}).then(() => {
						//change the values in frontend probably with main or store
					});
				} else {
					// create
					this.request({
						errorMessage: 'Error creating answer weight',
						successMessage: 'Answer weight created successfully',
						endpoint: `${endpoint}/`,
						body: { weight, feature, answer: answerId },
						type: 'post',
					}).then(() => {
						//change the values in frontend probably with main or store
					});
				}
			},
			saveTransitionWeight(transitionId, weight, feature, endpoint) {
				if (transitionId) {
					// save
					this.request({
						errorMessage: 'Error updating transition weight',
						successMessage: 'Transition wegiht updated successfully',
						endpoint: `${endpoint}/${transitionId}/`,
						body: { expected_weight: weight, feature },
						type: 'patch',
					}).then(() => {
						//change the values in frontend probably with main or store
					});
				} else {
					// create
					this.request({
						errorMessage: 'Error creating transition weight',
						successMessage: 'Transition wegiht created successfully',
						endpoint: `${endpoint}/`,
						body: {
							expected_weight: weight,
							feature,
							source: this.selectedEdgeInformation.from,
							destination: this.selectedEdgeInformation.to,
						},
						type: 'post',
					}).then(() => {
						//change the values in frontend probably with main or store
					});
				}
			},
			//probablemente no se use
			saveComment(endpoint) {
				const currentDate = new Date(
					new Date().toString().split('GMT')[0] + ' UTC'
				).toISOString();
				if (!this.selectedNodeInformation['comments']) {
					this.selectedNodeInformation['comments'] = [];
				}

				this.request({
					errorMessage: 'Error saving comment',
					successMessage: 'Comment saved successfully',
					endpoint: `${endpoint}/`,
					type: 'post',
					body: {
						text: this.newCommentText,
						date_sent: currentDate,
						node: this.selectedNode,
					},
				}).then(() => {
					//change the values in frontend probably with main or store
				});
			},
		},
	};
</script>
