var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('generic-dialog',{attrs:{"width":"300"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_vm._v(" ¿Estás seguro de eliminar esta validación de respuesta? "),_c('div',{staticClass:"d-flex mt-4"},[_c('v-btn',{staticClass:"me-auto",on:{"click":_vm.sendDeleteAnswerValidation}},[_vm._v("Sí, eliminar")]),_c('v-btn',{staticClass:"ms-auto",on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v("No")])],1)]),(_vm.internalAnswerValidation)?_c('generic-dialog',{attrs:{"width":"500","height":"200","delete-condition":_vm.role == 'admin',"title":"Módulo de edición"},on:{"save":function($event){return _vm.saveNodeWeight({ answerValidation: _vm.internalAnswerValidation })},"delete":function($event){_vm.deleteDialog = true}},model:{value:(_vm.computedValue),callback:function ($$v) {_vm.computedValue=$$v},expression:"computedValue"}},[_c('div',{staticClass:"pt-10"},[_c('v-select',{attrs:{"outlined":"","dense":"","label":"Categoría","items":[
          { name: '=', id: 'eq' },
          { name: '>', id: 'gt' },
          { name: '<', id: 'lt' },
          { name: '<=', id: 'lte' },
          { name: '>=', id: 'gte' },
        ],"item-text":"name","item-value":"id","hide-details":""},model:{value:(_vm.internalAnswerValidation.category),callback:function ($$v) {_vm.$set(_vm.internalAnswerValidation, "category", $$v)},expression:"internalAnswerValidation.category"}}),_c('v-text-field',{staticClass:"pt-2",attrs:{"type":"number","label":"Orden","disabled":_vm.role != 'admin',"dense":"","outlined":"","hide-details":"","hide-spin-buttons":""},model:{value:(_vm.internalAnswerValidation.number),callback:function ($$v) {_vm.$set(_vm.internalAnswerValidation, "number", $$v)},expression:"internalAnswerValidation.number"}}),_c('v-text-field',{staticClass:"pt-2",attrs:{"label":"Texto","disabled":_vm.role != 'admin',"dense":"","outlined":"","hide-details":"","hide-spin-buttons":""},model:{value:(_vm.internalAnswerValidation.error_text),callback:function ($$v) {_vm.$set(_vm.internalAnswerValidation, "error_text", $$v)},expression:"internalAnswerValidation.error_text"}})],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }