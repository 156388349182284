<template>
  <generic-dialog width="500" v-model="computedValue">
    <div class="text-subtitle bold">Nomenclatura de colores y símbolos</div>
    <div v-for="(item, index) in items" :key="index" class="text-left">
      <v-icon class="me-2" x-large :color="item.color ?? 'blue'">{{
        item.icon
      }}</v-icon>
      {{ item.text }}
    </div>
  </generic-dialog>
</template>
<script>
export default {
  name: "ChatbotNomenclature",
  data() {
    return {
      items: [
        {
          icon: "mdi-circle",
          color: "#D2E5FF",
          text: "Nodo común",
        },
        {
          icon: "mdi-circle",
          color: "#90EE90",
          text: "Nodo inicial",
        },
        {
          icon: "mdi-circle",
          color: "#D383D2",
          text: "Nodo que requiere pre-procesamiento",
        },
        {
          icon: "mdi-circle",
          color: "#F29B9B",
          text: "Nodo que requiere post-procesamiento",
        },

        {
          icon: "mdi-arrow-right-thin",
          color: "#0466c8",
          text: "Aristas dirigidas hacia el nodo seleccionado",
        },
        {
          icon: "mdi-arrow-right-thin",
          color: "#ff7b00",

          text: "Aristas salientes desde el nodo seleccionado",
        },
      ],
    };
  },
  props: {
    value: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>
