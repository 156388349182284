<template>
  <div>
    <!-- delete dialog -->
    <generic-dialog v-model="deleteDialog" width="300">
      ¿Estás seguro de eliminar esta validación de respuesta?
      <div class="d-flex mt-4">
        <v-btn @click="sendDeleteAnswerValidation" class="me-auto"
          >Sí, eliminar</v-btn
        >
        <v-btn @click="deleteDialog = false" class="ms-auto">No</v-btn>
      </div>
    </generic-dialog>
    <!-- edit node weight -->
    <generic-dialog
      v-model="computedValue"
      width="500"
      height="200"
      @save="saveNodeWeight({ answerValidation: internalAnswerValidation })"
      @delete="deleteDialog = true"
      :delete-condition="role == 'admin'"
      title="Módulo de edición"
      v-if="internalAnswerValidation"
    >
      <div class="pt-10">
        <v-select
          outlined
          dense
          v-model="internalAnswerValidation.category"
          label="Categoría"
          :items="[
            { name: '=', id: 'eq' },
            { name: '>', id: 'gt' },
            { name: '<', id: 'lt' },
            { name: '<=', id: 'lte' },
            { name: '>=', id: 'gte' },
          ]"
          item-text="name"
          item-value="id"
          hide-details
        ></v-select>
        <v-text-field
          type="number"
          label="Orden"
          :disabled="role != 'admin'"
          dense
          outlined
          class="pt-2"
          hide-details
          hide-spin-buttons
          v-model="internalAnswerValidation.number"
        >
        </v-text-field>
        <v-text-field
          label="Texto"
          :disabled="role != 'admin'"
          dense
          outlined
          class="pt-2"
          hide-details
          hide-spin-buttons
          v-model="internalAnswerValidation.error_text"
        >
        </v-text-field>
      </div>
    </generic-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AnswerValidation",
  props: {
    answerValidation: {
      required: true,
    },
    value: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      deleteDialog: false,
      internalAnswerValidation: null,
    };
  },
  mounted() {
    this.internalAnswerValidation = { ...this.answerValidation };
  },
  methods: {
    ...mapActions({
      deleteAnswerValidation: "chatbot/deleteAnswerValidation",
      saveAnswerValidation: "chatbot/saveAnswerValidation",
    }),
    sendDeleteAnswerValidation() {
      this.deleteAnswerValidation({
        answerValidation: this.internalAnswerValidation,
      }).then(() => {
        this.deleteDialog = false;
        this.internalAnswerValidation = null;
        this.$emit("selectAnswerValidation", null);
        this.$emit("input", false);
      });
    },
  },
  computed: {
    ...mapGetters({
      role: "chatbot/role",
    }),
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>
