<template>
  <generic-dialog v-model="computedValue" width="280">
    <h3 class="text-start">Escribe tu comentario</h3>
    <br />
    <v-textarea
      counter
      outlined
      dense
      rows="4"
      maxlength="500"
      v-model="newCommentText"
    />
    <br />
    <v-btn @click="saveComment">Comentar</v-btn>
  </generic-dialog>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "CommentCreator",
  props: {
    value: {
      required: true,
    },
    node: {
      required: true,
    },
  },
  data() {
    return {
      newCommentText: "",
    };
  },
  methods: {
    ...mapActions({
      request: "utils/request",
      addCommentToNode: "chatbot/addCommentToNode",
    }),
    saveComment() {
      const currentDate = new Date(
        new Date().toString().split("GMT")[0] + " UTC"
      ).toISOString();
      this.request({
        errorMessage: "Error saving comment",
        successMessage: "Comment saved successfully",
        endpoint: "comment/",
        type: "post",
        body: {
          text: this.newCommentText,
          date_sent: currentDate,
          node: this.node,
        },
      }).then((response) => {
        this.addCommentToNode({
          comment: response.data.data,
          nodeId: this.node,
        });
        this.$emit("input", false);
      });
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>
