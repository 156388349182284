<template>
  <generic-dialog
    v-model="computedValue"
    width="500"
    :title="`Interacciones de intervención:
        ${intervention ? intervention : 'Todas'}`"
  >
    <div class="text-left">
      Escriba las <b>variables y características</b> que quiere descargar de los
      usuarios presentes en la interacción. Si desea descargar más de una,
      <b>separar por coma</b>. Si no desea descargar ninguna variable ni
      característica, <b>dejar en blanco</b>.
    </div>
    <v-text-field
      label="variables"
      v-model="variables"
      placeholder="name,last_name"
    ></v-text-field>
    <v-text-field
      v-model="features"
      label="características"
      placeholder="want_to_talk"
    ></v-text-field>
    <v-btn :loading="downloading" @click="downloadChatbotInteractions">
      Descargar interacciones
    </v-btn>
  </generic-dialog>
</template>
<script>
export default {
  name: "ChatbotInteractionDownloader",
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    intervention: {
      required: false,
      default: null,
    },
  },
  mounted() {
    this.downloading = false;
  },
  methods: {
    downloadChatbotInteractions() {
      this.downloading = true;
      this.$emit("downloadChatbotInteractions", this.variables, this.features);
    },
  },
  data() {
    return {
      variables: "",
      features: "",
      downloading: false,
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>
