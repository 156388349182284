<template>
  <v-card
    style="background: white; margin: 3px"
    class="ma-2 rounded-lg d-flex flex-column"
  >
    <v-card
      :color="`${themes[currentTheme]}`"
      class="text-title pa-4 d-flex w-100 color--neutral-100-t justify-content-center align-items-center"
      elevation="0"
      style="border-radius: 5px 5px 0px 0px"
    >
      <v-img
        v-if="currentImage"
        :src="currentImage"
        max-width="40"
        max-height="40"
        style="border-radius: 40px"
      ></v-img>
      <div class="ms-3">
        {{ chatbotLabel }}
      </div>
      <div class="ms-auto text-right mr-2">
        <v-icon @click="closeChatbot" color="white" v-if="hasCloseButton">
          mdi-close
        </v-icon>
      </div>
    </v-card>

    <!-- chat -->
    <div
      id="messageBody"
      class="pt-4 pb-4"
      :style="`overflow-y: auto; max-height: ${width}px; width: ${width}px`"
    >
      <div v-for="(chat, index) in chats" :key="index" class="mb-2 ms-4 me-4">
        <div v-if="chat.source == 'Me'" class="text-right">
          <div>
            <!--<div class="ms-2">Usuario</div>-->
            <v-chip
              :color="`${themes[currentTheme]}`"
              class="ms-1 me-1 pt-1 pb-1 text-left block text-body"
              style="line-height: 21px !important; background: #0b286b"
            >
              <div
                style="color: white !important"
                v-html="formatText(chat.text)"
              ></div>
            </v-chip>
          </div>
        </div>
        <div v-else class="text-left w-100">
          <div class="w-100">
            <!--<div class="ms-auto me-2">Bot</div>-->
            <v-chip
              :color="`grey lighten-2`"
              class="ms-1 me-1 pt-1 pb-1 text-left block text-body"
              style="line-height: 21px !important"
            >
              <div class="d-flex flex-column">
                <div
                  v-if="chat.text != 'elipsis'"
                  v-html="formatText(chat.text)"
                ></div>
                <div
                  style="min-width: 12px; width: 12px"
                  v-else
                  class="loading"
                ></div>
                <div v-if="chat.media_url" class="mt-2">
                  <div v-if="getMediaType(chat.media_url) == 'image'">
                    <img
                      :src="chat.media_url"
                      class="mt-1"
                      style="width: 100%"
                    />
                  </div>
                  <div v-if="getMediaType(chat.media_url) == 'video'">
                    <video class="mt-1" style="width: 100%" controls>
                      <source :src="chat.media_url" type="video/mp4" />
                      <source :src="chat.media_url" type="video/quicktime" />
                      <source :src="chat.media_url" type="video/3gpp" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
                <a
                  :href="chat.link"
                  style="text-decoration: none"
                  target="blank"
                >
                  <v-card
                    class="rounded-lg w-100 ps-2 pe-2 pt-1 pb-1 mt-2 mb-1 text-center"
                    style="cursor: pointer"
                    v-if="chat.link"
                  >
                    <v-icon class="me-2" color="#2B9BF4"
                      >mdi-open-in-new</v-icon
                    >
                    <span style="color: #2b9bf4">{{ chat.button }}</span>
                  </v-card>
                </a>
              </div>
            </v-chip>
          </div>
          <div
            class="mt-1"
            :class="
              hasDescription(chat.answers)
                ? 'd-flex flex-column'
                : 'text-center'
            "
          >
            <span v-for="(button, index2) in chat.answers" :key="index2">
              <v-hover v-slot="{ hover }">
                <v-btn
                  small
                  :rounded="hasDescription(chat.answers) ? false : true"
                  :outlined="hover ? false : true"
                  class="text-body text-wrap color--neutral-100-t hover-div ma-1"
                  :color="`${themes[currentTheme]}`"
                  style="
                    border-width: 3px !important;
                    text-transform: none;
                    letter-spacing: 0px;
                    max-width: 98%;
                    font-family: Karla;
                    word-wrap: break-word;
                    cursor: pointer !important;
                  "
                  :style="
                    hasDescription(chat.answers)
                      ? 'border-radius: 8px; width: auto; margin-right: auto !important;min-height:50px; text-align:left;padding-top:3px; padding-bottom: 3px'
                      : ''
                  "
                  :class="hover ? 'extra-border' : ''"
                  @click="clickButton(button, index)"
                >
                  <div
                    class="text-wrap"
                    style="
                      word-break: break-word;
                      white-space: normal;
                      cursor: pointer !important;
                      max-width: 100%;
                    "
                  >
                    {{ button.text }}
                    <br v-if="button.description" />
                    <div v-if="button.description" class="text-label">
                      {{ button.description }}
                    </div>
                  </div>
                </v-btn>
              </v-hover>
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- input -->
    <form @submit.prevent="submit" class="mt-auto" v-if="showForm">
      <div>
        <v-textarea
          rows="1"
          no-resize
          solo
          outlined
          class="text-body"
          @keydown.enter="submit"
          auto-grow
          hide-details
          v-model="currentText"
        >
          <template v-slot:append>
            <v-icon class="mt-auto mb-auto" @click="submit">mdi-send</v-icon>
          </template>
        </v-textarea>
      </div>
    </form>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Chatbot",
  mounted() {
    let uuid;
    let getNextNode = true;
    if (!this.user) {
      if (!this.uuid) {
        uuid = this.generateUUID();
        getNextNode = false;
        this.setUUID({ uuid }).then(() => {
          this.getInteractions();
        });
      } else {
        getNextNode = false;
        this.getInteractions();
      }
    } else {
      uuid = null;
    }
    if (this.externalTheme != "") {
      this.currentTheme = this.externalTheme;
    } else if (this.chatbot) {
      this.currentTheme = this.chatbot.theme;
      this.currentImage = this.chatbot.image;
      this.chatbotLabel = this.chatbot.label;
    } else if (this.chatbotName) {
      this.request({
        type: "get",
        endpoint: `chatbot_information/${this.chatbotName}/`,
      }).then((response) => {
        let data = response.data;
        this.chatbotLabel = data.label;
        this.chatbotTheme = data.theme;
        this.chatbotImage = data.image;
      });
    }
    if (!this.currentNode && getNextNode) {
      // get first node
      let body = { text: "", id: "", stay: true };
      this.prepareAddMessages(body);
    } else {
      this.request({
        type: "post",
        endpoint: `user_node/`,
        body: { node: this.currentNode.id },
      }).then(() => {
        let body = {
          stay: true,
          text: "",
          id: "",
        };

        this.prepareAddMessages(body);
      });
    }
  },
  data() {
    return {
      chatbotLabel: "",
      currentUser: "Me",
      currentText: "",
      currentTheme: "quaternary",
      currentImage: "",
      themes: {
        primary: "indigo darken-2",
        secondary: "red darken-3",
        tertiary: "purple darken-4",
        quaternary: "green darken-4",
      },
    };
  },
  props: {
    externalTheme: {
      required: false,
      default: "",
      type: String,
    },
    width: {
      required: false,
      type: Number,
      default: 600,
    },
    showForm: {
      required: false,
      default: true,
      type: Boolean,
    },
    chats: {
      required: false,
      type: Array,
      default: () => [],
    },
    hasCloseButton: {
      default: true,
      type: Boolean,
      required: false,
    },
    currentNode: {
      default: null,
      required: false,
    },
    nodeCharacteristics: {
      default: null,
      required: false,
    },
    chatbotName: {
      default: null,
      required: false,
    },
  },
  computed: {
    ...mapGetters({
      user: "utils/user",
      chatbot: "chatbot/chatbot",
      uuid: "utils/uuid",
    }),
    hasAnswers() {
      if (this.chats.length == 0) {
        return false;
      }
      let lastElement = this.chats[this.chats.length - 1];
      if (
        lastElement &&
        lastElement.answers &&
        lastElement.answers.length > 0
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      request: "utils/request",
      setUUID: "utils/setUUID",
    }),
    getInteractions() {
      this.request({
        type: "get",
        endpoint: `get_history/${this.chatbotName}/${this.uuid}/`,
      }).then((response) => {
        this.chats = response.data.interactions;
        if (this.chats.length == 0) {
          let body = { text: "", id: "", stay: true };
          this.prepareAddMessages(body);
        }
      });
    },
    hasDescription(answers) {
      let returnValue = false;
      if (answers) {
        answers.forEach((a) => {
          if (a.description && a.description != "") {
            returnValue = true;
          }
        });
      }
      return returnValue;
    },
    formatText(text) {
      // Replace text between underscores with italic formatting
      text = text.replace(/_([^_]+)_/g, "<i>$1</i>");

      // Replace text between asterisks with bold formatting
      text = text.replace(/\*([^*]+)\*/g, "<b>$1</b>");

      return text;
    },
    prepareAddMessages(body) {
      this.chats.push({
        source: "Bot",
        text: "elipsis",
      });
      if (this.uuid) {
        body["uuid"] = this.uuid;
      }
      this.request({
        type: "post",
        endpoint: `get_next_node/${this.chatbotName}/`,
        body: body,
      }).then((response) => {
        this.chats.pop();
        this.addMessages(response.data, "Bot");
        this.scrollToBottom();
      });
    },
    random(min, max) {
      return Math.random() * (max - min + 1) - min;
    },
    generateUUID() {
      // Public Domain/MIT
      var d = new Date().getTime(); //Timestamp
      var d2 =
        (typeof performance !== "undefined" &&
          performance.now &&
          performance.now() * 1000) ||
        0; //Time in microseconds since page-load or 0 if unsupported
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = Math.random() * 16; //random number between 0 and 16
          if (d > 0) {
            //Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
          } else {
            //Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
          }
          return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
      );
    },
    closeChatbot() {
      this.$emit("closeChatbot");
    },
    sendMessage(message) {
      let body = message;
      if (this.uuid) {
        body["user"] = this.uuid;
      }
      this.prepareAddMessages(body);
    },
    async addMessages(messages, source) {
      let wait = false;
      if (messages.length > 1) {
        wait = true;
      }
      messages.forEach((m) => {
        m.source = source;
      });
      if (source == "Bot") {
        for (const m of messages) {
          this.chats.push(m);
          this.$emit("colorNode", m.id);
          if (wait) {
            await new Promise((resolve) => setTimeout(resolve, 1500));
          }
        }
      } else {
        messages.forEach((m) => {
          this.chats.push(m);
        });
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        var chatHistory = document.getElementById("messageBody");
        if (chatHistory) {
          chatHistory.scrollTop = chatHistory.scrollHeight;
        }
      });
    },
    submit() {
      if (this.currentText != "") {
        // delete answers
        let lastElement = this.chats[this.chats.length - 1];
        lastElement.answers = [];
        this.addMessages([{ text: this.currentText }], "Me");

        let body = { text: this.currentText.replace("\n", ""), id: null };
        if (this.uuid) {
          body["user"] = this.uuid;
        }
        this.prepareAddMessages(body);
        setTimeout(() => {
          this.currentText = "";
        }, 100);
      }
      this.scrollToBottom();
    },
    clickButton(button, index) {
      // remove buttons for given index
      this.chats[index].answers = [];
      let message = { text: button.text };
      this.addMessages([message], "Me");
      this.sendMessage({ text: button.text, id: button.id });
      this.scrollToBottom();
    },
    getMediaType(media_url) {
      const videoExtensions = [".mp4", ".mov", ".3gp"];
      const imageExtensions = [".jpeg", ".jpg", ".png"];
      const extension = media_url
        .slice(media_url.lastIndexOf("."))
        .toLowerCase();

      if (videoExtensions.includes(extension)) {
        return "video";
      } else if (imageExtensions.includes(extension)) {
        return "image";
      } else {
        return false;
      }
    },
  },
};
</script>
<style>
.v-chip {
  height: auto !important;
  max-width: calc(100% - 50px);
}

.v-chip .v-chip__content {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  white-space: pre-wrap;
  color: rgb(0, 0, 0);
}
.button-chatbot.v-btn__content {
  width: 100%;
  white-space: normal;
}
.loading::after {
  display: inline-block;
  animation: dotty steps(1, end) 1s infinite;
  content: "";
}

@keyframes dotty {
  0% {
    content: "";
  }
  25% {
    content: ".";
  }
  50% {
    content: "..";
  }
  75% {
    content: "...";
  }
  100% {
    content: "";
  }
}

.hover-div {
  transition: all 0.1s ease-in-out;
}
.extra-border {
  border: 3px solid black;
}
</style>
