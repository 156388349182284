<template>
  <div
    style="
      width: 100vw;
      height: 100vh;
      background: lightgray;
      overflow-y: hidden;
    "
  >
    <div class="pb-3 pt-3" style="height: 100%">
      <Chatbot
        :hasCloseButton="false"
        class="ma-auto"
        v-if="showChatbot"
        style="width: 40%; min-width: 600px; height: 100%"
        :chatbotName="chatbotName"
      />
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Chatbot from "@/components/Chatbot.vue";

export default {
  name: "ChatbotTest",
  components: { Chatbot },
  data() {
    return {
      chatbotName: "",
      showChatbot: false,
      username: null,
      uuid: null,
    };
  },
  methods: {
    ...mapActions({
      request: "utils/request",
    }),
  },
  mounted() {
    const { chatbotName } = this.$route.params;
    this.chatbotName = chatbotName;
    /*this.request({
      type: "get",
      endpoint: `delete_progress/${chatbotName}?features=1`,
    }).then(() => {*/
    this.showChatbot = true;
    //});
  },
};
</script>
