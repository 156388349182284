<template>
  <div>
    <!-- delete dialog -->
    <generic-dialog v-model="deleteDialog" width="300">
      ¿Estás seguro de eliminar esta característica?
      <div class="d-flex mt-4">
        <v-btn @click="sendDeleteFeature" class="me-auto">Sí, eliminar</v-btn>
        <v-btn @click="deleteDialog = false" class="ms-auto">No</v-btn>
      </div>
    </generic-dialog>
    <!-- edit feature -->
    <generic-dialog
      v-model="computedValue"
      width="500"
      v-if="feature"
      @save="sendSaveFeature"
      @delete="deleteDialog = true"
      title="Módulo de edición de características"
    >
      <v-text-field
        :disabled="role != 'admin'"
        dense
        outlined
        hide-details
        class="mt-5"
        label="Característica"
        v-model="internalFeature.name"
      ></v-text-field>
    </generic-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Feature",
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    feature: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      internalFeature: null,
      deleteDialog: false,
    };
  },
  mounted() {
    this.internalFeature = { ...this.feature };
    this.internalFeature.description = "description";
  },
  methods: {
    ...mapActions({
      addFeature: "chatbot/addFeature",
      saveFeature: "chatbot/saveFeature",
      deleteFeature: "chatbot/deleteFeature",
    }),
    sendSaveFeature() {
      if (this.internalFeature.id) {
        this.saveFeature({ feature: this.internalFeature }).then(() => {
          this.$emit("updateFeature", this.internalFeature);
          this.$emit("input", false);
        });
      } else {
        this.addFeature({ feature: this.internalFeature }).then((feature) => {
          this.$emit("pushFeature", feature);
          this.$emit("input", false);
        });
      }
    },
    sendDeleteFeature() {
      this.deleteFeature({ feature: this.internalFeature }).then(() => {
        this.$emit("removeFeature", this.internalFeature);
        this.$emit("input", false);
      });
    },
  },
  computed: {
    ...mapGetters({
      role: "chatbot/role",
    }),
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>
