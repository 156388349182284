import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./styles/main.scss";
import { Network } from "vue-vis-network";
import CustomDialog from "./components/CustomDialog.vue";
import GenericDialog from "./components/GenericDialog.vue";
import Tooltip from "./components/Tooltip.vue";
// vimeo
import vueVimeoPlayer from "vue-vimeo-player";
Vue.use(vueVimeoPlayer);
// smooth scroll
import VueSmoothScroll from "vue2-smooth-scroll";
Vue.use(VueSmoothScroll);
// mixpanel
import mixpanel from "mixpanel-browser";
// initialize mixpanel
mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN, {
  debug: process.env.VUE_APP_MIXPANEL_DEBUG || false,
  ignore_dnt: true,
});
// papaparse
import VuePapaParse from "vue-papa-parse";
Vue.use(VuePapaParse);
Vue.component("network", Network);
Vue.component("CustomDialog", CustomDialog);
Vue.component("GenericDialog", GenericDialog);
Vue.component("Tooltip", Tooltip);

Vue.config.productionTip = false;
Vue.prototype.$log = function (text) {
  console.log(text);
};
router.beforeEach((to, from, next) => {
  // check for logged in/out available routes
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters["utils/loggedIn"]) {
      next({ name: "login" });
    } else {
      next();
    }
  } else {
    next();
  }
});
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
