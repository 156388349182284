<template>
  <div>
    <v-card
      class="ms-auto me-auto main-card"
      style="margin-top: 20px; margin-bottom: 20px; background: lightgray"
    >
      <div style="width: 100%; text-align: center">
        <img
          src="@/assets/secretaria.png"
          style="max-width: 50%; min-width: 320px"
        />
      </div>

      <h2 class="text-center text-title w-100 ms-8 me-8 bold mb-1">
        Inscríbete y recibe información del Orienta Bot
      </h2>
      <transition name="fade" mode="out-in">
        <div v-if="currentScreen === 'prestart'" key="prestart" class="screen">
          <div class="text-left text-body mt-4 mb-4">
            ¡Hola! En alianza con la <b>ONG Consilium Bots</b> y la
            <b>Secretaría de Educación del Distrito</b>, hemos
            <b>diseñado un chat de WhatsApp</b> a través del cual te
            contactaremos para ayudarte a resolver preguntas que tengas sobre
            las oportunidades de trabajo en la ciudad y las posibilidades de
            formación que existen, cómo puedes pagarlas y otras preguntas más
            sobre tu futuro, luego de salir del colegio. En este formulario
            puedes dejarnos tus datos para contactarte.
          </div>
          <v-divider></v-divider>
          <div class="text-center pt-3 mt-auto">
            <v-btn
              color="primary"
              @keyup.enter="currentScreen = 'start'"
              rounded
              @click="currentScreen = 'start'"
              >Continuar</v-btn
            >
          </div>
        </div>
        <div v-else-if="currentScreen === 'start'" key="start" class="screen">
          <div class="text-left text-label">
            En cumplimiento de lo dispuesto en la Ley 1581 de 2012, "Por el cual
            se dictan disposiciones generales para la protección de datos
            personales", se informa a los estudiantes que los datos solicitados
            en el diligenciamiento del formulario tienen como finalidad
            gestionar la participación en el proceso de formación y
            acompañamiento. Asimismo, solicitamos su autorización para usar la
            información aquí consignada con las siguientes finalidades:
            <br />1. Recibir información relacionada con los procesos de
            formación de la Secretaría de Educación de Bogotá.<br />2. Ser
            contactado la Secretaría de Educación de Bogotá como parte de
            procesos de seguimiento a las actividades realizadas.<br />La
            información aquí suministrada será tratada por la Secretaría de
            Educación de Bogotá de acuerdo con las políticas de uso de datos
            vigentes en las entidades y determinadas por el ordenamiento
            jurídico del Estado Colombiano garantizando la confidencialidad,
            privacidad, seguridad y circulación restringida de los datos
            personales aquí recolectados y solo se utilizarán para las
            finalidades mencionadas.
          </div>
          <v-divider></v-divider>

          <div class="text-left pt-3">
            <div>¿Cuál es tu número de identificación?</div>
            <v-text-field
              label="Número de identificación"
              @copy.prevent
              @keydown.up.prevent
              @keydown.down.prevent
              hide-spin-buttons
              v-model="user.document_number"
            >
            </v-text-field>
            <v-text-field
              label="Repetir número de identificación"
              @keydown.up.prevent
              @keydown.down.prevent
              hide-spin-buttons
              v-model="documentNumberValidator"
              :error="documentHasWarning"
              :error-messages="documentWarningMessages"
            >
            </v-text-field>
            <v-btn
              color="primary"
              rounded
              @click="getUserInformation"
              :disabled="
                user.document_number == '' ||
                documentNumberValidator != user.document_number
              "
              >Continuar</v-btn
            >
          </div>
        </div>
        <div
          v-else-if="currentScreen === 'verify-info'"
          key="verify-info"
          class="screen"
        >
          <div class="text-center">
            <h3>¿Es esta información correcta?</h3>
            <div>
              Tu nombre es {{ user.first_name }} y estás cursando grado
              {{ user.grade }}
            </div>
            <div class="d-flex mt-4">
              <v-btn
                color="primary"
                class="ms-auto"
                rounded
                @click="currentScreen = 'contact-info'"
                >Sí</v-btn
              >
              <v-btn
                class="ms-2 me-auto"
                color="primary"
                rounded
                @click="currentScreen = 'verify-document-number'"
                >No</v-btn
              >
            </div>
          </div>
        </div>
        <div
          v-else-if="currentScreen === 'verify-document-number'"
          key="verify-document-number"
          class="screen"
        >
          <h3>¿Es este número de identificación correcto?</h3>
          <h3 class="bold">{{ user.document_number }}</h3>
          <div class="d-flex">
            <v-btn
              class="ms-auto"
              rounded
              color="primary"
              @click="
                resetUserInformation();
                currentScreen = 'all-info';
              "
            >
              Sí
            </v-btn>
            <v-btn
              class="ms-2 me-auto"
              rounded
              color="primary"
              @click="
                resetUserInformation();
                currentScreen = 'start';
              "
            >
              No
            </v-btn>
          </div>
        </div>
        <div
          v-else-if="currentScreen === 'all-info'"
          key="all-info"
          class="screen"
        >
          <h3>Favor rellena los siguientes datos:</h3>
          <v-select
            label="Tipo de documento:"
            :items="[
              'TI: Tarjeta de Identidad',
              'CC: Cédula de Ciudadanía',
              'CBB : Certificado Cabildo',
              'CE: Cédula de Extranjería',
              'NES: Número Establecido por la Secretaría',
              'NIP: Número de Identificación personal',
              'NUIP: Número único de identificación personal',
              'PEP: Permiso especial de permanencia',
              'PPT: Permiso de Protección Temporal',
              'RC: Registro civil de nacimiento',
              'TMF: Tarjeta de movilidad fronteriza',
              'Visa',
            ]"
            v-model="user.document_type"
          >
          </v-select>
          <v-text-field
            label="Número de identificación"
            :type="
              user.document_type == 'TI: Tarjeta de Identidad' ||
              user.document_type == 'CC: Cédula de Ciudadanía'
                ? 'number'
                : 'text'
            "
            :disabled="user.document_type == ''"
            @keydown.up.prevent
            @keydown.down.prevent
            hide-spin-buttons
            v-model="user.document_number"
          />
          <v-text-field label="Nombre:" type="text" v-model="user.first_name" />
          <v-text-field
            label="Apellido:"
            type="text"
            v-model="user.last_name"
          />

          <v-select
            label="Grado:"
            type="number"
            :disabled="user.document_number == ''"
            :items="['10', '11']"
            v-model="user.grade"
          />
          <v-text-field
            label="Número de celular:"
            type="number"
            @copy.prevent
            hide-spin-buttons
            :disabled="user.grade == ''"
            :error="phoneHasWarning"
            :error-messages="phoneWarningMessages"
            @keydown.up.prevent
            @keydown.down.prevent
            v-model="user.phone"
          />
          <v-text-field
            label="Verifica número de celular:"
            type="number"
            :error="phoneValidatorHasWarning"
            :error-messages="phoneValidatorWarningMessages"
            hide-spin-buttons
            :disabled="user.grade == ''"
            v-model="phoneValidator"
            @keydown.up.prevent
            @keydown.down.prevent
          />
          <div class="text-left">
            <v-btn
              color="primary"
              class="mr-auto"
              rounded
              :loading="sendingInformation"
              @click="currentScreen = 'verify-twice'"
              :disabled="
                user.phone == '' ||
                user.phone.length != 10 ||
                user.phone != phoneValidator ||
                user.first_name == '' ||
                user.grade == '' ||
                user.document_type == ''
              "
              >Continuar</v-btn
            >
            <div class="text-label mt-2">
              El número de celular debe contener 10 dígitos.
            </div>
          </div>
        </div>
        <div
          v-else-if="currentScreen === 'contact-info'"
          key="contact-info"
          class="screen"
        >
          <h3>Favor rellena los siguientes datos:</h3>

          <v-text-field
            label="Número de celular"
            type="number"
            hide-spin-buttons
            prefix="3"
            :error="phoneHasWarning"
            :error-messages="phoneWarningMessages"
            @keydown.up.prevent
            @keydown.down.prevent
            v-model="user.phone"
          />
          <v-text-field
            label="Verifica número de celular"
            @copy.prevent
            type="number"
            :error="phoneValidatorHasWarning"
            :error-messages="phoneValidatorWarningMessages"
            hide-spin-buttons
            prefix="3"
            v-model="phoneValidator"
            @keydown.up.prevent
            @keydown.down.prevent
          />
          <div class="text-left">
            <v-btn
              color="primary"
              class="mr-auto"
              rounded
              :loading="sendingInformation"
              @click="currentScreen = 'verify-twice'"
              :disabled="
                user.phone == '' ||
                user.phone.length != 10 ||
                user.phone != phoneValidator
              "
              >Continuar</v-btn
            >
            <div class="text-label mt-2">
              El número de celular debe contener 10 dígitos.
            </div>
          </div>
        </div>
        <div
          v-else-if="currentScreen === 'verify-twice'"
          key="verify-twice"
          class="screen"
        >
          <h3 class="mt-2">¿Son estos datos correctos?</h3>
          <v-container class="mt-3 rounded" rounded>
            <v-row>
              <v-col cols="6" class="col-border">
                Número de identificación
              </v-col>
              <v-col cols="6" class="col-border">
                {{ user.document_number }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="col-border"> Nombre </v-col>
              <v-col cols="6" class="col-border">
                {{ user.first_name }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="col-border"> Apellido </v-col>
              <v-col cols="6" class="col-border">
                {{ user.last_name }}
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6" class="col-border"> Grado </v-col>
              <v-col cols="6" class="col-border">
                {{ user.grade }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-border" cols="6"> Número de celular </v-col>
              <v-col class="col-border" cols="6">
                {{ user.phone }}
              </v-col>
            </v-row>
          </v-container>
          <div class="d-flex mt-4">
            <v-btn
              color="primary"
              class="ms-auto"
              rounded
              :loading="sendingInformation"
              :disabled="sendingInformation"
              @click="sendInformation"
              >Sí, continuar</v-btn
            >
            <v-btn
              class="ms-2 me-auto"
              color="primary"
              rounded
              @click="currentScreen = 'start'"
              >No, volver a ingresar</v-btn
            >
          </div>
        </div>
        <div v-else-if="currentScreen === 'thanks'" key="thanks" class="screen">
          <h3 class="mt-2">
            <div v-if="alreadyValidated">Ya has completado esta encuesta.</div>
            <div>¡Muchas gracias por tu participación!</div>
          </h3>
        </div>
      </transition>
    </v-card>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "Form",
  data() {
    return {
      currentScreen: "prestart",
      phoneValidator: "",
      alreadyValidated: false,
      documentNumberValidator: "",
      sendingInformation: false,
      user: {
        phone: "",
        document_type: "",
        document_number: "",
        first_name: "",
        last_name: "",
        grade: "",
        code: "",
      },
    };
  },
  mounted() {
    document.title = "Formulario";
  },
  methods: {
    ...mapActions({
      request: "utils/request",
    }),
    sendInformation() {
      this.sendingInformation = true;
      this.request({
        endpoint: `form/${this.user.document_number}/`,
        type: "get",
      })
        .then(() => {
          // exists, so patch
          this.request({
            endpoint: `form/${this.user.document_number}/`,
            type: "patch",
            body: this.user,
          }).then(() => {
            this.sendingInformation = false;
            this.currentScreen = "thanks";
          });
        })
        .catch(() => {
          // doesn't exist, so create
          this.request({
            endpoint: "form/",
            type: "post",
            body: this.user,
          }).then(() => {
            this.sendingInformation = false;
            this.currentScreen = "thanks";
          });
        });
    },
    resetUserInformation() {
      let documentNumber = this.user.document_number;
      this.user = {
        phone: "",
        document_type: "",
        document_number: documentNumber,
        first_name: "",
        last_name: "",
        grade: "",
        code: "",
      };
    },
    getUserInformation() {
      // 123456789
      this.request({
        endpoint: `form/${this.user.document_number}/`,
        type: "get",
      })
        .then((response) => {
          this.user = response.data;
          if (this.user.phone == "nan") {
            this.user.phone = "";
          }
          if (this.user.phone != "") {
            this.alreadyValidated = true;
            this.currentScreen = "thanks";
          } else {
            this.currentScreen = "verify-info";
          }
        })
        .catch(() => {
          this.currentScreen = "all-info";
        });
    },
  },
  computed: {
    phoneHasWarning() {
      // Add your custom warning condition here
      return this.user.phone.length > 0 && this.user.phone.length != 10;
    },
    documentHasWarning() {
      // Add your custom warning condition here
      return (
        this.user.document_number.length > 0 &&
        this.documentNumberValidator.length > 0 &&
        this.user.document_number != this.documentNumberValidator
      );
    },
    phoneValidatorHasWarning() {
      return this.phoneValidator.length > 0 && this.phoneValidator.length != 10;
    },
    phoneValidatorIsDifferent() {
      return (
        this.phoneValidator.length > 0 && this.phoneValidator != this.user.phone
      );
    },
    phoneWarningMessages() {
      if (this.phoneHasWarning) {
        return ["El número de celular debe tener 10 dígitos."];
      }
      return [];
    },
    documentWarningMessages() {
      if (this.documentHasWarning) {
        return ["El número de identificación no coincide."];
      }
      return [];
    },
    phoneValidatorWarningMessages() {
      if (this.phoneValidatorHasWarning) {
        return ["El número de celular debe tener 10 dígitos."];
      }
      if (this.phoneValidatorIsDifferent) {
        return ["El número de teléfono no coincide."];
      }
      return [];
    },
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.screen {
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 20px;
  max-height: 70vh;
  overflow-y: auto;
  padding-top: 0px !important;
}
.main-card {
  min-width: 700px;
  max-width: 70vw;
  min-height: 90vh;
}
@media (max-width: 740px) {
  .main-card {
    min-width: 0px;
    max-width: 90vw;
  }
}
.col-border {
  border: 1px solid gray;
}
</style>
