var render = function render(){var _vm=this,_c=_vm._self._c;return _c('generic-dialog',{attrs:{"width":"500"},on:{"save":_vm.saveChatbot},model:{value:(_vm.computedValue),callback:function ($$v) {_vm.computedValue=$$v},expression:"computedValue"}},[(_vm.internalChatbot)?_c('div',[_c('v-text-field',{attrs:{"readonly":"","disabled":"","label":"Nombre interno"},model:{value:(_vm.internalChatbot.name),callback:function ($$v) {_vm.$set(_vm.internalChatbot, "name", $$v)},expression:"internalChatbot.name"}}),_c('v-text-field',{attrs:{"label":"Messaging service sid"},model:{value:(_vm.internalChatbot.start_message_service),callback:function ($$v) {_vm.$set(_vm.internalChatbot, "start_message_service", $$v)},expression:"internalChatbot.start_message_service"}}),_c('v-select',{attrs:{"items":['ES', 'EN'],"label":"Idioma"},model:{value:(_vm.chatbot.language),callback:function ($$v) {_vm.$set(_vm.chatbot, "language", $$v)},expression:"chatbot.language"}}),_c('v-text-field',{attrs:{"label":"Nombre visible"},model:{value:(_vm.internalChatbot.label),callback:function ($$v) {_vm.$set(_vm.internalChatbot, "label", $$v)},expression:"internalChatbot.label"}}),_c('div',{staticClass:"text-left"},[_c('div',{staticClass:"text-body-2 mb-2"},[_vm._v("Color")]),_c('v-btn-toggle',{model:{value:(_vm.internalChatbot.theme),callback:function ($$v) {_vm.$set(_vm.internalChatbot, "theme", $$v)},expression:"internalChatbot.theme"}},[_vm._l(([
            'primary',
            'secondary',
            'tertiary',
            'quaternary',
          ]),function(i,index){return _c('v-btn',{key:index,attrs:{"icon":"","value":i}},[_c('v-icon',{attrs:{"color":_vm.themes[i]}},[_vm._v("mdi-circle")])],1)}),_c('tooltip',{staticClass:"mt-auto mb-auto",attrs:{"width":600}},[_c('chatbot',{attrs:{"externalTheme":_vm.internalChatbot.theme,"hasCloseButton":false,"width":550,"chats":[
              { source: 'Bot', text: '¡Hola! ¿Quieres hablar conmigo?' },
              { source: 'Me', text: '¡Claro!' },
              { source: 'Bot', text: 'Te informo que...' },
            ],"showForm":false}})],1)],2)],1),_c('div',{staticClass:"text-left mt-2"},[_c('div',{staticClass:"text-body-2"},[_vm._v("Avatar")]),_c('image-file-input',{attrs:{"imageSource":_vm.internalChatbot.image},on:{"setImage":_vm.setImage}})],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }