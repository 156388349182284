import services from "@/services";
import router from "@/router";
const getDefaultState = () => ({
  toastNotificationMessage: "",
  toastNotificationState: true,
  user: null,
  uuid: null,
  accessToken: null,
  loggedIn: false,
  isSuperAdmin: false,
  role: "",
  selectedView: "Edition",
});
const state = getDefaultState();
const getters = {
  toastNotificationMessage: ({ toastNotificationMessage }) =>
    toastNotificationMessage,
  toastNotificationState: ({ toastNotificationState }) =>
    toastNotificationState,
  user: ({ user }) => user,
  accessToken: ({ accessToken }) => accessToken,
  loggedIn: ({ loggedIn }) => loggedIn,
  isSuperAdmin: ({ isSuperAdmin }) => isSuperAdmin,
  role: ({ role }) => role,
  selectedView: ({ selectedView }) => selectedView,
  uuid: ({ uuid }) => uuid,
};

const mutations = {
  setToastNotification(state, { toastState, toastMessage }) {
    state.toastNotificationState = toastState;
    state.toastNotificationMessage = toastMessage;
    setTimeout(function () {
      state.toastNotificationMessage = "";
    }, 2000);
  },
  setUUID(state, { uuid }) {
    state.uuid = uuid;
  },
  setUser(state, { user, accessToken, loggedIn, isSuperAdmin }) {
    state.user = user;
    state.accessToken = accessToken;
    state.loggedIn = loggedIn;
    state.isSuperAdmin = isSuperAdmin;
  },
  setRole(state, role) {
    state.role = role;
  },
  setSelectedView(state, selectedView) {
    state.selectedView = selectedView;
  },
};
const actions = {
  setUUID({ commit }, { uuid }) {
    commit("setUUID", { uuid });
  },
  // eslint-disable-next-line
  reset({ state }, {}) {
    Object.assign(state, getDefaultState());
  },

  setToastNotification({ commit }, data) {
    commit("setToastNotification", data);
  },
  setRole({ commit }, role) {
    commit("setRole", role);
  },
  setSelectedView({ commit }, selectedView) {
    commit("setSelectedView", selectedView);
  },
  // eslint-disable-next-line
  login({ dispatch, rootGetters }, { username, password, captcha, stay }) {
    return dispatch("request", {
      errorMessage: "Error logging in",
      type: "post",
      endpoint: `login/`,
      body: {
        username: username,
        password: password,
        captcha: captcha,
      },
    })
      .then((response) => {
        if (response) {
          // set user
          return dispatch("setUser", {
            user: username,
            accessToken: response.data.access,
            loggedIn: true,
            isSuperAdmin: response.data.is_superuser,
          }).then(() => {
            dispatch("chatbot/getOrganizations", {}, { root: true }).then(
              () => {
                if (stay) {
                  return;
                } else if (rootGetters["chatbot/organizations"].length == 1) {
                  dispatch(
                    "chatbot/setOrganization",
                    { organization: rootGetters["chatbot/organizations"][0] },
                    { root: true }
                  ).then(() => {
                    if (stay) {
                      return;
                    } else {
                      dispatch("chatbot/getChatbots", {}, { root: true }).then(
                        () => {
                          if (stay) {
                            return;
                          } else {
                            router.push({ name: "main" });
                          }
                        }
                      );
                    }
                  });
                } else if (rootGetters["chatbot/organizations"].length > 1) {
                  router.push({ name: "organization" });
                } else {
                  dispatch("chatbot/getChatbots", {}, { root: true }).then(
                    () => {
                      if (stay) {
                        return;
                      } else {
                        router.push({ name: "main" });
                      }
                    }
                  );
                }
              }
            );
          });
        }
      })
      .catch((error) => {
        throw error;
      });
  },
  // eslint-disable-next-line
  logout({ dispatch }) {
    dispatch("chatbot/reset", {}, { root: true });
    dispatch("chatbot/reset", {}, { root: true });
    dispatch("reset", {});
    for (const key in window.sessionStorage) {
      window.sessionStorage.setItem(key, null);
    }
    router.push({ name: "login" });
  },
  setUser({ commit }, { user, accessToken, loggedIn, isSuperAdmin }) {
    commit("setUser", { user, accessToken, loggedIn, isSuperAdmin });
  },
  // eslint-disable-next-line
  interact({}, { sender, message }) {
    return services.interact({ sender, message }).then((response) => {
      return response;
    });
  },
  // eslint-disable-next-line
  request(
    { commit },
    { endpoint, type, body, successMessage, errorMessage, params }
  ) {
    return services
      .request({ endpoint, type, body, params })
      .then((response) => {
        if (successMessage) {
          commit("setToastNotification", {
            toastState: true,
            toastMessage: successMessage,
          });
        }
        return response;
      })
      .catch((error) => {
        if (errorMessage) {
          commit("setToastNotification", {
            toastState: false,
            toastMessage: errorMessage,
          });
        }
        throw error;
      });
  },
  // eslint-disable-next-line
  asyncFetch({}, { url, file }) {
    let filename = `${file}.csv`;
    return services
      .downloadFile({ url, filename })
      .then(() => {
        return 0;
      })
      .catch((error) => {
        throw error;
      });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
