<template>
  <div>
    <generic-dialog
      title="Asignar rol"
      @save="saveRole"
      v-model="editDialog"
      width="500"
    >
      <v-text-field label="Usuario" v-model="editedRole.user"></v-text-field>
      <v-select
        label="Chatbot"
        v-model="editedRole.chatbot"
        :items="sortedChatbots"
        item-text="name"
        item-value="id"
      ></v-select>

      <v-select
        v-model="editedRole.role"
        label="Rol"
        :items="['reader', 'writer', 'admin']"
      ></v-select>
    </generic-dialog>
    <generic-dialog v-model="deleteDialog" width="300">
      <div>¿Estás seguro de eliminar este rol?</div>

      <div class="d-flex mt-4">
        <v-btn class="me-auto" @click="deleteRole">Sí, eliminar</v-btn>
        <v-btn @click="deleteDialog = false" class="ms-auto">No</v-btn>
      </div>
    </generic-dialog>
    <generic-dialog
      v-model="computedValue"
      card-style="height:440px"
      width="700"
      height="400"
    >
      <div v-if="loaded">
        <v-container v-if="loaded" styte="max-height:300px;overflow-y: auto">
          <v-row class="text-left">
            <v-col cols="4">Usuario</v-col>
            <v-col cols="3">Chatbot</v-col>
            <v-col cols="3">Rol</v-col>
            <v-col cols="2">Acciones</v-col>
          </v-row>
          <v-row style="max-height: 280px; overflow-y: auto">
            <v-col>
              <v-row
                v-for="role in roles"
                :key="role.id"
                class="text-left text-body"
              >
                <v-col cols="4">{{ role.user.identifier }}</v-col>
                <v-col cols="3">{{ role.chatbot.name }}</v-col>
                <v-col cols="3">{{ role.role }}</v-col>
                <v-col cols="2">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="setEditDialog(role)"
                        icon
                        class="ms-auto"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="
                          deleteDialog = true;
                          setEditedRole(role);
                        "
                        icon
                        class="ms-auto"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip></v-col
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>

        <v-btn class="mt-2" @click="openCreateDialog"
          >Agregar rol<v-icon class="ms-2">mdi-plus</v-icon></v-btn
        >
      </div>
      <div v-else class="d-flex mh-100" style="height: 90%">
        <v-progress-circular
          indeterminate
          color="primary"
          :size="50"
          :width="15"
          class="mt-auto mb-auto ms-auto me-auto"
        ></v-progress-circular>
      </div>
    </generic-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "UserRole",
  data() {
    return {
      sortedChatbots: [],
      editDialog: false,
      deleteDialog: false,
      editedRole: {
        chatbot: "",
        id: null,
        user: "",
        role: "",
      },
      roles: [],
      loaded: false,
    };
  },
  mounted() {
    this.sortedChatbots = this.chatbots;
    this.sortedChatbots.sort((a, b) =>
      a.label.toLowerCase() > b.label.toLowerCase()
        ? 1
        : b.label.toLowerCase() > a.label.toLowerCase()
        ? -1
        : 0
    );

    this.request({
      type: "get",
      endpoint: `user_role/`,
    }).then((response) => {
      this.roles = response.data;
      this.loaded = true;
    });
  },
  methods: {
    deleteRole() {
      this.request({
        type: "delete",
        endpoint: `user_role/${this.editedRole.id}/`,
        successMessage: "User Role deleted successfully.",
        errorMessage: "Could not delete user role.",
      }).then(() => {
        this.deleteDialog = false;
        this.editDialog = false;

        this.roles = this.roles.filter((r) => r.id != this.editedRole.id);
        this.setDefaultEditRole();
      });
    },
    setDefaultEditRole() {
      this.editedRole = {
        chatbot: "",
        id: null,
        user: "",
        role: "",
      };
    },
    openCreateDialog() {
      this.setDefaultEditRole();
      this.editDialog = true;
    },
    saveRole() {
      let type = "post";
      let endpoint = "user_role/";
      let errorMessage = "Error creating role";
      let successMessage = "Role created successfully";
      let body = this.editedRole;
      if (this.editedRole.id !== null) {
        // patch
        type = "patch";
        endpoint = `user_role/${this.editedRole.id}/`;
        errorMessage = "Error updating role";
        successMessage = "Role updated successfully";
      } else {
        // create
        delete body["id"];
      }
      this.request({
        errorMessage,
        successMessage,
        type,
        endpoint,
        body,
      }).then((response) => {
        let data = response.data;
        let role = this.roles.find((r) => r.id == data.id);
        if (role) {
          role.chatbot = data.chatbot;
          role.id = data.id;
          role.user = data.user;
          role.role = data.role;
        } else {
          this.roles.push(data);
        }

        this.editedRole = {
          chatbot: data.chatbot.id,
          id: data.id,
          user: data.user.identifier,
          role: data.role,
        };
      });
    },
    unsetEditDialog() {
      this.setDefaultEditRole();
      this.editDialog = false;
    },
    setEditedRole(role) {
      this.editedRole = {
        chatbot: role.chatbot.id,
        id: role.id,
        user: role.user.identifier,
        role: role.role,
      };
    },
    setEditDialog(role) {
      this.setEditedRole(role);
      this.editDialog = true;
    },
    ...mapActions({
      request: "utils/request",
    }),
  },
  props: {
    value: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({
      chatbots: "chatbot/chatbots",
    }),
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>
<style>
.v-simple-table--class-name {
  max-height: 300px; /* Adjust the max height as needed */
  overflow-y: auto;
}
</style>
