import axios from "axios";
import store from "../store";

// create base api
const api = axios.create({
  baseURL: process.env.VUE_APP_ENDPOINT,
});
// access store
const brainApi = axios.create({
  baseURL: process.env.VUE_APP_BRAIN_ENDPOINT,
});

let extraHeaders = { "Content-Type": "application/json" };
if (process.env.VUE_APP_ENDPOINT.includes("ngrok")) {
  extraHeaders["ngrok-skip-browser-warning"] = "69420";
}

let extraHeadersBrain = { "Content-Type": "application/json" };
if (process.env.VUE_APP_BRAIN_ENDPOINT.includes("ngrok")) {
  extraHeadersBrain["ngrok-skip-browser-warning"] = "69420";
}

// set header from store on every request.
api.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    ...extraHeaders,
  };
  return config;
});
// set header from store on every request.
brainApi.interceptors.request.use((config) => {
  const token = store.getters["utils/accessToken"];
  if (token) {
    config.headers = {
      Authorization: token,
      ...config.headers,
      ...extraHeadersBrain,
    };
  } else {
    config.headers = {
      ...config.headers,
      ...extraHeadersBrain,
    };
  }

  return config;
});

export { api, brainApi };
