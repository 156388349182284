<template>
	<div style="background: lightblue; margin: 4rem">
		<h3 class="text-start ml-4 mt-10">{{ name }}</h3>
		<CustomDialog v-bind="items" v-model="selected"> </CustomDialog>
		<div v-for="(element, index) in elements" :key="index">
			{{ element.name }}
			<v-btn icon class="ms-auto" @click="selected = true"
				><v-icon>mdi-pencil</v-icon></v-btn
			>
		</div>
		<v-btn class="mt-2 mb-2" @click="selected = true"
			>Agregar <v-icon>mdi-plus</v-icon></v-btn
		>
	</div>
</template>

<script>
	export default {
		name: 'CustomList',
		components: {},
		props: {
			name: {
				type: String,
				default: '',
			},
			elements: {
				type: Array,
			},
			items: {
				type: Object,
			},
		},
		data() {
			return {
				selected: false,
			};
		},
		mounted() {},
	};
</script>
