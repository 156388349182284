import Vue from "vue";
import VueRouter from "vue-router";
import Main from "../views/Main.vue";
import Login from "../views/Login.vue";
import ChatbotTest from "../views/ChatbotTest.vue";
import DownloadFile from "../views/DownloadFile.vue";
import Form from "../views/Form.vue";
import Organization from "../views/Organization.vue";

Vue.use(VueRouter);
const routes = [
  {
    path: "/organization",
    name: "organization",
    component: Organization,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/",
    name: "main",
    component: Main,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/form",
    name: "form",
    component: Form,
  },
  {
    path: "/download/:uuid/",
    name: "download",
    component: DownloadFile,
  },
  {
    path: "/chatbot/:chatbotName/",
    name: "chatbot",
    component: ChatbotTest,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
