<template>
  <div>
    <!-- single feature -->
    <feature
      v-model="editFeature"
      :feature="feature"
      v-if="feature"
      @pushFeature="pushFeature"
      @removeFeature="removeFeature"
      @updateFeature="updateFeature"
    />
    <!-- features -->
    <generic-dialog
      :title="
        role == 'admin'
          ? 'Edición de características'
          : 'Lista de características'
      "
      title-class="h2"
      v-model="computedValue"
      max-width="500"
      v-if="features"
    >
      <div style="height: 400px !important; overflow-y: scroll">
        <div
          v-for="(feature, index) in features"
          :key="index"
          class="d-flex mt-2 mb-2 ml-2 pl-2 pr-2 pt-1 pb-1 rounded border"
        >
          <div class="mt-auto mb-auto">
            {{ feature.name }}
          </div>
          <v-btn
            icon
            class="ms-auto"
            @click="selectFeature(feature)"
            v-if="role == 'admin'"
            ><v-icon>mdi-pencil</v-icon></v-btn
          >
        </div>
      </div>
      <v-btn v-if="role == 'admin'" @click="addFeature"
        >Agregar característica<v-icon class="ms-2">mdi-plus</v-icon></v-btn
      >
    </generic-dialog>
  </div>
</template>
<script>
import Feature from "@/components/Feature.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Features",
  components: { Feature },
  data() {
    return {
      features: [],
      feature: null,
      editFeature: false,
    };
  },
  mounted() {
    this.request({
      errorMessage: "Error opening feature",
      type: "get",
      endpoint: "feature/",
    }).then((response) => {
      this.features = response.data;
    });
  },
  props: {
    value: {
      required: true,
      type: Boolean,
    },
  },
  methods: {
    ...mapActions({
      request: "utils/request",
    }),
    addFeature() {
      this.feature = { name: "" };
      this.editFeature = true;
    },
    selectFeature(feature) {
      this.feature = feature;
      this.editFeature = true;
    },
    pushFeature(feature) {
      this.features.push(feature);
    },
    removeFeature(feature) {
      this.features = this.features.filter((f) => f.id != feature.id);
    },
    updateFeature(feature) {
      this.features.find((f) => f.id == feature.id).name = feature.name;
    },
  },
  watch: {
    editFeature(newVal) {
      if (newVal == false) {
        this.feature = null;
      }
    },
  },
  computed: {
    ...mapGetters({
      role: "chatbot/role",
    }),
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>
