<template>
  <div>
    <chatbot-resource
      v-if="showResource"
      :resource="currentResource"
      @close="showResource = false"
      v-model="showResource"
      @setEmptyResource="setEmptyResource"
      @deleteResource="deleteResource"
      @reloadResource="reloadResource"
      @addResource="addResource"
    />
    <generic-dialog title="Recursos" width="600" v-model="computedValue">
      <div class="d-flex flex-wrap" style="gap: 12px">
        <v-card
          @click="setResource(r)"
          class="pa-4 text-left d-flex flex-column"
          v-for="r in resources"
          :key="r"
          width="120px"
          height="120px"
        >
          <div class="text-caption">{{ r.name }}</div>
          <br />

          <v-icon class="mt-auto me-auto"
            >mdi-{{
              {
                PDF: 'file-pdf-box',
                web: 'web',
                'Excel / CSV': 'file-excel-box',
              }[r.type]
            }}</v-icon
          >
        </v-card>
        <v-card
          @click="openEmptyResource"
          width="120px"
          height="120px"
          class="pa-4 text-left d-flex flex-column"
          ><div class="text-caption">Añadir recurso</div>
          <br /><v-icon class="mt-auto me-auto">mdi-plus</v-icon></v-card
        >
      </div>
    </generic-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import ChatbotResource from '@/components/ChatbotResource';
export default {
  name: 'ChatbotResourceGroup',
  mounted() {
    this.request({
      type: 'get',
      endpoint: `resource/`,
      params: {
        params: {
          chatbot__id: this.chatbot.id,
        },
      },
    }).then((response) => {
      this.resources = response.data;
    });
  },
  data() {
    return {
      resources: [],
      currentResource: null,
      showResource: false,
    };
  },
  components: {
    ChatbotResource,
  },
  methods: {
    ...mapActions({
      request: 'utils/request',
      saveResource: 'chatbot/saveResource',
    }),
    reloadResource() {
      this.resources = this.chatbot.resources;
    },
    addResource(resource) {
      this.resources.push(resource);
    },
    deleteResource(resourceId) {
      this.resources = this.resources.filter((r) => r.id != resourceId);
    },
    openEmptyResource() {
      this.setEmptyResource();
      this.showResource = true;
    },
    setResource(resource) {
      this.currentResource = resource;
      this.showResource = true;
    },
    setEmptyResource() {
      this.currentResource = {
        name: '',
        type: 'PDF',
        file: '',
        content: '',
        description: '',
      };
      this.showResource = false;
    },
  },
  props: {
    value: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({
      chatbot: 'chatbot/chatbot',
    }),
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
};
</script>
