import { api, brainApi } from "../api";

export default {
  interact({ sender, message }) {
    return api.post("", { sender, message });
  },
  request({ endpoint, type, body, params }) {
    if (type == "post") {
      return brainApi.post(endpoint, body);
    } else if (type == "get") {
      return brainApi.get(endpoint, params);
    } else if (type == "patch") {
      return brainApi.patch(endpoint, body);
    } else if (type == "delete") {
      return brainApi.delete(endpoint);
    }
  },
  downloadFile({ url }) {
    return brainApi({
      url,
      method: "GET",
      responseType: "blob", // Important
    })
      .then((response) => {
        let s = response.headers["content-disposition"];

        let filename = s.match(/filename="([^"]+)"/)[1];
        // Create a new Blob object using the response data of the file
        const file = new Blob([response.data], {
          type: "application/octet-stream",
        });

        // Create a link element, use it to download the Blob, and remove it
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = fileURL;
        link.setAttribute("download", filename); // Set the file name
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
        URL.revokeObjectURL(fileURL); // Free up memory (optional)
        return;
      })
      .catch((error) => {
        throw error;
      });
  },
};
