//import services from "@/services";
//import router from "@/router";
import Vue from 'vue';

function computeNodeAndEdges(chatbot) {
  chatbot.edges = [];

  // process nodes to add color, label and shape
  chatbot.nodes = chatbot.nodes.map((node) => {
    let processedNode = node;
    //const cutText = (text, maxLength) =>
    //	text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
    processedNode.label = processedNode.name;
    processedNode.color = {
      background: getColor(node),
      border: getColor(node),
    };
    processedNode.shape = 'ellipse';
    // add fulltext for search

    // add any possible edges
    if (processedNode.transitions) {
      processedNode.transitions.forEach((t) => {
        chatbot.edges.push(t);
      });
    }
    return processedNode;
  });
  return chatbot;
}
const getDefaultState = () => ({
  chatbot: null,
  organization: null,
  organizations: [],
  chatbots: [],
  features: [],
  options: {
    interaction: {
      selectConnectedEdges: false,
      hover: true,
      dragView: true,
      dragNodes: true,
    },
    clickToUse: false,
    nodes: {
      borderWidth: 4,
      physics: false,
    },
    edges: {
      color: 'lightgray',
    },
    physics: {
      enabled: true, // Enable physics simulation
      barnesHut: {
        gravitationalConstant: -2000,
        centralGravity: 0.1,
        springLength: 100,
        springConstant: 0.04,
        damping: 0.09,
        avoidOverlap: 1,
      },
      stabilization: {
        enabled: true,
        iterations: 1000,
        updateInterval: 100,
        onlyDynamicEdges: false,
        fit: true,
      },
    },
    /*physics: {
      forceAtlas2Based: {
        gravitationalConstant: -26,
        centralGravity: 0.005,
        springLength: 230,
        springConstant: 0.18,
        avoidOverlap: 1.5,
      },
      maxVelocity: 1460,
      solver: "forceAtlas2Based",
      timestep: 0,
      stabilization: {
        enabled: true,
        iterations: 100,
        updateInterval: 0,
      },
    },*/
    /*layout: {
      hierarchical: {
        enabled: true,
        direction: "LR",
        sortMethod: "directed",
        levelSeparation: 100,
        nodeSpacing: 100,
      },
    },*/
  },
});
const state = getDefaultState();
const getters = {
  organizations: ({ organizations }) => organizations,
  organization: ({ organization }) => organization,
  chatbot: ({ chatbots, chatbot }) => chatbots[chatbot],
  chatbots: ({ chatbots }) => chatbots,
  options: ({ options }) => options,
  role: ({ chatbots, chatbot }) => chatbots[chatbot].role,
};
const mutations = {
  // update chatbot
  updateChatbotBasic(state, { chatbot }) {
    let oldChatbot = state.chatbots.find((cb) => cb.id == chatbot.id);
    updateKeys(oldChatbot, chatbot);
  },
  // set Chatbot Updated
  setChatbotUpdated(state, { chatbotId, updated }) {
    state.chatbots.find((cb) => cb.id == chatbotId).updated = updated;
  },
  // chatbot testing
  changeNodeColor(state, { node, color }) {
    let nodeObject = state.chatbots[state.chatbot].nodes.find(
      (n) => n.id == node
    );
    let colorObject = { ...nodeObject.color };
    colorObject.background = color;
    delete nodeObject['color'];
    nodeObject.color = { ...colorObject };
  },
  setDrag(state, { drag }) {
    state.options.interaction.dragView = drag;
    state.options.interaction.zoomable = drag;
  },
  recolor(state) {
    state.chatbots[state.chatbot].nodes.forEach((n) => {
      n.color = {
        background: getColor(n),
        border: getColor(n),
      };
    });
  },
  // chatbots
  setChatbot(state, chatbot) {
    state.chatbot = chatbot;
  },
  updateChatbot(state, chatbot) {
    const indexToReplace = state.chatbots.findIndex(
      (obj) => obj.id === chatbot.id
    );

    if (indexToReplace !== -1) {
      // Replace the old object with the new one. In this case,
      // recompute node and edges
      chatbot = computeNodeAndEdges(chatbot);
      Vue.set(state.chatbots, indexToReplace, chatbot);
      // state.chatbots[indexToReplace] = chatbot;
    }
  },
  setOrganizations(state, organizations) {
    state.organizations = organizations;
  },
  setOrganization(state, organization) {
    state.organization = organization;
  },
  setChatbots(state, chatbots) {
    state.chatbots = chatbots;
  },
  /*
  -------------------- node --------------------
  */
  addNode(state, node) {
    // add base properties
    node.label = node.name;
    node.color = {
      background: getColor(node),
      border: getColor(node),
    };
    node.shape = 'ellipse';
    // add node to current chatbot
    state.chatbots[state.chatbot].nodes.push(node);
  },
  addCommentToNode(state, { nodeId, comment }) {
    let chatbotNode = state.chatbots[state.chatbot].nodes.find(
      (n) => n.id == nodeId
    );
    if (!('comments' in chatbotNode)) {
      chatbotNode.comments = [];
    }
    chatbotNode.comments.push(comment);
  },
  deleteCommentFromNode(state, { nodeId, comment }) {
    let chatbotNode = state.chatbots[state.chatbot].nodes.find(
      (n) => n.id == nodeId
    );
    if (!('comments' in chatbotNode)) {
      chatbotNode.comments = [];
    }
    chatbotNode.comments = chatbotNode.comments.filter(
      (c) => c.id != comment.id
    );
  },
  addInteractionsToNode(
    state,
    { node, interactions, uniqueInteractions, size, maxInteractions }
  ) {
    let chatbotNode = state.chatbots[state.chatbot].nodes.find(
      (n) => n.id == node.id
    );
    chatbotNode.count = interactions;
    let percentage = Math.round((100 * uniqueInteractions) / maxInteractions);
    if (size >= 0 && size <= 1) {
      chatbotNode.label = `${chatbotNode.name}\n${uniqueInteractions} únicos (${percentage}%)\n${interactions} totales`;
      chatbotNode.font = {
        size: 5,
      };
      chatbotNode.widthConstraint = {
        minimum: 25 + size * 25,
        maximum: 25 + size * 25,
      };
      chatbotNode.heightConstraint = {
        minimum: 25 + size * 25,
        maximum: 25 + size * 25,
      };
    }
  },
  addInteractionsToEdges(state, { from, to, interactions, maxInteractions }) {
    let chatbotEdges = state.chatbots[state.chatbot].edges
      .filter((e) => e.source == from)
      .filter((e) => e.destination == to);

    chatbotEdges.forEach((e) => {
      e.count = interactions;
      e.maxCount = maxInteractions;
    });

    /*let percentage = Math.round((100 * interactions) / maxInteractions);
    if (size >= 0 && size <= 1) {
      chatbotNode.label = `${chatbotNode.name}\n${interactions} (${percentage}%)`;
      chatbotNode.font = {
        size: 6,
      };
      chatbotNode.widthConstraint = {
        minimum: 25 + size * 25,
        maximum: 25 + size * 25,
      };
      chatbotNode.heightConstraint = {
        minimum: 25 + size * 25,
        maximum: 25 + size * 25,
      };
    }*/
  },
  removeInteractionsFromNode(state, node) {
    let chatbotNode = state.chatbots[state.chatbot].nodes.find(
      (n) => n.id == node.id
    );
    chatbotNode.count = 0;
    ['font', 'widthConstraint', 'heightConstraint', 'font'].forEach((key) => {
      if (key in chatbotNode) {
        delete chatbotNode[key];
      }
    });
    //const cutText = (text, maxLength) =>
    //	text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
    chatbotNode.label = chatbotNode.name;
    chatbotNode.font = { size: 14 };
  },
  saveNode(state, node) {
    let oldNode = state.chatbots[state.chatbot].nodes.find(
      (n) => n.id == node.id
    );
    oldNode = updateKeys(oldNode, node);
    oldNode.label = node.name;
  },
  deleteNode(state, node) {
    // delete node from nodes
    state.chatbots[state.chatbot].nodes = state.chatbots[
      state.chatbot
    ].nodes.filter((n) => {
      return n.id != node;
    });
    // delete node from edges
    state.chatbots[state.chatbot].edges = state.chatbots[
      state.chatbot
    ].edges.filter((n) => {
      if (n.from == node) {
        return false;
      } else if (n.to == node) {
        return false;
      }
      return true;
    });
  },
  /*
  ------------------------- resource ------------------------
  */
  addResource(state, resource) {
    state.chatbots[state.chatbot].resources.push(resource);
  },
  saveResource(state, resource) {
    let oldResource = state.chatbots[state.chatbot].resources.find(
      (r) => r.id == resource.id
    );
    updateKeys(oldResource, resource);
  },
  deleteResource(state, resource) {
    // delete resource from resources
    state.chatbots[state.chatbot].resources = state.chatbots[
      state.chatbot
    ].resources.filter((r) => {
      return r.id != resource;
    });
  },

  /*
  -------------------- transition weight --------------------
  */
  addTransitionWeight(state, transitionWeight) {
    // add on node transitions
    let node = state.chatbots[state.chatbot].nodes.find(
      (n) => n.id == transitionWeight.source
    );
    node.transitions.push(transitionWeight);
    // add on edges
    state.chatbots[state.chatbot].edges.push(transitionWeight);
  },
  saveTransitionWeight(state, transitionWeight) {
    // save on node transitions
    let node = state.chatbots[state.chatbot].nodes.find(
      (n) => n.id == transitionWeight.source
    );
    let oldTransitionWeight = node.transitions.find(
      (t) => t.id == transitionWeight.id
    );
    updateKeys(oldTransitionWeight, transitionWeight);
    // save on edges
    oldTransitionWeight = state.chatbots[state.chatbot].edges.find(
      (t) => t.id == transitionWeight.id
    );
    updateKeys(oldTransitionWeight, transitionWeight);
  },
  deleteTransitionWeight(state, transitionWeight) {
    // delete on node transitions
    state.chatbots[state.chatbot].nodes.forEach((n) => {
      n.transitions = n.transitions.filter((t) => t.id != transitionWeight.id);
    });
    // delete on edges
    state.chatbots[state.chatbot].edges = state.chatbots[
      state.chatbot
    ].edges.filter((t) => t.id != transitionWeight.id);
  },
  /*
  -------------------- answer --------------------
  */
  addAnswer(state, answer) {
    let node = state.chatbots[state.chatbot].nodes.find(
      (n) => n.id == answer.node
    );
    node.answers.push(answer);
  },
  saveAnswer(state, answer) {
    let node = state.chatbots[state.chatbot].nodes.find(
      (n) => n.id == answer.node
    );
    let oldAnswer = node.answers.find((a) => a.id == answer.id);
    updateKeys(oldAnswer, answer);
  },
  deleteAnswer(state, answer) {
    let node = state.chatbots[state.chatbot].nodes.find(
      (n) => n.id == answer.node
    );
    node.answers = node.answers.filter((a) => a.id != answer.id);
  },
  /*
  ----------------- node resource -----------------
  */
  addNodeResource(state, resource) {
    let node = state.chatbots[state.chatbot].nodes.find(
      (n) => n.id == resource.node
    );
    node.resources.push(resource);
  },
  saveNodeResource(state, resource) {
    let node = state.chatbots[state.chatbot].nodes.find(
      (n) => n.id == resource.node
    );
    let oldResource = node.resources.find((a) => a.id == resource.id);
    updateKeys(oldResource, resource);
  },
  deleteNodeResource(state, resource) {
    let node = state.chatbots[state.chatbot].nodes.find(
      (n) => n.id == resource.node
    );
    node.resources = node.resources.filter((a) => a.id != resource.id);
  },
  /*
  -------------------- answer weight --------------------
  */
  addAnswerWeight(state, answerWeight) {
    let answer;
    state.chatbots[state.chatbot].nodes.forEach((n) => {
      let foundAnswer = n.answers.find((a) => a.id == answerWeight.answer);
      if (foundAnswer) {
        answer = foundAnswer;
      }
    });
    answer.weights.push(answerWeight);
  },
  saveAnswerWeight(state, answerWeight) {
    let answer;
    state.chatbots[state.chatbot].nodes.forEach((n) => {
      let foundAnswer = n.answers.find((a) => a.id == answerWeight.answer);
      if (foundAnswer) {
        answer = foundAnswer;
      }
    });
    let oldAnswerWeight = answer.weights.find((aw) => aw.id == answerWeight.id);
    updateKeys(oldAnswerWeight, answerWeight);
  },
  deleteAnswerWeight(state, answerWeight) {
    let answer;
    state.chatbots[state.chatbot].nodes.forEach((n) => {
      let foundAnswer = n.answers.find((a) => a.id == answerWeight.answer);
      if (foundAnswer) {
        answer = foundAnswer;
      }
    });
    answer.weights = answer.weights.filter((aw) => aw.id != answerWeight.id);
  },
  /*
  ----------------- answer validation -----------------
  */
  addAnswerValidation(state, answerValidation) {
    let node = state.chatbots[state.chatbot].nodes.find(
      (n) => n.id == answerValidation.node
    );
    node.validations.push(answerValidation);
  },
  saveAnswerValidation(state, answerValidation) {
    let node = state.chatbots[state.chatbot].nodes.find(
      (n) => n.id == answerValidation.node
    );
    let oldAnswerValidation = node.validations.find(
      (a) => a.id == oldAnswerValidation.id
    );
    updateKeys(oldAnswerValidation, answerValidation);
  },
  deleteAnswerValidation(state, answerValidation) {
    let node = state.chatbots[state.chatbot].nodes.find(
      (n) => n.id == answerValidation.node
    );
    node.validations = node.validations.filter(
      (a) => a.id != answerValidation.id
    );
  },
  /*
  -------------------- node weight --------------------
  */
  addNodeWeight(state, nodeWeight) {
    let node = state.chatbots[state.chatbot].nodes.find(
      (n) => n.id == nodeWeight.node
    );
    node.weights.push(nodeWeight);
  },
  saveNodeWeight(state, nodeWeight) {
    let node = state.chatbots[state.chatbot].nodes.find(
      (n) => n.id == nodeWeight.node
    );
    let oldNodeWeight = node.weights.find((a) => a.id == nodeWeight.id);
    updateKeys(oldNodeWeight, nodeWeight);
  },
  deleteNodeWeight(state, nodeWeight) {
    let node = state.chatbots[state.chatbot].nodes.find(
      (n) => n.id == nodeWeight.node
    );
    node.weights = node.weights.filter((a) => a.id != nodeWeight.id);
  },
  /*
  -------------------- node variable --------------------
  */
  addNodeVariable(state, nodeVariable) {
    let node = state.chatbots[state.chatbot].nodes.find(
      (n) => n.id == nodeVariable.node
    );
    node.variables.push(nodeVariable);
  },
  saveNodeVariable(state, nodeVariable) {
    let node = state.chatbots[state.chatbot].nodes.find(
      (n) => n.id == nodeVariable.node
    );
    let oldNodeVariable = node.variables.find((a) => a.id == nodeVariable.id);
    updateKeys(oldNodeVariable, nodeVariable);
  },
  deleteNodeVariable(state, nodeVariable) {
    let node = state.chatbots[state.chatbot].nodes.find(
      (n) => n.id == nodeVariable.node
    );
    node.variables = node.variables.filter((a) => a.id != nodeVariable.id);
  },
};
const actions = {
  // eslint-disable-next-line
  reset({ state }, {}) {
    Object.assign(state, getDefaultState());
  },
  saveChatbot({ commit }, { chatbot }) {
    commit('saveChatbot', { chatbot });
  },
  updateChatbotBasic({ commit }, { chatbot }) {
    commit('updateChatbotBasic', { chatbot });
  },
  getChatbotUpdated({ commit, dispatch, getters }, { chatbotId }) {
    if (!chatbotId) {
      chatbotId = getters.chatbot.id;
    }
    return dispatch(
      'utils/request',
      { type: 'get', endpoint: `chatbot/${chatbotId}?required_fields=updated` },
      { root: true }
    ).then((response) => {
      // set chatbots
      let updated = response.data.updated;

      commit('setChatbotUpdated', { chatbotId, updated });
      return updated;
    });
  },
  getChatbot({ dispatch, rootGetters, commit }, { chatbotId }) {
    if (rootGetters['utils/isSuperAdmin']) {
      // if superadmin, get all chatbots
      return dispatch(
        'utils/request',
        { type: 'get', endpoint: `chatbot/${chatbotId}/` },
        { root: true }
      ).then((response) => {
        // set chatbots
        let chatbot = response.data;
        chatbot.role = 'admin';
        commit('updateChatbot', chatbot);
        return chatbot;
      });
    } else {
      // otherwise, get roles
      return dispatch(
        'utils/request',
        {
          endpoint: 'user_role',
          type: 'get',
          params: {
            params: {
              chatbot__id: chatbotId,
            },
          },
        },
        { root: true }
      ).then(
        // set chatbots
        (response) => {
          let chatbot = response.data.chatbot;
          chatbot.role = response.data.role;
          commit('updateChatbot', chatbot);
          return chatbot;
        }
      );
    }
  },
  getOrganizations({ dispatch, commit }) {
    return dispatch(
      'utils/request',
      {
        type: 'get',
        endpoint: `organization/`,
      },
      { root: true }
    ).then((response) => {
      // set chatbots
      let organizations = response.data;
      commit('setOrganizations', organizations);
    });
  },
  getChatbots({ dispatch, getters, rootGetters }) {
    if (rootGetters['utils/isSuperAdmin']) {
      // if superadmin, get all chatbots
      return dispatch(
        'utils/request',
        {
          type: 'get',
          endpoint: `chatbot/`,
          params: { params: { organization: getters.organization.id } },
        },
        { root: true }
      ).then((response) => {
        // set chatbots
        let chatbots = response.data;
        chatbots.forEach((chatbot) => {
          chatbot.role = 'admin';
        });
        return dispatch('setChatbots', { chatbots }).then(() => {
          return chatbots;
        });
      });
    } else {
      // otherwise, get roles
      return dispatch(
        'utils/request',
        {
          endpoint: 'user_role',
          type: 'get',
        },
        { root: true }
      ).then(
        // set chatbots
        (response) => {
          let chatbots = response.data.map((role) => {
            let chatbot = role.chatbot;
            chatbot.role = role.role;
            return chatbot;
          });
          chatbots = chatbots.filter((chatbot) => {
            return chatbot.organization == getters.organization.id;
          });
          return dispatch(
            'chatbot/setChatbots',
            { chatbots },
            { root: true }
          ).then(() => {
            return chatbots;
          });
        }
      );
    }
  },
  setOrganizations({ commit }, { organizations }) {
    commit('setOrganizations', organizations);
  },
  setOrganization({ commit }, { organization }) {
    commit('setOrganization', organization);
  },
  setChatbots({ commit }, { chatbots }) {
    // first, preprocess each chatbot to be able to be read
    // by vue vis network
    let processedChatbots = chatbots.map((chatbot) => {
      // compute chatbot
      chatbot = computeNodeAndEdges(chatbot);
      return chatbot;
    });

    commit('setChatbots', processedChatbots);
    if (processedChatbots.length > 0) {
      commit('setChatbot', 0);
    }
  },
  setChatbot({ commit }, chatbot) {
    commit('setChatbot', chatbot);
  },
  changeNodeColor({ commit }, { node, color }) {
    commit('changeNodeColor', { node, color });
  },
  recolor({ commit }) {
    commit('recolor');
  },
  removeInteractions({ commit, getters }) {
    getters.chatbot.nodes.forEach((node) => {
      commit('removeInteractionsFromNode', node);
    });
    return;
  },
  getInteractions({ commit, dispatch, getters }, { intervention }) {
    // get interactions
    let endpoint = `interaction?chatbot__name=${getters.chatbot.name}&aggregated=1`;
    if (intervention) {
      endpoint = `interaction?chatbot__name=${getters.chatbot.name}&name=${intervention}&aggregated=1`;
    }
    return dispatch(
      'utils/request',
      {
        type: 'get',
        endpoint,
      },
      { root: true }
    )
      .then((response) => {
        let counts = response.data.nodes;
        let edgeCounts = response.data.edges;
        let uniqueCounts = response.data.unique;

        // let maxCount = Math.max(...Object.values(counts));
        let maxEdgeCount = Math.max(...Object.values(edgeCounts));
        let maxUniqueCount = Math.max(...Object.values(uniqueCounts));
        let maxInteractions = maxUniqueCount;
        if (maxInteractions == 0) {
          maxInteractions = 1;
        }
        if (maxEdgeCount == 0) {
          maxEdgeCount = 1;
        }

        getters.chatbot.nodes.forEach((node) => {
          // add node interactions
          let nodeInteractions = 0;
          let uniqueInteractions = 0;
          if (node.id in counts) {
            nodeInteractions = counts[node.id];
          }
          if (node.id in uniqueCounts) {
            uniqueInteractions = uniqueCounts[node.id];
          }
          let font_size_scaler = uniqueInteractions / maxInteractions;
          commit('addInteractionsToNode', {
            node,
            interactions: nodeInteractions,
            uniqueInteractions: uniqueInteractions,
            size: font_size_scaler,
            maxInteractions: maxInteractions,
          });
        });
        getters.chatbot.edges.forEach((e) => {
          let edgeInteractions = 0;
          let edgeId = `${e.source}-${e.destination}`;
          if (edgeId in edgeCounts) {
            edgeInteractions = edgeCounts[edgeId];
          }
          commit('addInteractionsToEdges', {
            from: e.source,
            to: e.destination,
            interactions: edgeInteractions,
            maxInteractions: maxEdgeCount,
          });
        });
        return;
      })
      .catch((error) => {
        throw error;
      });
  },
  addCommentToNode({ commit }, { comment, nodeId }) {
    commit('addCommentToNode', { comment, nodeId });
  },
  setDrag({ commit }, { drag }) {
    commit('setDrag', { drag });
  },
  deleteComment({ dispatch, commit }, { comment }) {
    return dispatch(
      'utils/request',
      {
        errorMessage: 'Error resolving comment',
        successMessage: 'Comment resolved successfully',
        endpoint: `comment/${comment.id}/`,
        type: 'delete',
      },
      { root: true }
    ).then(() => {
      commit('deleteCommentFromNode', { comment, nodeId: comment.node });
    });
  },
  /*
  -------------------- resource --------------------
  */
  addResource({ commit, dispatch }, { resource }) {
    // let chatbot = getters.chatbot.id;
    // send request
    return dispatch(
      'utils/request',
      {
        errorMessage: 'Error adding resource',
        successMessage: 'Resource added successfully',
        endpoint: 'resource/',
        type: 'post',
        body: resource,
      },
      {
        root: true,
      }
    ).then((response) => {
      commit('addResource', response.data);
      return response.data;
    });
  },
  saveResource({ commit, dispatch }, { resource }) {
    let id = resource.id;
    let errorMessage = 'Error updating resource';
    let successMessage = 'Resource updated successfully';
    delete resource['id'];
    dispatch(
      'utils/request',
      {
        errorMessage,
        successMessage,
        type: 'patch',
        endpoint: `resource/${id}/`,
        body: resource,
      },
      { root: true }
    ).then((response) => {
      commit('saveResource', response.data);
      return response.data;
    });
  },
  deleteResource({ commit, dispatch }, { resourceId }) {
    return dispatch(
      'utils/request',
      {
        errorMessage: 'Error deleting resource',
        successMessage: 'Resource deleted successfully',
        endpoint: `resource/${resourceId}/`,
        type: 'delete',
      },
      { root: true }
    ).then(() => {
      commit('deleteResource', resourceId);
    });
  },
  /*
  -------------------- node --------------------
  */
  addNode({ commit, dispatch, getters }, { x, y }) {
    let chatbot = getters.chatbot.id;
    // send request
    return dispatch(
      'utils/request',
      {
        errorMessage: 'Error adding node',
        successMessage: 'Node added successfully',
        endpoint: 'node/',
        type: 'post',
        body: {
          name: '',
          text: 'Reemplazar texto aquí',
          pos_x: x,
          pos_y: y,
          chatbot,
        },
      },
      {
        root: true,
      }
    ).then((response) => {
      commit('addNode', response.data);
      return response.data;
    });
  },
  saveNode({ commit, dispatch }, { node, callback }) {
    let id = node.id;
    let errorMessage = 'Error updating node';
    let successMessage = 'Node updated successfully';
    if ('silent' in node) {
      delete node['silent'];
      errorMessage = false;
      successMessage = false;
    }

    delete node['id'];
    if (callback) {
      dispatch(
        'utils/request',
        {
          errorMessage,
          successMessage,
          type: 'patch',
          endpoint: `node/${id}/`,
          body: node,
        },
        { root: true }
      ).then(() => {
        // re add node id and save to store
        node.id = id;
        // set Chatbot Updated
        dispatch('getChatbotUpdated', { chatbotId: null });
        commit('saveNode', node);
      });
    } else {
      node.id = id;
      commit('saveNode', node);
    }
  },
  deleteNode({ commit, dispatch }, { node }) {
    dispatch(
      'utils/request',
      {
        errorMessage: 'Error deleting node',
        successMessage: 'Node deleted successfully',
        endpoint: `node/${node}/`,
        type: 'delete',
      },
      { root: true }
    ).then(() => {
      commit('deleteNode', node);
      dispatch('getChatbotUpdated', { chatbotId: null });
    });
  },
  /*
  -------------------- transition weights --------------------
  */
  addTransitionWeight(
    { commit, dispatch },
    { source, destination, feature, expected_weight }
  ) {
    return dispatch(
      'utils/request',
      {
        endpoint: 'transition_weight/',
        type: 'post',
        body: {
          source,
          destination,
          feature,
          expected_weight,
        },
      },
      { root: true }
    )
      .then((response) => {
        dispatch('getChatbotUpdated', { chatbotId: null });
        commit('addTransitionWeight', response.data);
      })
      .catch(() => {});
  },
  saveTransitionWeight({ commit, dispatch }, { transitionWeight }) {
    return dispatch(
      'utils/request',
      {
        errorMessage: 'Error updating transition weight',
        successMessage: 'Transition weight updated successfully',
        type: 'patch',
        endpoint: `transition_weight/${transitionWeight.id}/`,
        body: transitionWeight,
      },
      { root: true }
    ).then((response) => {
      // re add node id and save to store
      dispatch('getChatbotUpdated', { chatbotId: null });
      commit('saveTransitionWeight', response.data);
      return response.data;
    });
  },
  deleteTransitionWeight({ commit, dispatch }, { transitionWeight }) {
    return dispatch(
      'utils/request',
      {
        errorMessage: 'Error deleting transition weight',
        successMessage: 'transition weihgt deleted successfully',
        endpoint: `transition_weight/${transitionWeight.id}/`,
        type: 'delete',
      },
      { root: true }
    ).then(() => {
      dispatch('getChatbotUpdated', { chatbotId: null });
      commit('deleteTransitionWeight', transitionWeight);
      return;
    });
  },
  /*
  -------------------- answer --------------------
  */
  addAnswer({ commit, dispatch }, { node }) {
    dispatch(
      'utils/request',
      {
        errorMessage: 'Error creating answer',
        successMessage: 'Answer created successfully',
        type: 'post',
        endpoint: `answer/`,
        body: {
          text: 'Respuesta',
          node: node,
        },
      },
      { root: true }
    ).then((response) => {
      dispatch('getChatbotUpdated', { chatbotId: null });
      commit('addAnswer', response.data);
    });
  },
  saveAnswer({ commit, dispatch }, { answer }) {
    // remove answer weights, to just patch answer itself
    let finalAnswer = { ...answer };
    if ('weights' in finalAnswer) {
      delete finalAnswer['weights'];
    }
    dispatch(
      'utils/request',
      {
        errorMessage: 'Error updating answer',
        successMessage: 'Answer updated successfully',
        type: 'patch',
        endpoint: `answer/${answer.id}/`,
        body: finalAnswer,
      },
      { root: true }
    ).then((response) => {
      dispatch('getChatbotUpdated', { chatbotId: null });
      commit('saveAnswer', response.data);
    });
  },
  deleteAnswer({ commit, dispatch }, { answer }) {
    return dispatch(
      'utils/request',
      {
        errorMessage: 'Error deleting answer',
        successMessage: 'Answer deleted successfully',
        type: 'delete',
        endpoint: `answer/${answer.id}/`,
      },
      { root: true }
    ).then(() => {
      dispatch('getChatbotUpdated', { chatbotId: null });
      commit('deleteAnswer', answer);
      return;
    });
  },
  /*
  ----------------- node resource ------------------
  */
  addNodeResource({ commit, dispatch }, { node, resource }) {
    return dispatch(
      'utils/request',
      {
        errorMessage: 'Error creating node resource',
        successMessage: 'Node resource created successfully',
        type: 'post',
        endpoint: `node_resource/`,
        body: {
          node: node,
          resource: resource,
        },
      },
      { root: true }
    ).then((response) => {
      commit('addNodeResource', response.data);
    });
  },
  saveNodeResource({ commit, dispatch }, { resource }) {
    dispatch(
      'utils/request',
      {
        errorMessage: 'Error updating node resource',
        successMessage: 'Node resource updated successfully',
        type: 'patch',
        endpoint: `node_resource/${resource.id}/`,
        body: resource,
      },
      { root: true }
    ).then((response) => {
      commit('saveNodeResource', response.data);
    });
  },
  deleteNodeResource({ commit, dispatch }, { resource }) {
    return dispatch(
      'utils/request',
      {
        errorMessage: 'Error deleting answer',
        successMessage: 'Answer deleted successfully',
        type: 'delete',
        endpoint: `node_resource/${resource.id}/`,
      },
      { root: true }
    ).then(() => {
      commit('deleteNodeResource', resource);
      return;
    });
  },
  /*
  -------------------- answer weight --------------------
  */
  addAnswerWeight({ commit, dispatch }, { answer }) {
    dispatch(
      'utils/request',
      {
        errorMessage: 'Error creating answer',
        successMessage: 'Answer created successfully',
        type: 'post',
        endpoint: `answer_weight/`,
        body: { weight: 0, feature: 'default', answer: answer.id },
      },
      { root: true }
    ).then((response) => {
      dispatch('getChatbotUpdated', { chatbotId: null });
      commit('addAnswerWeight', response.data);
    });
  },
  saveAnswerWeight({ commit, dispatch }, { answerWeight }) {
    dispatch(
      'utils/request',
      {
        errorMessage: 'Error updating answer weight',
        successMessage: 'Answer weight updated successfully',
        type: 'patch',
        endpoint: `answer_weight/${answerWeight.id}/`,
        body: answerWeight,
      },
      { root: true }
    ).then((response) => {
      dispatch('getChatbotUpdated', { chatbotId: null });
      commit('saveAnswerWeight', response.data);
    });
  },
  deleteAnswerWeight({ commit, dispatch }, { answerWeight }) {
    return dispatch(
      'utils/request',
      {
        errorMessage: 'Error deleting answer',
        successMessage: 'Answer deleted successfully',
        type: 'delete',
        endpoint: `answer_weight/${answerWeight.id}/`,
      },
      { root: true }
    ).then(() => {
      dispatch('getChatbotUpdated', { chatbotId: null });
      commit('deleteAnswerWeight', answerWeight);
      return;
    });
  },
  /*
  -------------------- node weight --------------------
  */
  addNodeWeight({ commit, dispatch }, { node }) {
    dispatch(
      'utils/request',
      {
        errorMessage: 'Error creating node weight',
        successMessage: 'Node weight created successfully',
        type: 'post',
        endpoint: `node_weight/`,
        body: {
          feature: 'default',
          weight: 0,
          node: node,
        },
      },
      { root: true }
    ).then((response) => {
      dispatch('getChatbotUpdated', { chatbotId: null });
      commit('addNodeWeight', response.data);
    });
  },
  saveNodeWeight({ commit, dispatch }, { nodeWeight }) {
    dispatch(
      'utils/request',
      {
        errorMessage: 'Error updating node weight',
        successMessage: 'node weight updated successfully',
        type: 'patch',
        endpoint: `node_weight/${nodeWeight.id}/`,
        body: nodeWeight,
      },
      { root: true }
    ).then((response) => {
      dispatch('getChatbotUpdated', { chatbotId: null });
      commit('saveNodeWeight', response.data);
    });
  },
  deleteNodeWeight({ commit, dispatch }, { nodeWeight }) {
    return dispatch(
      'utils/request',
      {
        errorMessage: 'Error deleting node weight',
        successMessage: 'Node weight deleted successfully',
        type: 'delete',
        endpoint: `node_weight/${nodeWeight.id}/`,
      },
      { root: true }
    ).then(() => {
      dispatch('getChatbotUpdated', { chatbotId: null });
      commit('deleteNodeWeight', nodeWeight);
      return;
    });
  },
  /*
  ------------------ answer validation ------------------
  */
  addAnswerValidation({ commit, dispatch }, { node }) {
    dispatch(
      'utils/request',
      {
        errorMessage: 'Error creating answer validation',
        successMessage: 'Answer validation created successfully',
        type: 'post',
        endpoint: `answer_validation/`,
        body: {
          node: node,
          category: 'eq',
          value: '0',
        },
      },
      { root: true }
    ).then((response) => {
      dispatch('getChatbotUpdated', { chatbotId: null });
      commit('addAnswerValidation', response.data);
    });
  },
  saveAnswerValidation({ commit, dispatch }, { answerValidation }) {
    dispatch(
      'utils/request',
      {
        errorMessage: 'Error updating answer validation',
        successMessage: 'Answer validation updated successfully',
        type: 'patch',
        endpoint: `answer_validation/${answerValidation.id}/`,
        body: answerValidation,
      },
      { root: true }
    ).then((response) => {
      dispatch('getChatbotUpdated', { chatbotId: null });
      commit('saveAnswerValidation', response.data);
    });
  },
  deleteAnswerValidation({ commit, dispatch }, { answerValidation }) {
    return dispatch(
      'utils/request',
      {
        errorMessage: 'Error deleting answer validation',
        successMessage: 'Answer validation deleted successfully',
        type: 'delete',
        endpoint: `answer_validation/${answerValidation.id}/`,
      },
      { root: true }
    ).then(() => {
      dispatch('getChatbotUpdated', { chatbotId: null });
      commit('deleteAnswerValidation', answerValidation);
      return;
    });
  },
  /*
  -------------------- node variable --------------------
  */
  addNodeVariable({ commit, dispatch }, { node }) {
    dispatch(
      'utils/request',
      {
        errorMessage: 'Error creating node variable',
        successMessage: 'Node variable created successfully',
        type: 'post',
        endpoint: `node_variable/`,
        body: {
          variable: 'variable',
          number: 0,
          node: node,
        },
      },
      { root: true }
    ).then((response) => {
      dispatch('getChatbotUpdated', { chatbotId: null });
      commit('addNodeVariable', response.data);
    });
  },
  saveNodeVariable({ commit, dispatch }, { nodeVariable }) {
    // remove answer weights, to just patch answer itself
    dispatch(
      'utils/request',
      {
        errorMessage: 'Error updating node variable',
        successMessage: 'Node variable updated successfully',
        type: 'patch',
        endpoint: `node_variable/${nodeVariable.id}/`,
        body: nodeVariable,
      },
      { root: true }
    ).then((response) => {
      dispatch('getChatbotUpdated', { chatbotId: null });
      commit('saveNodeVariable', response.data);
    });
  },
  deleteNodeVariable({ commit, dispatch }, { nodeVariable }) {
    return dispatch(
      'utils/request',
      {
        errorMessage: 'Error deleting node variable',
        successMessage: 'Node variable successfully',
        type: 'delete',
        endpoint: `node_variable/${nodeVariable.id}/`,
      },
      { root: true }
    ).then(() => {
      dispatch('getChatbotUpdated', { chatbotId: null });
      commit('deleteNodeVariable', nodeVariable);
      return;
    });
  },
  /*
  -------------------- feature --------------------
  */
  addFeature({ dispatch }, { feature }) {
    return dispatch(
      'utils/request',
      {
        errorMessage: 'Error creating feature',
        successMessage: 'Feature created successfully',
        type: 'post',
        endpoint: `feature/`,
        body: feature,
      },
      { root: true }
    ).then((response) => {
      return response.data;
    });
  },
  saveFeature({ dispatch }, { feature }) {
    return dispatch(
      'utils/request',
      {
        errorMessage: 'Error creating feature',
        successMessage: 'Feature created successfully',
        type: 'patch',
        endpoint: `feature/${feature.id}/`,
        body: feature,
      },
      { root: true }
    ).then(() => {
      return;
    });
  },
  deleteFeature({ dispatch }, { feature }) {
    return dispatch(
      'utils/request',
      {
        errorMessage: 'Error deleting feature',
        successMessage: 'feature deleted successfully',
        type: 'delete',
        endpoint: `feature/${feature.id}/`,
      },
      { root: true }
    ).then(() => {
      return;
    });
  },
};
function getColor(node) {
  if (node.start) {
    return 'lightgreen';
  }
  if (node.ai) {
    return '#d383d2';
  }
  if (node.preprocess) {
    return '#d383d2';
  }
  if (node.special) {
    return '#F29B9B';
  }

  return 'lightblue';
}
function updateKeys(older, newer) {
  for (let key in newer) {
    if (key in older) {
      older[key] = newer[key];
    }
  }
  return older;
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
