<template>
  <div>
    <generic-dialog title="Crear chatbot" v-model="computedValue" width="600">
      <v-text-field
        label="nombre del chatbot:"
        v-model="newChatbotName"
        :rules="[
          (v) =>
            validateName(v) ||
            'El nombre solo puede contener letras y guión bajo.',
          (v) =>
            shortName(v) ||
            'El nombre no puede ser de largo mayor a 50 caracteres.',
        ]"
      ></v-text-field>
      <v-file-input
        v-model="chatbotFile"
        label="(opcional) Contenido de chatbot:"
        accept=".json"
        @change="uploadChatbot"
      ></v-file-input>
      <v-btn :disabled="!validName" @click="createChatbot">Crear chatbot</v-btn>
    </generic-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ChatbotCreator",
  data() {
    return {
      chatbotFile: null,
      newChatbotContent: {},
      newChatbotName: "",
    };
  },
  props: {
    value: {
      required: true,
      type: Boolean,
    },
  },
  methods: {
    shortName(name) {
      if (name && name.length < 50) {
        return true;
      }
      return false;
    },
    validateName(name) {
      const pattern = /^[a-zA-Z_0-9]+$/;
      return pattern.test(name);
    },
    ...mapActions({
      request: "utils/request",
      getChatbots: "chatbot/getChatbots",
    }),
    uploadChatbot() {
      const file = this.chatbotFile;
      const reader = new FileReader();
      reader.onload = () => {
        const json = JSON.parse(reader.result);
        this.newChatbotContent = json;
      };
      reader.readAsText(file);
    },
    createChatbot() {
      this.newChatbotContent["name"] = this.newChatbotName;
      this.newChatbotContent["label"] = this.newChatbotName;
      this.newChatbotContent["organization"] = this.organization.id;

      this.request({
        errorMessage: "Error creating chatbot",
        successMessage: "Chatbot created successfully",
        type: "post",
        endpoint: `chatbot/`,
        body: this.newChatbotContent,
      }).then(() => {
        this.getChatbots().then(() => {
          this.$emit("input", false);
        });
      });
    },
  },
  computed: {
    ...mapGetters({
      organization: "chatbot/organization",
    }),
    validName() {
      if (
        this.validateName(this.newChatbotName) &&
        this.shortName(this.newChatbotName)
      ) {
        return true;
      }
      return false;
    },
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>
